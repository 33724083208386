import React from 'react'
import { Link } from 'react-router-dom'
import { dotToComma } from "../../services/helpers"


function MainProfil({profil,handleViewProfil}) {


  const slicedArray = profil.orders.length>2?profil.orders.slice(0, 2):profil.orders;
  const priceTotal=(totalBasket,deliveyPrice,promoPrice)=>{
    let total = totalBasket+deliveyPrice-promoPrice
    total=dotToComma(total)
    return total
}
  return (
    <main className="col-md-9">

      <article className="card mb-3">
        <div className="card-body">
          
          <figure className="icontext">
              <div className="icon">
                <img className="rounded-circle img-sm border" src="images/avatars/avatar3.jpg"/>
              </div>
              <div className="text">
                <strong>{profil.userInfos.firstname_customer} {profil.userInfos.surname_customer} </strong> <br/> 
                <p className="mb-2"> {profil.userInfos.username_customer}  </p> 
                <button onClick={()=>handleViewProfil(3)} className="btn btn-light btn-sm">Modifier</button>
              </div>
          </figure>
          <hr/>
          
          <p>
            <i className="fa fa-map-marker text-muted"></i> &nbsp; Inscription: &nbsp; 
            {profil.dataUser.dateSubscribe} 
          </p>
          <p>
            <i className="fa fa-map-marker text-muted"></i> &nbsp; Ville: &nbsp; 
            {profil.userInfos.nameTown} ({profil.userInfos.postCode}) &nbsp; &nbsp; <button className="btn " type='button' onClick={()=>handleViewProfil(3)}><i className="fa fa-edit text-muted" ></i></button>
          </p>
          <p>
            <i className="fa fa-map-marker text-muted"></i> &nbsp; Dernière commande:  &nbsp; 
            {profil.dateLastAchat} 
          </p>
          

          <article className="card-group card-stat">
            
            <figure className="card bg">
              <div className="p-3">
                <h4 className="title">{profil.quantityPaniers}</h4>
                <span>Panier en cours</span>
              </div>
            </figure>
            <figure className="card bg">
              <div className="p-3">
                <h4 className="title">{profil.achatsTotal}</h4>
                <span>Commandes</span>
              </div>
            </figure>
            <figure className="card bg">
              <div className="p-3">
                <h4 className="title">{profil.enAttente}</h4>
                <span>En attente</span>
              </div>
            </figure>
            <figure className="card bg">
              <div className="p-3">
                <h4 className="title">{profil.livre}</h4>
                <span>Livrées</span>
              </div>
            </figure>
          </article>
          

        </div>
      </article> 

      <article className="card  mb-3">
        <div className="card-body">
          <h5 className="card-title mb-4">Dernières commandes </h5>	
          <div className="row">
          {
            slicedArray.map((data,index)=>{
              return(
                <div key={index} className="col-md-12">
                <article key={index} className="card mb-4">
                  <header className="card-header">
                    <Link to={`/commandes/${data.number_order}`} className="float-right"> <i className="fa fa-eye"></i> Voir</Link>
                    <strong className="d-inline-block mr-3">Numéro: {data.number_order}</strong>
                    <span>Date: {data.dateOrder}</span>
                  </header>
                  <div className="card-body">
                    <div className="row" > 
                      <div className="col-md-8">
                        <h6 className="text-muted">Livraison</h6>
                        <p><time className="text-muted"><i className="fa fa-truck"></i></time>:&nbsp;{data.dateDeliveryFrench}<br/>
                        <time className="text-muted"><i className="fa fa-address-book"></i></time>:&nbsp;{data.nameDeliveryAddress} <br/>  
                            {data.roadDeliveryAddress} {data.infosroadDeliveryAddress && `,${data.infosroadDeliveryAddress}`} <br/> 
                          {data.postcodeDeliveryAddress} {data.townDeliveryAddress}<br/>
                          <time className="text-muted"><i className="fa fa-phone-volume"></i></time>:&nbsp; {data.contactAdresse} <br/>
                          
                          Statut:&nbsp;{data.cancel_order===1 &&
                                  <span className="text-danger">COMMANDE ANNULEE </span>
                                }
                                {data.cancel_order!==1 &&
                                  <span className={data.typestatusorder==='COMMANDE VALIDEE' || data.typestatusorder==='LIVRAISON EFFECTUEE'?"text-success":"text-warning"}>{data.typestatusorder} </span>
                                }
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h6 className="text-muted">Paiement</h6>
                        
                        <p>CB<br/>Sous total:  {dotToComma(data.total_order)}€ <br/>
                        Livraison:  {dotToComma(data.pricedelivery_order)}€ <br/> 
                        {data.amountpromo_order>0 && <span>Promo:  -{dotToComma(data.amountpromo_order)}€ <br/></span> }
                        <span className="b">Total:  {priceTotal(data.total_order,data.pricedelivery_order,data.amountpromo_order)}€ </span>
                        </p>
                      </div>
                    </div> 
                  </div> 
                
                </article> 
                </div>
              )
            })
          }
          
        </div> 

        <button type="button" onClick={()=>handleViewProfil(1)} className="btn btn-outline-secondary btn-block"> Voir toutes les commandes <i className="fa fa-chevron-down"></i>  </button>
        </div>
      </article> 

    </main> 
  )
}

export default MainProfil