import React, { useEffect, useState } from 'react'


function BtnScrollUp() {
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [showBtn,setShowBtn]=useState(false)
   
    const scrollUp=()=>{
        window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth'
        });
    }
    const handleScroll = (e) => {
        const position = window.pageYOffset;
        window.scrollY > 500?setShowBtn(true):setShowBtn(false)
        const mediaMatchInfo = window.matchMedia('(min-width: 500px)');
        setMatches(mediaMatchInfo.matches)
        
    };
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll, { passive: true });
       
       
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    })
    return (
        <div>
            {showBtn &&
                <button onClick={scrollUp} className='btn btn-primary btn-rounded'  style={{position:"fixed",bottom:matches?"9vh":"2vh",left:matches?"85vw":"43vw",zIndex:1 }}><i class="fa fa-arrow-up"></i></button> 
            }
        </div>
    )

  
}

export default BtnScrollUp