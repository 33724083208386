import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { useStoreState } from 'easy-peasy';
import Template from './components/Template';
import Loading from './components/loading/Loading';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred';
import FindPostCode from './components/postCode/FindPostCode';
import {urlData,urlDataImage} from './services/url';



function Brands() {
  const linkApiImages=urlDataImage()
  const postCode= useStoreState((state)=>state.postCode)
  const [marques, setMarques]= useState([])
  const [brands, setBrands]= useState([])
  const [showBrandsFilter, setShowBrandsFilter]= useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  
  
  
  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/brands?postCode=${postCode}`);
          if(response.data.success) {
            setFetchError(null);
            setMarques(response.data);
            setBrands(response.data.brands)
            
          }else{
            console.log("findErrors in ")
            setFetchError(response.data.message);
          }
      } catch (err) {
       
          console.log(err)
          setFetchError(err.message);
      } finally {
          setIsLoading(false);
      }
  }
   
  fetchData()
},[postCode])

  const  showBrandsLetter = (letter)=>{
   
    if(letter=="ALL"){
      setMarques({success:true,brands})
    }else{
      let findBrands= brands.filter((e) =>  e.nameBrand.startsWith(letter))
      setMarques({success:true,brands:findBrands})
    }
    
  }

  return (
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError &&
        <Template>
        <div className="container">
        <section className="section-content padding-y">
              <div className="container">
                <div className="card mb-3">
                  <div className="card-body">
                <div className="row">
                  <div className="col-md-2"> Vous êtes ici: </div> 
                  <nav className="col-md-8"> 
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={`/`}>
                            Accueil
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                            MARQUES
                        </li>
                        
                    </ol>  
                  </nav>
                </div>
                <hr/>
                <div className="row">
                  <div className="col-md-2">Rechercher par</div>
                  <div className="col-md-10"> 
                    <ul className="list-inline">
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('ALL')} className="btn" >Tous</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('A')} className="btn" >A</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('B')} className="btn" >B</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('C')} className="btn" >C</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('D')} className="btn" >D</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('E')} className="btn" >E</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('F')} className="btn" >F</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('G')} className="btn" >G</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('H')} className="btn" >H</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('I')} className="btn" >I</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('J')} className="btn" >J</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('K')} className="btn" >K</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('L')} className="btn" >L</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('M')} className="btn" >M</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('N')} className="btn" >N</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('O')} className="btn" >O</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('P')} className="btn" >P</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('Q')} className="btn" >Q</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('R')} className="btn" >R</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('S')} className="btn" >S</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('T')} className="btn" >T</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('U')} className="btn" >U</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('V')} className="btn" >V</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('W')} className="btn" >W</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('X')} className="btn" >X</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('Y')} className="btn" >Y</button></li>
                      <li className="list-inline-item mr-1 dropdown"><button onClick={e=> showBrandsLetter('Z')} className="btn" >Z</button></li>
                      
                    </ul>
                  </div>
                </div> 
                  </div> 
                </div> 
        
                
                <div className="row">
                  
                </div> 
          
                
              </div> 
            </section>
            <section className="section-content padding-y">
              <div className="container">
                <div className="card mb-3">
                  <div  className="card-body">
                    {marques.brands.length==0 &&
                      <div className="row text-center">
                        <div  className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <h4>0 marque</h4>
                        </div>
                        
                      </div>
                    }
                    <div className="row">
                      {marques.brands.map(e=>{
                        return (
                          <div key={e.idBrand} className="col-xl-2 col-lg-3 col-md-4 col-6">
                            <Link to={`/marques/${e.idBrand}`}>
                              <div className="card card-sm card-product-grid">
                                <div  className="img-wrap"> 
                                  <img style={{"objectFit": "contain"}} src={`${linkApiImages}${e.uniqIdDoc}`}/>
                                </div>
                                <figcaption className="info-wrap">
                                  {e.nameBrand}
                                
                                </figcaption>
                              </div>
                            </Link>
                          </div>
                        )
                      })}
                      
                    </div>
                  </div> 
                </div> 
              </div> 
            </section>
        </div>
        <FindPostCode/>
      </Template>
      }
    </div>
  )
}

export default Brands
