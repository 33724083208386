import { Link } from 'react-router-dom'
import { dotToComma } from "../../services/helpers"
import AddToBasket from '../addToBasket/AddToBasket';
import {urlDataImage} from '../../services/url';
import { useStoreActions,useStoreState } from 'easy-peasy';

function ArticleBox({idBookProd,image,nameProduct,price,isFlash,articlePromo,sellBy,stock,showPrices,idBookSizePromo,newPricePromo,oldPricePromo,idProduct,idBookSize,article}) {

  const addBasket= useStoreActions((state)=>state.addBasket)
  const connected= useStoreState((state)=>state.connected)
  const postCode= useStoreState((state)=>state.postCode)
  const token= useStoreState((state)=>state.token)
  const idUser= useStoreState((state)=>state.idUser)
  const addTotalBasket= useStoreActions((state)=>state.addTotalBasket)
  const setArticleSizes= useStoreActions((state)=>state.setArticleSizes)
  const setIsLoadingProduct= useStoreActions((state)=>state.setIsLoadingProduct)
  const setMessageError= useStoreActions((state)=>state.setMessageError)

  const setMoveToBasket= useStoreActions((state)=>state.setMoveToBasket)
  const setShowPopupError= useStoreActions((state)=>state.setShowPopupError)
  const setShowPopupSuccess= useStoreActions((state)=>state.setShowPopupSuccess)
  const setDeleteFromWishlist= useStoreActions((state)=>state.setDeleteFromWishlist)

  const linkApiImages=urlDataImage()
  
  const colorShopBtn= "#f88e55"
  

  return (
   
      <div className="col-md-3">
        <figure className="card card-product-grid">
          <div className="img-wrap"> 
          <Link to={`/articles/${idBookProd}`}>
            {articlePromo&&<span className="badge badge-danger"> {isFlash?"FLASH":"PROMOTION"} </span>}
          
            <img  className="mt-1 ml-1 mr-1 rounded img-fluid " src={`${linkApiImages}/${image}`} alt={nameProduct}/>
            </Link>
          </div> 
          <figcaption className="info-wrap">
              <Link to={`/articles/${idBookProd}`} className="title mb-2">{nameProduct}</Link>
              
              { postCode &&
                <div className="price-wrap">
                  
                  {idBookSizePromo && <span className="price">{dotToComma(newPricePromo)}€</span>}
                  {idBookSizePromo && <del className="price-old">{dotToComma(oldPricePromo)}€</del>}
                  {!idBookSizePromo && <span className="price">{dotToComma(price)}€</span> }
                </div> 
               
              }
              
              
              
             
              <p>
                {stock>0 && article?.availableProduct!==0 && article.available_bookprod!==0 ?<span className="tag mb-2"> <i className="fa fa-check"></i> En Stock</span> :<span className="tag mb-2"> <i className="fa fa-remove"></i> Indisponible</span> }
              </p>
              { postCode &&
                <div style={{display: "flex",justifyContent: "space-around"}}>
                  <Link to={`/articles/${idBookProd}`} className="btn btn-outline-secondary">
                    <i className="fa fa-envelope"></i> Voir le produit 
                  </Link>
                  {stock>0 && article.availableProduct!==0 && article.available_bookprod!==0 &&
                  <AddToBasket 
                    idBookProd={idBookProd}
                    idProduct={idProduct}
                    idBookSize={idBookSize}
                    quantity={1}
                    showPopupSuccess={1}
                    showPopupError={1}
                    multipleArticle={article.multipleArticle>1?true:false}
                    addBasket={addBasket}
                    connected={connected}
                    postCode={postCode}
                    token={token}
                    idUser={idUser}
                    addTotalBasket={addTotalBasket}
                    setArticleSizes={setArticleSizes}
                    setIsLoadingProduct={setIsLoadingProduct}
                    setMessageError={setMessageError}
                    setMoveToBasket={setMoveToBasket}
                    setShowPopupError={setShowPopupError}
                    setShowPopupSuccess={setShowPopupSuccess}
                    setDeleteFromWishlist={setDeleteFromWishlist}
                  >
                    <button className='btn btnLight' type='button'>
                      <span className="icon icon-xs rounded-circle " style={{backgroundColor:`${colorShopBtn}`}}>
                        <i className={colorShopBtn?`fa fa-cart-plus grey`:"fa fa-cart-plus red"}></i>
                      </span>
                    </button>
                  </AddToBasket>
                }
                            
                  
                </div>
              }

              { !postCode &&
                <button type="button" className="btn btn-outline-secondary">
                  <i className="fa fa-envelope"></i> Indisponible
                </button>
              }
                
               
                  
                
              
              
          </figcaption>
        </figure>
      </div>
    
  );
}

export default ArticleBox;