import React from 'react'

function ArticleSeven() {
  return (
    <>
        <h5 class="card-title">Article 7</h5>
        <p>
          Droit de rétractation Conformément à l’article L.221-28 du code de la consommation, le client à un délai de 14 jours, à compter du jour de réception du produit, pour se rétracter. En cas de retour produits sous 14 jours après la date de commande, les frais seront à la charge du client. Si le client souhaite se rétracter il devra faire part de sa décision à la compagnie, en contactant celle-ci à cette adresse : Le client devra retourner sous un délai de 14 jours, à compter du jour où celui-ci à informer la compagnie de son souhait de se rétracter, l’article dans son emballage d’origine, complet et indiquer le numéro de commande. Les articles retournés incomplets, abîmés, endommagés, détériorés, salis ou dans tout autre état qui laisserait raisonnablement penser qu’ils ont été utilisés ou portés, ne seront ni remboursés ni échangés et lui seront retournés. Une fois que la compagnie aura récupéré le bien celle-ci procédera au remboursement dans un délai de 14 jours. La compagnie utilisera le même moyen de paiement, pour effectuer le remboursement, que lors de la transaction initiale.
        </p>
    </>
  )
}

export default ArticleSeven