import React, { useEffect, useState,useRef } from 'react'
import axios from 'axios';
import { Link, useNavigate, useLocation  } from 'react-router-dom'
import { useStoreActions,useStoreState } from 'easy-peasy';
import ChangePostCode from './postCode/ChangePostCode';
import Footer from './footer/Footer'
import ArticleBox from './articleBox/ArticleBox';
import SectionArticles from '../components/sections/sectionArticles';
import {urlData,urlDataImage} from '../services/url';
import {random_sort,commaToDot, dotToComma} from '../services/helpers';
import Modal from './modal/ModalPostCode';
import ModalChangePostCode from './modal/ModalPostCodeChange';


function Template( {children}) {
  const location = useLocation();
  console.log("location.pathname",location.pathname);
  const linkApiImages=urlDataImage()
  const [ showResult, setShowResult] = useState(false)
  const [ wordToSearch, setWordToSearch] = useState("")
  const [data, setData] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoriesMenu, setCategoriesMenu] = useState([])
  const [products, setProducts] = useState([])
  const [filterdProducts, setFilterdProducts] = useState([])
  const [ nameCategory, setNameCategory] = useState('all')
  const [ priceMin, setPriceMin] = useState("")
  const [ priceMax, setPriceMax] = useState("")
  const [ pickPrice, setPickPrice] = useState(0)
  const priceMinPrevious = useRef("");
  const priceMaxPrevious = useRef("");
  const nameCategoryPrevious = useRef("all");
  const [ showModal, setShowModal] = useState(false)

  const totalBasket= useStoreState((state)=>state.totalBasket)
  const token= useStoreState((state)=>state.token)
  const idUser= useStoreState((state)=>state.idUser)
  const deleteFromWishlist= useStoreState((state)=>state.deleteFromWishlist)
  const showPopupSuccess= useStoreState((state)=>state.showPopupSuccess)
  const showPopupError= useStoreState((state)=>state.showPopupError)
  const moveToBasket= useStoreState((state)=>state.moveToBasket)
  const connected= useStoreState((state)=>state.connected)
  const townName= useStoreState((state)=>state.townName)
  const setFullDisconnect= useStoreActions((state)=>state.setFullDisconnect)
  const postCode= useStoreState((state)=>state.postCode)
  const articleSizes= useStoreState((state)=>state.articleSizes)
  const isLoadingProduct= useStoreState((state)=>state.isLoadingProduct)
  const setIsLoadingProduct= useStoreActions((state)=>state.setIsLoadingProduct)
  const setArticleSizes= useStoreActions((state)=>state.setArticleSizes)
  const addBasket= useStoreActions((state)=>state.addBasket)
 
  const messageError= useStoreState((state)=>state.messageError)
  
  const setLastActiveTime= useStoreActions((state)=>state.setLastActiveTime)
  const setMoveToBasket= useStoreActions((state)=>state.setMoveToBasket)
  const setShowPopupError= useStoreActions((state)=>state.setShowPopupError)
  const setShowPopupSuccess= useStoreActions((state)=>state.setShowPopupSuccess)
  const setDeleteFromWishlist= useStoreActions((state)=>state.setDeleteFromWishlist)
  const addTotalBasket= useStoreActions((state)=>state.addTotalBasket)
  const setMessageError= useStoreActions((state)=>state.setMessageError)
  

 

  
  
  let showPrices=postCode?1:0
  const navigate = useNavigate()

  const handleChangePostCode=()=>{
    window.$("#changePostCode").modal("show")
  }
  const handleLogOut=()=>{
    window.$("#logoutModal").modal("show")
  }



  const getCategories=(products)=>{
    if(!Array.isArray(products)) return 
    if(products.length===0) return 

    let categoriesData= products.map(e=>e.nameCatPro)
    let uniqueCats=  [...new Set(categoriesData)];
    setCategories(uniqueCats)
  }

  const logout=()=>{
    try {
      
      setFullDisconnect()
      window.$("#logoutModal").modal("hide")
      navigate('/')
    } catch (error) {
      setFullDisconnect()
      console.log("erreur logout")
    }
    
  }

  useEffect(() => {
    priceMinPrevious.current = priceMin; 
    priceMaxPrevious.current = priceMax; 
    nameCategoryPrevious.current = nameCategory; 
  }, [nameCategory,priceMin,priceMax]);

  useEffect(()=>{

    async function callGetCategories(){
      try {
        const response = await axios.get(`${urlData()}/categories?postCode=${postCode}`,{header:{"Content-Type":"application/json"}});
        if(response.data.success){
          setCategoriesMenu(response.data?.catPrimaries)
         
          return
        }
      } catch (error) {
        
      }
    }
    callGetCategories()
  },[])

  const handleCloseLogOut=()=>{
    window.$("#logoutModal").modal("hide")
    
    
  }

  const findByPriceMinMax=(product)=>{
    console.log('call min && max')
    if(product.idBookSizePromo){
      return product.newPricePromo>=commaToDot(priceMin) && product.newPricePromo<=commaToDot(priceMax)
    }else{
      return product.price>=commaToDot(priceMin) && product.price<=commaToDot(priceMax)
    }
  }

  const findByPriceMin=(product)=>{
    console.log('call min ')
    console.log(`commaToDot(priceMin): ${commaToDot(priceMin)}`)
    if(product.idBookSizePromo){
      return product.newPricePromo>=commaToDot(priceMin)
    }else{
      return product.price>=commaToDot(priceMin)
    }
  }

  const findByPriceMax=(product)=>{
    console.log('call max')
    if(product.idBookSizePromo){
      return product.newPricePromo<=commaToDot(priceMax)
    }else{
      return product.price<=commaToDot(priceMax)
    }
    
  }

  const seeAllArticles=()=>{
    setNameCategory("all")
    setFilterdProducts(products)
    setPriceMax('')
    setPriceMin('')
    
  }
  const filterSearch= ()=>{
      let dataProducts= products
      if(nameCategory!=='all'){
        dataProducts=products.filter(product=>product.nameCatPro === nameCategory)
      }
      if(priceMin && priceMax && dataProducts.length>0){
        let filtered= dataProducts.filter(findByPriceMinMax)
        filtered=filtered.sort(random_sort)
        setFilterdProducts(filtered)
        return
      }

      if(priceMin && !priceMax && dataProducts.length>0){
        let filtered= dataProducts.filter(findByPriceMin)
        filtered=filtered.sort(random_sort)
        setFilterdProducts(filtered)
        return
      }

      if(!priceMin && priceMax && dataProducts.length>0){
        let filtered= dataProducts.filter(findByPriceMax)
        filtered=filtered.sort(random_sort)
        setFilterdProducts(filtered)
        return
      }
      
      let filtered=nameCategory!=='all'? products.filter(e=> e.nameCatPro ===nameCategory ):products
      filtered=filtered.sort(random_sort)
    
      setFilterdProducts(filtered)
  }

  const refreshPage = () => {
      // Get the current time
      let currentTime = new Date().getTime();

      // Get the last time the user was active
      let lastActiveTime = localStorage.getItem('lastActiveTime');
      let connected = localStorage.getItem('connected');

      // If there is no last active time, set it to now
      if (!lastActiveTime) {
        localStorage.setItem('lastActiveTime', currentTime);
        return;
      }



      // Calculate the difference between now and the last active time
      let diff = currentTime - lastActiveTime;

      // If the difference is greater than 15 minutes, clear local storage and set the new active time to now
     if (diff > 900000){
      //if (diff > 10000) {
        localStorage.setItem('lastActiveTime', currentTime);
        console.log(`connected:${connected}`)
        if(connected){
          setFullDisconnect()
          localStorage.setItem('lastActiveTime', currentTime);
          //window.$("#expiredSessionModal").modal("show")
        }
        
        
      } else {
        // Otherwise, just update the last active time to now
        localStorage.setItem('lastActiveTime', currentTime);  
      }  

    
  }
  setInterval(()=>{
    refreshPage()
  },60000)

  
 
  const handleSearch=async(e)=>{
    e.preventDefault()
    try {
      if(wordToSearch.length>0){
        setIsLoading(true)
        setShowResult(false)
        const response = await axios.get(`${urlData()}/find-article/${wordToSearch}?postCode=${postCode}`,{header:{"Content-Type":"application/json"}});
        
        if(!response.data.success){
          setFetchError(`${response.data.message}`)
          setIsLoading(false)
          return
        }
        
        if(response.data?.words.length>0){
          setProducts(response.data?.words)
          setFilterdProducts(response.data?.words)
          getCategories(response.data?.words)
          
        }

        if(response.data.words.length===0 && response.data?.allProducts.length>0){
          setProducts(response.data?.allProducts)
          setFilterdProducts(response.data?.allProducts)
          getCategories(response.data?.allProducts)
        }
       
        setData(response.data)
        setIsLoading(false)
        setShowResult(true)
      }
    } catch (error) {
      setIsLoading(false)
      setFetchError('Une erreur est survenue')
    }
    
  }

  const closeSearch=()=>{
    setShowResult(false)
    setData({})
    setWordToSearch("")
    setIsLoading(false)
    setFetchError(null)
  }

  useEffect(()=>{
    if(wordToSearch.length===0){
      closeSearch()
    }
  },[wordToSearch])

  const styles = {
    main: {
      //width: "200px",
      height:"65vh",
      //border: "1px solid #2d2d2d",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    section: {
      //width: "200px",
      
      
    },
   
  };

  const handleCloseMultipleArticle=(e)=>{
    e.preventDefault()
    
    window.$(`#multipleArticle`).modal("hide")
    setIsLoadingProduct(true)
    setArticleSizes([])
    setPickPrice(0)
  }

  const showErrorBasket=(message)=>{
    setMessageError(message)
    setArticleSizes([])
    window.$("#multipleArticle").modal("hide")
    window.$("#errorBasket").modal("show")
    setTimeout(()=>{
      window.$("#errorBasket").modal("hide")
    },3000)
  }

  const showSuccessBasket=()=>{
    window.$("#multipleArticle").modal("hide")
    setArticleSizes([])
    window.$("#successBasket").modal("show")
    setTimeout(()=>{
      window.$("#successBasket").modal("hide")
      if(moveToBasket){
        navigate('/panier')
      }
    },1500)
    
  }

  
  const addToServerBasket= async(idBookProd,idProduct,idBookSize,quantity)=>{
   
    let body={idBookProd,idProduct,idBookSize,quantity,idUser,postCode}
    const response = await axios.post(`${urlData()}/addProductBasket`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
    if(!response.data.success) {
      if(showPopupError)showErrorBasket(response.data.message)
      return
    }
    if(!response.data.addProduct) {
      if(this.props.showPopupError) showErrorBasket(response.data.message)
      return
    }
    addTotalBasket(parseInt(quantity))
    console.log("deleteFromWishlist",deleteFromWishlist)
    if(deleteFromWishlist){
      const responseDeleteWishlist = await axios.delete(`${urlData()}/wishlist/${idUser}/${idBookProd}`,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${this.state.token}`}});
    }
    console.log("showPopupSuccess",showPopupSuccess)
    console.log("moveToBasket",moveToBasket)
    if(showPopupSuccess){showSuccessBasket()}
    if(!showPopupSuccess && moveToBasket){
      
      window.$("#multipleArticle").modal("hide")
      setArticleSizes([])
      navigate('/panier')
    }

  }


 







  return (
    <div>
      <header className="section-header">
        <section className="header-main border-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xs-12 col-xl-2 col-lg-3 col-md-12  ">
                <Link to={`/`} className="brand-wrap"><img className="logo" alt="LNVX" src="https://api.lnvx.fr/images/logo/logo.png"/></Link>
                <Link to={`/`} className="brand-wrap d-none d-xs"><i className="fa fa-home" style={{color:"#12225b",fontSize:"30px",marginLeft:"5px",}} title="Accueil"></i></Link>
              </div>
              
              
              <div className="col-xl-5 col-lg-5 col-md-6">
                <div className="row align-items-center">
                  <div className="d-none d-sm-inline col-1 ">
                      <div className="widget-header mr-2">
                        <Link to="/" className="widget-view">
                            <div className="icon-area">
                              <i className="fa fa-home" style={{color:"#12225b"}} title="Accueil"></i>
                            </div>
                        </Link>
                      </div>
                  </div>
                  <div className="col-11">
                    <form onSubmit={handleSearch} className="search-header">
                    
                        <div className="input-group w-100">
                        
                          <input type="text" onChange={(e)=>setWordToSearch(e.target.value)} className="form-control" value={wordToSearch}/>
                          
                          <div className="input-group-append">
                            <button onClick={handleSearch} className="btn btn-secondary" type="button">
                              <i className="fa fa-search"></i> Rechercher
                            </button>
                            
                          </div>
                        </div>
                    </form> 
                  </div>
                </div>
                
                
              </div> 
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="widgets-wrap float-md-right">
                  
                    
                    
                    {
                      connected &&
                      <div className="widget-header mr-3">
                      <Link to="/compte" className="widget-view">
                          <div className="icon-area">
                            <i className="fa fa-user"></i>
                          </div>
                          <small className="text"> Mon compte </small>
                      </Link>
                    </div>
                    
                    }

                    {
                      connected &&
                      <div className="widget-header mr-3">
                      <Link to="/commandes" className="widget-view">
                        <div className="icon-area">
                          <i className="fa fa-store"></i>
                        </div>
                        <small className="text"> Commandes </small>
                      </Link>
                    </div>
                    
                    }
                    
                  
                  
                   
                    {
                      !connected && <div className="widget-header mr-3">
                      <Link to="/connexion" className="widget-view">
                       
                          <div className="icon-area">
                            <i className="fa fa-user"></i>
                          </div>
                          <small className="text"> Connexion </small>
                          </Link>
                      </div>
                    }
                      
                    
                    
                  <div className="widget-header">
                    <Link to="/panier" className="widget-view">
                     
                        <div className="icon-area">
                          <i className="fa fa-shopping-cart"></i>
                          <span className="notify">{totalBasket}</span>
                        </div>
                        <small className="text"> Panier </small>
                      
                    </Link>
                  </div>
                  {!connected && postCode &&
                    <div className="widget-header">
                      <button onClick={handleChangePostCode} type='button'  className="btn widget-view">
                        <span style={{fontSize:"10px"}}>{townName}</span>
                        <div className="icon-area">
                          <i className="fa fa-search-location"></i>
                        </div>
                        
                        
                        <small className="text">Localité </small>
                      </button >
                      
                      
                    </div>
                  }
                  {connected &&
                    <div className="widget-header">
                        
                        <button type='button' onClick={handleLogOut} className="btn widget-view">
                          <div className="icon-area">
                            <i className="fa fa-user"></i>
                          </div>
                          <small className="text"> Déconnexion </small>
                        </button>
                    
                    </div>
                  }
                    
                   
                  
                </div> 
              </div> 
            </div> 
          </div>
        </section> 


        <nav className="navbar navbar-main navbar-expand-lg border-bottom">
          <div className="container">

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          
            <div className="collapse navbar-collapse" id="main_nav">
              <ul className=" navbar-nav">
                <li className="nav-item dropdown">
                  <button type='button' className="btn nav-link dropdown-toggle" data-toggle="dropdown" > <i className="fa fa-bars text-muted mr-2"></i> Catégories </button>
                  <div className="dropdown-menu">
                  <nav className="row">
                    <div className="col-6">
                    {categoriesMenu.map((data,index)=>{
                        return(
                          <div key={index}>
                            <Link to={`/categories/${data.idCategoryProduct}`}  className="dropdown-item">{data.nameCategoryProduct}</Link>
                          </div>
                        
                        )
                      })

                    }
                    
                    
                    <Link to="/categories" className="dropdown-item"> Toutes nos catégories</Link>
                    </div>
                    </nav>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to="/nouveautes" className="nav-link">Nouveautés</Link>
                </li>
                <li className="nav-item">
                  <Link to="/promotions" className="nav-link"> Promotions</Link>
                </li>
                <li className="nav-item">
                  <Link to="/flashs" className="nav-link">Flash</Link>
                </li>
                <li className="nav-item">
                  <Link to="/tendances" className="nav-link">Tendances</Link>
                </li>
                <li className="nav-item">
                  <Link to="/marques" className="nav-link">Nos marques</Link>
                </li>
               
              </ul>
              
            </div> 
          </div> 
        </nav>
        {!connected &&
          <ChangePostCode/>
        }
        {connected &&
        
          <div className="modal" id="logoutModal"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">DECONNECTION</h5>
                  
                </div>
                <div className="modal-body">
                    <h6>Se déconnecter?</h6>
                  
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-success" onClick={logout}>Valider</button>
                    <button type="button" onClick={handleCloseLogOut} className="btn btn-light text-danger">Annuler</button>
                  
                </div>
              </div>
            </div>
          </div>
        }
      </header>
      {isLoading && 
        <div className="container">
        <div className="row">
          <div className="col-lg-12">
          
          <div style={styles.main}>
            <div style={styles.section}>
                <p style={{display: "flex",justifyContent: "center",alignItems: "center"}} ><img style={{width:"120px"}} src="https://api.lnvx.fr/images/logo/logo.png" alt="Logo LNVX"/></p>
                <h5 >RECHERCHE EN COURS</h5>
                <p style={{display: "flex",justifyContent: "center",alignItems: "center"}}>Recherche.....</p>
            </div>
            
          </div>

      
          </div>
        </div>
      </div> 
      
      }

      {fetchError && 
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            
            <div style={styles.main}>
              <div style={styles.section}>
                  <p style={{display: "flex",justifyContent: "center",alignItems: "center"}} ><img style={{width:"120px"}} src="https://api.lnvx.fr/images/logo/logo.png" alt="Logo LNVX"/></p>
                  <h5 >UNE ERREUR EST SURVENUE !</h5>
                  <p style={{display: "flex",justifyContent: "center",alignItems: "center"}}>Chargement.....</p>
              </div>
              
            </div>

        
            </div>
          </div>
        </div>
      }


      {
        showResult && 
        <div className="container">
            <section className="section-content padding-y">
                <div className="container">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 text-center mb-4"> 
                          <button type='button' className='btn btn-danger ' onClick={closeSearch}>Fermer la recherche</button>
                        </div> 
                      </div>
                      <div className="row">
                        <div className="col-md-2"> Vous êtes ici: </div> 
                        <nav className="col-md-8"> 
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to={`/`}>
                                Accueil
                              </Link>
                            </li>
                            <li className="breadcrumb-item">Recherche: {wordToSearch}</li>
                            
                        </ol>  
                        </nav>
                      </div>
                      <hr/>
                      <div className="row">
                        <div className="col-md-2">Filter par</div>
                        <div className="col-md-10"> 
                          <ul className="list-inline">
                            
                            <li className="list-inline-item mr-3 dropdown"><button type="button" className="btn dropdown-toggle" data-toggle="dropdown">   Catégorie </button>
                                  <div className="dropdown-menu p-3" style={{"maxWidth":"400px"}}>
                                    <label className="form-check">
                                      <input type="radio" defaultChecked name="myfilter" onChange={()=>setNameCategory("all")}  className="form-check-input"/> Tous	
                                    </label>
                                    {categories?.map((e,index)=>{
                                      return(
                                        <label key={index} className="form-check">
                                         <input key={e} type="radio"  name="myfilter" onChange={()=>setNameCategory(e)}  className="form-check-input"/><span style={{fontSize:"10px"}}>{e}</span><br/>
                                         </label>
                                       
                                      )
                                    }
                                      
                                    )}
                                   
  
                                  </div>
                              </li>
                              {
                                nameCategory!=='all' &&
                                <li className="list-inline-item mr-3 dropdown">
                                {nameCategory}
                              </li>
                              }
                              
                            
                            <li className="list-inline-item mr-3">
                              <div className="form-inline">
                                <label className="mr-2">Prix</label>
                              <input className="form-control form-control-sm" onChange={(e)=>setPriceMin(e.target.value)} value={priceMin} placeholder="Min" type="number"/>
                                <span className="px-2"> - </span>
                              <input className="form-control form-control-sm" onChange={(e)=>setPriceMax(e.target.value)} value={priceMax} placeholder="Max" type="number"/>
                              <button type="button"  onClick={filterSearch} className="btn btn-sm btn-light ml-2">Ok</button>
                            </div>
                            </li>
                           
                          </ul>
                        </div>
                      </div> 
                    </div> 
                  </div> 
                
            
                  {/*<header className="mb-3">
                      <div className="form-inline">
                        <strong className="mr-md-auto">{infos.sizesProduct} articles trouvés </strong>
                      </div>
                  </header>*/}
            
                  
                    {
                      data.words.length>0 &&
                      <div className="row">
                          {filterdProducts.map((e,index)=>
                        <ArticleBox key={index}  idBookProd={e.idBookProd} image={e.uniqIdDoc} 
                        nameProduct={e.nameProduct} 
                        price={e.price} 
                        showPrices={showPrices} 
                        idBookSizePromo={e.idBookSizePromo}
                        newPricePromo={e.newPricePromo}
                        oldPricePromo={e.oldPricePromo}
                        isFlash={e.isFlash} 
                        articlePromo={e.articlePromo}
                        sellBy={"LNVX"} stock={e.stock}
                        idProduct={e.idProduct}
                        idBookSize={e.idBookSize}
                        article={e}
                        />
                      )}
                      </div> 
                    }
                    
                  
                   
            
                  
                </div> 
              </section>
              <section>
              { data.words.length===0 && data.allProducts.length>0 &&

                <div className="row">
                    <div className="col-md-12">
                      <div className="card mb-3">
                        <div className="card-body">
                          <header className="mb-3">
                          <div className="form-inline">
                            <strong className="mr-md-auto">0 articles trouvés </strong>
                          </div>
                          </header>   
                        </div>
                      </div>
                    </div>
                    
                    
                            
                    
                </div>

                }
                { data.words.length===0 && data.allProducts.length>0 &&

                <SectionArticles  linkApiImages={linkApiImages} nameSection="Nos recommendations" sectionData={filterdProducts}  />
                }
              </section>
              <section>
              { data.words.length===0 && filterdProducts.length===0 &&

                <div className="row">
                    <div className="col-md-12">
                      <div className="card mb-3" style={{height:"40vh"}}>
                        <div className="card-body">
                          <header className="mb-3">
                          <div className="form-inline">
                            <strong className="mr-md-auto">0 articles trouvés  </strong>
                            
                          </div>
                          </header>   
                          <button type='button' className='btn btn-secondary' onClick={seeAllArticles}>Voir tous les articles</button>
                        </div>
                      </div>
                    </div>
                    
                </div>

                }
               
              </section>
        </div>
      }
      {
        !showResult && !isLoading && children
      }
      {
        !postCode &&  <Modal />
      }
      <ModalChangePostCode showModal={showModal} setShowModal={setShowModal}/>
      <Footer postCode={postCode}/>
      <div className="modal" id="expiredSessionModal"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">SESSION EXPIREE</h5>
              
            </div>
            <div className="modal-body">
                <h6>Session expirée</h6>
                <p>Votre session à expirée </p>
            </div>
            <div className="modal-footer">
              <Link>
                <button type="button" className="btn btn-success" onClick={()=>{
                      //window.$("#expiredSessionModal").modal("hide")
                      setTimeout(()=>{
                        window.location.reload();
                      },2000)
                     
                    }
                  
                  } >Page d'accueil</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="multipleArticle" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">CHOISIR UN ARTICLE</h5>
            </div>
            <div className="modal-body">
              
              <div className="row ">
                <div className='col-md-12 mb-2'>
                  <h5 className="title text-center">{articleSizes && articleSizes.length>0?articleSizes[0].nameProduct:""} </h5>
                </div>
                <div className='col-md-12 mb-2'>
                  {articleSizes.length===0 && isLoadingProduct && <p>Chargement......</p>}
                  <div className='input-group '>
                    {articleSizes.length>0 && articleSizes.map((v,index) => {
                        return (
                        
                            <button key={index} disabled={v.stock_bookprosize===0?true:false}   onClick={e =>{
                              if(v.stock_bookprosize>0){
                                setPickPrice(index)
                              }
                              
                            }} className={
                              v.stock_bookprosize>0?index==pickPrice?"btn btn-rounded btn-outline-secondary bg-secondary mr-1 mb-1":"btn btn-rounded btn-outline-secondary mr-1 mb-1":"btn btn-rounded btn-outline-info bg-info mr-1 mb-1 "} style={{"minWidth":"100px","width":"150px"}} >
                              {v.stock_bookprosize>0 && <p className={index==pickPrice?"text-white":""}>{v.nameSizing}<br/>
                              {v.nameColor}<br/>
                                  {v?.articlePromo?dotToComma(v?.newPricePromo):dotToComma(v?.price)}€
                                  {v?.articlePromo &&<br/>}
                                  {v?.articlePromo &&<del>{dotToComma(v?.oldPricePromo)}€</del>
                                  }
                              </p>}
                              {v.stock_bookprosize===0 && <p className='text-danger'>Indisponible</p>}
                            </button>
                          
                        )
                      
                      
                    })}

                  </div>
                </div>
                
               
              </div>
            </div>
            <div className="modal-footer">
          
              {/* resultTowns.length===0 &&
                <button type="button" title='Vous devez sélectionner une ville' disabled className="btn btn-success">Valider</button>
              */}

            
                <button type="button" className="btn btn-success" onClick={(e)=>{
                  let idBookProd=articleSizes.length>0?articleSizes[pickPrice].idBookProd:0;
                  let idProduct=articleSizes.length>0?articleSizes[pickPrice].idProduct:0;
                  let idBookSize=articleSizes.length>0?articleSizes[pickPrice].idBookSize:0;
                  let quantity=1
                  let stock=articleSizes.length>0?articleSizes[pickPrice].stock_bookprosize:0
                  if(!connected && stock>0){
                    addBasket({idBookProd,idProduct,idBookSize,quantity})
                    showSuccessBasket()
                    return
                  }
                  if(stock>0)addToServerBasket(idBookProd,idProduct,idBookSize,quantity)
                  return
                }
                 }>Valider</button>
              
              <button type="button" onClick={(e)=>handleCloseMultipleArticle(e)} className="btn btn-danger " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="successBasket"  tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">PANIER</h5>
            </div>
            <div className="modal-body">
                <p className="icontext"><i className="icon text-success fa fa-check"></i>Ajouter au panier.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="errorBasket"  tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">PANIER</h5>
            </div>
            <div className="modal-body">
            <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {messageError?messageError:"Erreur, l'article n'a pas pu être ajouté au panier"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
         
    </div>
  )
}

export default Template

