import React, { useEffect, useState,useRef } from 'react'
import ErrorFiveHundred from '../errors/ErrorFiveHundred';
import Loading from '../loading/Loading';
import Template from '../Template';
import { Link } from 'react-router-dom'
import FindPostCode from '../postCode/FindPostCode';
import ArticleBox from '../articleBox/ArticleBox';
import { random_sort,commaToDot } from '../../services/helpers';
import FilterTemplate from '../FilterProduct';


function SectionTemplate({isLoading,fetchError,titleSection,infos,showPrices}) {

  
  const [filterdProducts, setFilterdProducts] = useState([])
  const [ nameCategory, setNameCategory] = useState('all')
  const [ priceMin, setPriceMin] = useState("")
  const [ priceMax, setPriceMax] = useState("")
  //const priceMinPrevious = useRef("");
  //const priceMaxPrevious = useRef("");
 // const nameCategoryPrevious = useRef("all");
  const [products, setProducts] = useState([])

  let allProducts=infos.allNouveautes||[]
  allProducts=allProducts.length>0?allProducts.sort(random_sort):[]

  /*useEffect(() => {
    priceMinPrevious.current = priceMin; 
    priceMaxPrevious.current = priceMax; 
    nameCategoryPrevious.current = nameCategory; 
  }, [nameCategory,priceMin,priceMax]);*/

  useEffect(() => {
    setProducts(infos.allNouveautes)
    setFilterdProducts(infos.allNouveautes)
  }, [infos])


  const findByPriceMin=(product)=>{
    console.log('call min ')
    console.log(`commaToDot(priceMin): ${commaToDot(priceMin)}`)
    if(product.idBookSizePromo){
      return product.newPricePromo>=commaToDot(priceMin)
    }else{
      return product.price>=commaToDot(priceMin)
    }
  }

  const findByPriceMax=(product)=>{
    console.log('call max')
    if(product.idBookSizePromo){
      return product.newPricePromo<=commaToDot(priceMax)
    }else{
      return product.price<=commaToDot(priceMax)
    }
    
  }

  const findByPriceMinMax=(product)=>{
    console.log('call min && max')
    if(product.idBookSizePromo){
      return product.newPricePromo>=commaToDot(priceMin) && product.newPricePromo<=commaToDot(priceMax)
    }else{
      return product.price>=commaToDot(priceMin) && product.price<=commaToDot(priceMax)
    }
  }


  const seeAllArticles=()=>{
    setNameCategory("all")
    setFilterdProducts(products)
    setPriceMax('')
    setPriceMin('')
    
  }

  const filterSearch= ()=>{
      let dataProducts= products
      if(nameCategory!=='all'){
        dataProducts=products.filter(product=>product.nameCatPro === nameCategory)
      }
      if(priceMin && priceMax && dataProducts.length>0){
        let filtered= dataProducts.filter(findByPriceMinMax)
        filtered=filtered.sort(random_sort)
        setFilterdProducts(filtered)
        return
      }

      if(priceMin && !priceMax && dataProducts.length>0){
        let filtered= dataProducts.filter(findByPriceMin)
        filtered=filtered.sort(random_sort)
        setFilterdProducts(filtered)
        return
      }

      if(!priceMin && priceMax && dataProducts.length>0){
        let filtered= dataProducts.filter(findByPriceMax)
        filtered=filtered.sort(random_sort)
        setFilterdProducts(filtered)
        return
      }
      
      let filtered=nameCategory!=='all'? products.filter(e=> e.nameCatPro ===nameCategory ):products
      filtered=filtered.sort(random_sort)
    
      setFilterdProducts(filtered)
  }




  return (
    <div>
      
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError &&
        <React.Fragment >
          <FilterTemplate allProducts={infos.allNouveautes} titleSection={titleSection} infos={infos} showPrices={showPrices}/>
        </React.Fragment>
        
       
      }
      
    </div>
  )
}

export default SectionTemplate


 {/*<Template>
        <div className="container">
        <section className="section-content padding-y">
                <div className="container">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-2"> Vous êtes ici: </div> 
                        <nav className="col-md-8"> 
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to={`/`}>
                                Accueil
                              </Link>
                            </li>
                            <li className="breadcrumb-item">{titleSection}</li>
                            
                        </ol>  
                        </nav>
                      </div>
                      <hr/>
                      <div className="row">
                        <div className="col-md-2">Filter par</div>
                        <div className="col-md-10"> 
                          <ul className="list-inline">
                            
                            <li className="list-inline-item mr-3 dropdown"><button type="button" className="btn dropdown-toggle" data-toggle="dropdown">   Catégorie </button>
                                  <div className="dropdown-menu p-3" style={{"maxWidth":"400px"}}>
                                    <label className="form-check">
                                      <input type="radio" defaultChecked name="myfilter" onChange={()=>setNameCategory("all")}  className="form-check-input"/> Tous	
                                    </label>
                                    {infos?.catPrimaries?.map((e,index)=>{
                                      return(
                                        <label className="form-check">
                                         <input key={e} type="radio"  name="myfilter" onChange={()=>setNameCategory(e)}  className="form-check-input"/><span style={{fontSize:"10px"}}>{e.nameCategoryProduct}</span><br/>
                                         </label>
                                       
                                      )
                                    }
                                      
                                    )}
                                   
  
                                  </div>
                              </li>
                              {
                                nameCategory!=='all' &&
                                <li className="list-inline-item mr-3 dropdown">
                                {nameCategory}
                              </li>
                              }
                              
                            
                            <li className="list-inline-item mr-3">
                              <div className="form-inline">
                                <label className="mr-2">Prix</label>
                              <input className="form-control form-control-sm" onChange={(e)=>setPriceMin(e.target.value)} value={priceMin} placeholder="Min" type="number"/>
                                <span className="px-2"> - </span>
                              <input className="form-control form-control-sm" onChange={(e)=>setPriceMax(e.target.value)} value={priceMax} placeholder="Max" type="number"/>
                              <button type="button"  onClick={filterSearch} className="btn btn-sm btn-light ml-2">Ok</button>
                            </div>
                            </li>
                           
                          </ul>
                        </div>
                      </div> 
                      
                    </div> 
                  </div> 
                
            
                  <header className="mb-3">
                      <div className="form-inline">
                        <strong className="mr-md-auto">{infos.sizesProduct} articles trouvés </strong>
                      </div>
                  </header>
            
                  <div className="row">
                    {filterdProducts?.map((e,index)=>{
                        return(
                          <ArticleBox key={index}  idBookProd={e.idBookProd} image={e.uniqIdDoc} 
                        nameProduct={e.nameProduct} 
                        price={e.price} 
                        showPrices={showPrices} 
                        idBookSizePromo={e.idBookSizePromo}
                        newPricePromo={e.newPricePromo}
                        oldPricePromo={e.oldPricePromo}
                        isFlash={e.isFlash} 
                        articlePromo={e.articlePromo}
                        sellBy={"LNVX"} stock={e.stock}
                        idProduct={e.idProduct}
                        idBookSize={e.idBookSize}
                        article={e}
                        />
                        )
                      }
                    
                    )}
                  </div> 
            
            
                  
                </div> 
              </section>
        </div>
        <FindPostCode/>
        </Template>*/}





/*
<div className="row">
                        <div className="col-md-2">Filter par</div>
                        <div className="col-md-10"> 
                          <ul className="list-inline">
                            
                            <li className="list-inline-item mr-3 dropdown"><button type="button" className="dropdown-toggle" data-toggle="dropdown">   Catégorie </button>
                                  <div className="dropdown-menu p-3" style={{"maxWidth":"400px"}}>
                                    <label className="form-check">
                                      <input type="radio" name="myfilter" value="all" className="form-check-input"/> Tous	
                                    </label>
                                    {infos.catPrimaries?.map((e,index)=>{
                                      return(
                                        <>
                                         <input type="radio" key={index} name="myfilter" defaultValue={e.idCategoryProduct} className="form-check-input"/>{e.nameCategoryProduct}
                                        </>
                                       
                                      )
                                    }
                                      
                                    )}
                                   
  
                                  </div>
                              </li>
                              <li className="list-inline-item mr-3 dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">  Couleurs </a>
                                  <div className="dropdown-menu p-3">	
                                  <label className="form-check">
                                      <input type="radio" name="myfilter2" value="all" className="form-check-input"/> Tous	
                                    </label>
                                  </div> 
                              </li>
                            
                            <li className="list-inline-item mr-3">
                              <div className="form-inline">
                                <label className="mr-2">Prix</label>
                              <input className="form-control form-control-sm" placeholder="Min" type="number"/>
                                <span className="px-2"> - </span>
                              <input className="form-control form-control-sm" placeholder="Max" type="number"/>
                              <button type="submit" className="btn btn-sm btn-light ml-2">Ok</button>
                            </div>
                            </li>
                           
                          </ul>
                        </div>
                      </div> 

*/