import React, { useEffect, useState} from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import {urlData} from '../../services/url';
import {random_sort} from '../../services/helpers';


function Footer({postCode}) {
 
  const [brands,setBrands]=useState([]) 

  useEffect(()=>{

    async function callGetBrands(){
      console.log("callBrands")
      try {
        const response = await axios.get(`${urlData()}/brands?postCode=${postCode}`,{header:{"Content-Type":"application/json"}});
        if(response.data.success){
          
          if(response.data?.brands.length>0){ 
            let sectionData=response.data?.brands.length>3?response.data?.brands.sort(random_sort).slice(0,4):response.data?.brands.sort(random_sort)
            setBrands(sectionData)
          }
          return
        }
      } catch (error) {
        
      }
    }
    callGetBrands()
  },[])

  const stylePage={
    li:{
      ':hover': {
        color: "red"
      }
    }
  }

  return (
    <footer className="section-footer bg-secondary">
        <div className="container">
          <section className="footer-top padding-y-lg text-white">
            <div className="row">
              <aside className="col-md col-6">
                
                <ul className="list-unstyled">
                  <li> <a target={'_blank'} href='https://play.google.com/store/apps/details?id=com.lnvx.lnvx_app'><img width={120} src={`${window.location.origin}/images/google_play_store.png`} alt="playstore"/></a></li>
                  <li> <a target={'_blank'} href='https://apps.apple.com/fr/app/opener-open-links-in-apps/id1595627048'><img width={120} src={`${window.location.origin}/images/apple_store.png`} alt="appleStore"/></a></li>
                  
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h6 className="title">Marques</h6>
                <ul className="list-unstyled" >
                  {brands.length>0 && 
                    brands.map((e,index)=>{
                      return <li style={stylePage.li} key={index}> <Link to={`/marques/${e.idBrand}`}>{e.nameBrand}</Link></li>
                    })
                  }
                  <li style={stylePage.li}> <Link to='/marques'>Toutes nos marques</Link></li>
                </ul>
              </aside>
              
              <aside className="col-md col-6">
                <h6 className="title">Aide</h6>
                <ul className="list-unstyled">
                  <li> <Link to='/contact' style={{"&:hover":"white"}}>Nous contacter</Link></li>
                  <li> <Link to='/conditions' style={{textDecoration:"white"}}>Conditions générales</Link></li>
                 
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h6 className="title">Mon compte</h6>
                <ul className="list-unstyled">
                  <li> <Link to='/connexion'> Se connecter </Link></li>
                  <li> <Link to='/inscription'> Inscription </Link></li>
                  <li> <Link to='/compte'> Paramètres </Link></li>
                  <li> <Link to='/commandes'> Mes commandes </Link></li>
                </ul>
              </aside>
              <aside className="col-md">
                <h6 className="title">Suivez-nous</h6>
                <ul className="list-unstyled">
                  <li><a href='https://www.facebook.com/lnvx.fr/'> <i className="fab fa-facebook"></i> Facebook </a></li>
                  <li><a href='https://www.instagram.com/lnvx.fr/'> <i className="fab fa-instagram"></i> Instagram </a></li>
                  
                </ul>
              </aside>
            </div> 
          </section>	

          <section className="footer-bottom text-center">
              <p className="text-muted"> &copy; 2019 LNVX, Tous droits réservés </p>
              <br/>
          </section>
        </div>
      </footer>
  )
}

export default Footer