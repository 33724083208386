
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import store from './store'
import Homepage from './Homepage';
import Newers from './Newers';
import Tenders from './Tenders';
import Promotions from './Promotions';
import Flashs from './Flashs';
import Brands from './Brands';
import Basket from './Basket';
import Article from './Article';
import Login from './Login';
import Register from './Register';
import Profil from './Profil';
import Orders from './Orders';
import OrderPage from './OrderPage';
import InfosCustomer from './InfosCustomer';
import Categories from './Categories';
import Category from './Category';
import Brand from './Brand';
import Contact from './Contact';


function App() {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage/>} />
          <Route path="/connexion" element={<Login/>} />
          <Route path="/inscription" element={<Register/>} />
          <Route path="/nouveautes" element={<Newers/>} />
          <Route path="/flashs" element={<Flashs/>} />
          <Route path="/promotions" element={<Promotions/>} />
          <Route path="/tendances" element={<Tenders/>} />
          <Route path="/marques" element={<Brands/>} />
          <Route path="/marques/:idBrand" element={<Brand/>} />
          <Route path="/panier" element={<Basket/>} />
          <Route path="/articles/:idArticle" element={<Article/>} />
          <Route path="/compte" element={<Profil/>} />
          <Route path="/commandes" element={<Orders/>} />
          <Route path="/commandes/:idOrder" element={<OrderPage/>} />
          <Route path="/conditions" element={<InfosCustomer/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/categories" element={<Categories/>} />
          <Route path="/categories/:idCategory" element={<Category/>} />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
