import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import SectionTemplate from './components/sectionTemplate/SectionTemplate';
import {urlData} from './services/url';

function Newers() {
  
  const postCode= useStoreState((state)=>state.postCode)
  const [newersPage, setNewerPage] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)
  
  
  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/newerspage?postCode=${postCode}`);
          console.log(response.data)
          if(response.data.success) {
            setFetchError(null);
            setNewerPage(response.data);
            
          }else{
           
            setFetchError(response.data.message);
          }
      } catch (err) {
       
        
          setFetchError(err.message);
      } finally {
        !postCode?setShowPrices(false):setShowPrices(true)
         
          setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode])
//SectionTemplate({isLoading,fetchError,titleSection,infos})

  return (
    <SectionTemplate isLoading={isLoading} fetchError={fetchError} titleSection='Nouveaute' infos={newersPage} showPrices={showPrices}/>
    
  )
}

export default Newers
