import React from 'react'
import './Modal.css'


function ModalRegisterSuccess({firstname,surname,showModal}) {

  return (
    <div className={showModal ? "modal-container" : "hidden"}>
      <div className="card">
        <div className="card-body">
          <h5 class="card-title">INSCRIPTION</h5>
          <h6>Inscription</h6>
          <p>Bienvenue {firstname} {surname}.</p>
          <div className="alert alert-success mt-3">
              <p className="icontext"><i className="icon text-success fa fa-check"></i>Votre compte a été crée.</p>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default ModalRegisterSuccess