import React,{ useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import {useStoreState, useStoreActions } from 'easy-peasy';
import axios from 'axios';
import {urlDataImage,urlData} from '../../services/url';
import {validateEmail} from '../../services/helpers';
import UsernameChange from './UsernameChange';
import IdentityChange from './IdentityChange';
import LocalityChange from './LocalityChange';
import PasswordChange from './PasswordChange';
import UserDeleteAccount from './DeleteAccountUser';

function SettingsProfil({profil,refreshData}) {

  const postCode= useStoreState((state)=>state.postCode)
  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [checked, setChecked] = useState(profil.dataUser.newsletters);
  const [messageErrorParams, setMessageErrorParams] = useState("");
  const [messageSuccessParams, setMessageSuccessParams] = useState("");
  
 

  const hideFormError=()=>{
    window.$(".formChangeUsername").hide()
  }
  hideFormError()

  const handleNewsletter= async ()=>{
    if(!connected) return
    setIsLoadingData(true)
    let infos= !checked?1:0
    setChecked(infos)
    let body ={
      idUser,infos
    }
    const responseNewsletter = await axios.post(`${urlData()}/newsletter`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
    console.log("responseNewsletter")
    console.log(responseNewsletter)
    console.log("responseNewsletter")
    if(!responseNewsletter.data.success){
      setMessageErrorParams(responseNewsletter.data.message)
      setIsLoadingData(false)
      window.$("#errorParams").modal("show")
      setTimeout(()=>{
        window.$("#errorParams").modal("hide")
        setMessageErrorParams("")
      },2500)
      return
    }
    setIsLoadingData(false)
    setMessageSuccessParams("Le service a été mis à jour")
    window.$("#successParams").modal("show")//
    setTimeout(()=>{
      window.$("#successParams").modal("hide")
      setMessageErrorParams("")
    },2500)
    refreshData()
    return
    
  }

  return (
    <main className="col-md-9" style={{height:"75vh",overflow: "hidden",overflowY: "scroll"}}>
      <article className="card mb-4">
        <header className="card-header">
          <strong className="d-inline-block mr-3">NEWSLETTER</strong>
        </header>
        <div className="card-body">
          <div className="row" > 
            <div className="col-md-8">
              <h6 className="text-muted">Gérer le service de newsletter</h6>
              
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" disabled={isLoadingData?true:false} onChange={handleNewsletter} checked={checked} id="newsletter" defaultValue={profil.dataUser.newsletters} />
                <label className="form-check-label" htmlFor="newsletter">Service {!checked?'inactif':'actif'}</label>
              </div>
              
            </div>
          </div> 
        </div> 
      </article> 
      <UsernameChange profil={profil} refreshData={refreshData}/>
      <IdentityChange profil={profil} refreshData={refreshData}/>
      <LocalityChange profil={profil} refreshData={refreshData}/>
      <PasswordChange profil={profil} refreshData={refreshData}/>
      <UserDeleteAccount/>
      
      
      
      <div className="modal" id="errorParams"  tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">PARAMETRES</h5>
            </div>
            <div className="modal-body">
            <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {messageErrorParams?messageErrorParams:"Erreur, laction n'a pas pu être effectuer."}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="successParams"  tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">PARAMETRES</h5>
            </div>
            <div className="modal-body">
                <p className="icontext"><i className="icon text-success fa fa-check"></i>{messageSuccessParams?messageSuccessParams:"L'action a été effectué avec succées."}</p>
            </div>
          </div>
        </div>
      </div>
      
    </main>
  )
}

export default SettingsProfil