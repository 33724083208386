import React from 'react'

function ArticleEight() {
  return (
    <>
        <h5 class="card-title">Article 8</h5>
        <p>
        Données personnelles Un certains nombre de données seront demandés lors du passage de la commande (identité, adresse de résidence, numéro de téléphone, adresse de livraison, adresse électronique et moyen de paiement). Ces données seront utilisées afin de pouvoir traiter la commande, celle-ci pourront aussi être transmise à nos partenaires dans le cadre de l’exécution de la commande. Conformément à la loi informatique et Libertés du 6 janvier 1978, le Client dispose d’un droit d’accès, de modification, de rectification et de suppression des données personnelles le concernant.
        </p>
    </>
  )
}

export default ArticleEight