import React from 'react'

function ArticleFour() {
  return (
    <>
        <h5 class="card-title">Article 4</h5>
        <p>
          Paiement Le paiement se réalise par carte bancaire et au passage de la commande. Le client assure être le titulaire de la carte de crédit permettant l’achat. Lors du paiement le client sera amené à communiquer le numéro et la date d’expiration figurant au recto de la carte ainsi que les numéros du cryptogramme visuel figurant au verso (ou au recto) de sa carte bancaire. Dans l’hypothèse où, pour quelque raison que ce soit (opposition, refus du centre émetteur…), le débit des sommes dues par le client s’avérerait impossible, le processus d’achat sur Internet sera annulé. En cas d’une transaction réussie, une facture sera adressée à l’adresse e-mail indiquée lors de la commande. Elle est établie conformément au tarif en vigueur au jour du passage de la commande. La société Les nouveaux se dégage de toute responsabilité quant aux erreurs de saisies.
        </p>
    </>
  )
}

export default ArticleFour