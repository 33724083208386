import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred'
import Loading from './components/loading/Loading'
import FindPostCode from './components/postCode/FindPostCode'
import Template from './components/Template';

import { useNavigate } from 'react-router-dom'
import MainProfil from './components/profil/MainProfil';
import OrdersProfile from './components/profil/OrdersProfile';
import WishListProfil from './components/profil/WishListProfil';
import {urlData} from './services/url';
import SettingsProfil from './components/profil/SettingsProfile';


function Profil() {

  const postCode= useStoreState((state)=>state.postCode)
  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [profil, setProfil] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [viewPage, setViewPage] = useState(0)
  const navigate = useNavigate()
  const voidInfo= ''


  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      if(!connected){
        navigate('/connexion')
      }else{
        try {
          const response = await axios.get(`${urlData()}/profile-user/${idUser}`,{headers:{'content-type': 'application/json', 'Accept': 'application/json','Authorization': `Bearer ${token}`}});
        
          if(response.data.success) {
            setFetchError(null);
            setProfil(response.data);
            
          }else{
           
            setFetchError(response.data.message);
          }
      } catch (err) {
       
         
          setFetchError(err.message);
      } finally {
       
          setIsLoading(false);

      }
      }
      
  }
   
  fetchData()
},[voidInfo,connected,navigate,token,idUser])

  const refreshData= async ()=>{
    try {
      const response = await axios.get(`${urlData()}/profile-user/${idUser}`,{headers:{'content-type': 'application/json', 'Accept': 'application/json','Authorization': `Bearer ${token}`}});
      
      if(response.data.success) {
        setFetchError(null);
        setProfil(response.data);
        
      }else{
       
        setFetchError(response.data.message);
      }
    } catch (err) {
    
       
        setFetchError(err.message);
    } finally {
    
        setIsLoading(false);

    }
  }


  const handleViewProfil=(index)=>{
    setViewPage(index)
  }

  return (
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError &&
        <Template>
        <section className="section-content padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-md-3">
                <nav className="list-group">
                  <button type='button' onClick={()=>handleViewProfil(0)} className={viewPage===0?"btn list-group-item active":"btn list-group-item "} > Mon compte  </button>
                  <button type='button' onClick={()=>handleViewProfil(1)} className={viewPage===1?"btn list-group-item active":"btn list-group-item "} > Mes commandes </button>
                  <button type='button' onClick={()=>handleViewProfil(2)} className={viewPage===2?"btn list-group-item active":"btn list-group-item "} > Ma wishlist </button>
                  <button type='button' onClick={()=>handleViewProfil(3)} className={viewPage===3?"btn list-group-item active":"btn list-group-item "} > Paramètres </button>
                  {/*<button type='button' onClick={()=>{}} className="btn list-group-item" href="page-index-1.html"> Déconnexion </button>*/}
                </nav>
              </aside>
              { viewPage===0 &&
                <MainProfil profil={profil} handleViewProfil={handleViewProfil}/>
              }

              { viewPage===1 &&
                <OrdersProfile profil={profil}/>
              }

              { viewPage===2 &&
                <WishListProfil profil={profil} refreshData={refreshData} />
              }

              {
                viewPage===3 && 
                <SettingsProfil profil={profil} refreshData={refreshData}/>
              }
             
            </div>
          </div> 
        </section>
        <FindPostCode/>
      </Template>
      }
    </div>
  )
}

export default Profil