import React,{ useEffect, useState } from 'react'
import { Link, useNavigate   } from 'react-router-dom'
import { dotToComma } from '../../../../services/helpers'
import Template from '../../../Template'
import { useStoreActions,useStoreState } from 'easy-peasy';
import BasketEmpty from '../../BasketEmpty';
import {urlDataImage} from '../../../../services/url';

function DetailsBasket({data,showDeliveryInfos,basketEmpty,refreshData}) {
  
  const totalBasket= useStoreState((state)=>state.totalBasket)
  const linkApiImages=urlDataImage()
  const addBasket= useStoreActions((state)=>state.addBasket)
  const removeBasket= useStoreActions((state)=>state.removeBasket)
  const deleteBasket= useStoreActions((state)=>state.deleteBasket)
  const [ loadingBtn, setLoadingBtn ] = useState()
  const navigate = useNavigate();

  useEffect(() => {
    console.log('data basket details updated')
    refreshData()
  },[totalBasket])


  const getTotalItem=(quantity,price)=>{
    try {
      let priceTotal= quantity*price
      priceTotal= dotToComma(priceTotal)
      return priceTotal
    } catch (error) {
      return 'Erreur'
      
    }
  }


  const findAvailable =(bookProd,size,stock)=>{
    return bookProd!==0 && size!==0 && stock!==0?true:false
   }
 
   const quantityOverStock =(stock,quantity)=>{
     return quantity>stock?true:false
    }

  const addOne=async (idBookProd,idProduct,idBookSize,stock,quantityBasket,availableBookProd,availableSize)=>{
    setLoadingBtn(true)
    
    if(!findAvailable(availableBookProd,availableSize,stock)){
      window.$('#basketInfoDispo').modal('show')
      setLoadingBtn(false)
      return
    }

    if(quantityOverStock(stock,quantityBasket)){
      window.$('#basketInfoQuantity').modal('show')
      setLoadingBtn(false)
      return
    }
    const quantity=1;
     let value= await addBasket({idBookProd,idProduct,idBookSize,quantity})
     setLoadingBtn(false)
      
  }
  const removeOne=async (idBookProd,idProduct,idBookSize,stock,availableBookProd,availableSize)=>{
    setLoadingBtn(true)
    if(!findAvailable(availableBookProd,availableSize,stock)){
      window.$('#basketInfoDispo').modal('show')
      setLoadingBtn(false)
      return
    }
    const quantity=1;
    let value= await removeBasket({idBookProd,idProduct,idBookSize,quantity})
    
    setLoadingBtn(false)
  }
  const deleteArticle= async (idBookProd,idProduct,idBookSize)=>{
    setLoadingBtn(true)
    let value= await deleteBasket({idBookProd,idProduct,idBookSize})
    setLoadingBtn(false)
    
  }

  const productAvailable = (e) =>{
    if(e.stock<e.quantity)return false
    if(e.availableBookProd===0 || e.availableSize===0)return false
    return true
  };

  const checkValidNextStep=()=>{
  
    if(!Array.isArray(data?.infosPanier?.products) || data?.infosPanier?.products?.length===0)return
    if(!data?.infosPanier?.products.every(productAvailable)){
      window.$('#basketInfoAvailableProduct').modal("show")
      return
    }
    showDeliveryInfos()
    return
  }

  const checkValidNextStepLink=()=>{
  
    if(!Array.isArray(data?.infosPanier?.products) || data?.infosPanier?.products?.length===0)return
    if(!data?.infosPanier?.products.every(productAvailable)){
      window.$('#basketInfoAvailableProduct').modal("show")
      return
    }
    navigate("/connexion");
    return
  }


  if(basketEmpty){
    return(
      <BasketEmpty/>
    )
  }
  if(!basketEmpty){
    return (
      <Template>
              
        <section className="section-content padding-y">
          <div className="container">
  
          <div className="row">
            <main className="col-md-9">
              <div className="card" style={{minHeight:"65vh"}}>
                <div className="d-sm-none">
                  <table className="table table-borderless table-shopping-cart" >
                    <thead className="text-muted">
                      <tr className="small text-uppercase">
                        <th scope="col"  className='text-center '>Articles</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.infosPanier.products.map((e,index)=>{
                      
                        return(
                          <tr key={index}>
                            <td>
                              <table className="  table table-borderless table-shopping-cart">
                                <tr>
                                  <td colSpan={4}>
                                    <figure className="itemside"  >
                                      <div className="aside"><img src={`${linkApiImages}${e.nameImage}`} className="img-xs"/></div>
                                      <figcaption className="info">
                                        <Link to={`/articles/${e.idBookProd}`}  className="title text-dark">{e.nameProduct}</Link>
                                        {!findAvailable(e.availableBookProd,e.availableSize,e.stock)  &&
                                          <p className="text-danger small">Le produit est indisponible</p>
                                        }
                                        {findAvailable(e.availableBookProd,e.availableSize,e.stock) && quantityOverStock(e.stock,e.quantity)  &&
                                          <p className="text-danger small">Stock disponible {e.stock}</p>
                                        }
                                        {!e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.price)}€</p>}
                                        {e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.newPrice)}€ <span style={{textDecorationLine:"line-through"}}>{dotToComma(e.oldPrice)}€</span></p>}
                                        <p className="text-muted small">Taille: {e.nameSizing}, Couleur: {e.nameColor} {e.availableBookProd}{e.availableSize}</p>
                                        
                                      </figcaption>
                                    </figure>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-center ' style={{paddingTop:'22px',alignItems:"center"}} width="33%"> 
                                    <div className='text-center price-wrap'>
                                      <var className="price">
                                          { e.idBookPromotionSize?
                                            getTotalItem(e.quantity,e.newPrice)
                                            :
                                            getTotalItem(e.quantity,e.price)
                                                                          
                                          }
                                          €
                                      </var> 
                                    </div>
                                      
                                        
                                  </td>
                                  <td width="56%"> 
                                    
                                      <div className="input-group">
                                        
                                        <button className="btn btn-xs"  style={{width:"10px"}}  disabled={loadingBtn?true:false} onClick={()=>removeOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity,e.availableBookProd,e.availableSize)} type="button" id="button-minus"> &minus; </button>
                                        
                                        <div style={{paddingTop:'9px'}}>{e.quantity}</div>
                                        
                                        <button className="btn btn-xs" style={{width:"10px"}}  disabled={loadingBtn?true:false} onClick={()=>{
                                              
                                              addOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity,e.availableBookProd,e.availableSize)
                                              
                                            }} type="button" id="button-plus"> + </button>
                                        
                                      </div>
                                  
                                    
                                  </td>
                                  
                                  <td  width="10%"> 
                                    <button type="button" disabled={loadingBtn?true:false} onClick={()=>deleteArticle(e.idBookProd,e.idProduct,e.idBookSize)} className="btn btn-light"> <i className="fa fa-trash "></i></button>
                                  </td>
                                </tr>
                              </table>
                              
                            </td>
                          
                          </tr>
                        )
                      })}
                      
                    </tbody>
                  </table>
                </div>  
              
                <div className=" d-none d-sm-block">
                  <table className=" table table-borderless table-shopping-cart" >
                    <thead className="text-muted">
                      <tr className="small text-uppercase">
                        <th scope="col">Articles</th>
                        <th scope="col" width="120">Quantité</th>
                        <th scope="col" width="120">Prix</th>
                        <th scope="col" className="text-right" width="200"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.infosPanier.products.map((e,index)=>{
                      
                        return(
                          <tr key={index}>
                            <td>
                              <figure className="itemside"  >
                                <div className="aside"><img src={`${linkApiImages}${e.nameImage}`} className="img-sm"/></div>
                                <figcaption className="info">
                                  <Link to={`/articles/${e.idBookProd}`}  className="title text-dark">{e.nameProduct}</Link>
                                  {!findAvailable(e.availableBookProd,e.availableSize,e.stock)  &&
                                    <p className="text-danger small">Le produit est indisponible</p>
                                  }
                                  {findAvailable(e.availableBookProd,e.availableSize,e.stock) && quantityOverStock(e.stock,e.quantity)  &&
                                    <p className="text-danger small">Stock disponible {e.stock}</p>
                                  }
                                  {!e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.price)}€</p>}
                                  {e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.newPrice)}€ <span style={{textDecorationLine:"line-through"}}>{dotToComma(e.oldPrice)}€</span></p>}
                                  <p className="text-muted small">Taille: {e.nameSizing}, Couleur: {e.nameColor} </p>
                                  
                                </figcaption>
                              </figure>
                            </td>
                            <td> 
                            <div className="form-group col-md flex-grow-0">
                                <div className="input-group mb-3 input-spinner">
                                  
                                    <div className="input-group-append">
                                      <button className="btn " disabled={loadingBtn?true:false} onClick={()=>removeOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity,e.availableBookProd,e.availableSize)} type="button" id="button-minus"> &minus; </button>
                                    </div>
                                  
                                  <div style={{paddingTop:'9px'}}>{e.quantity}</div>
                                  
                                    <div className="input-group-prepend">
                                      <button className="btn " disabled={loadingBtn?true:false} onClick={()=>{
                                        
                                        addOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity,e.availableBookProd,e.availableSize)
                                        
                                      }} type="button" id="button-plus"> + </button>
                                    </div>
                                  
                                </div>
                              </div>
                              
                            </td>
                            <td> 
                              {e.idBookPromotionSize?
                                <div className="price-wrap"> 
                                  <var className="price">{getTotalItem(e.quantity,e.newPrice)}€</var> 
                                  
                                </div>
                                :
                                <div className="price-wrap"> 
                                  
                                  <var className="price">{getTotalItem(e.quantity,e.price)}€</var> 
                                  
                                </div>
                            
                            
                              }
                                
                            </td>
                            <td className="text-left"> 
                              <button type="button" disabled={loadingBtn?true:false} onClick={()=>deleteArticle(e.idBookProd,e.idProduct,e.idBookSize)} className="btn btn-light"> <i className="fa fa-trash "></i></button>
                            </td>
                          </tr>
                        )
                      })}
                      
                    </tbody>
                  </table>
                </div>

                <div  className="d-sm-none">
                  <div className=" card-body border-top">
                      <div className='text-center'>
                      <dl className="text-center">
                        <dt>Total Panier: {dotToComma(data?.infosPanier?.payInfos?.toPay)}€</dt>
                      </dl>
                      </div>
                      <div className='text-center'>
                        <hr/>
                      </div>
                      <div className='text-center'>
                        <button type="button" onClick={()=>checkValidNextStep()} className="btn btn-secondary "> Commander en mode invité <i className="fa fa-chevron-right"></i> </button>
                      </div>
                      <div className='text-center'>
                        <hr/>
                      </div>
                      <div className='text-center mb-4'>
                      <button onClick={()=>checkValidNextStepLink()} className="btn btn-info "> Se connecter <i className="fa fa-chevron-right"></i> </button>
                      </div>
                      <div className='text-center'>
                        <Link to="/" className="btn btn-light"> <i className="fa fa-chevron-left"></i> Continuer mes achats </Link>
                      </div>
                    
                  </div>	
                </div> 
                
                <div className=" d-none d-sm-block">
                  <div className="card-body border-top ">
                    <div className="float-md-right">
                      <div className='text-center'>
                        <button type="button" onClick={()=>checkValidNextStep()} className="btn btn-secondary "> Commander en mode invité <i className="fa fa-chevron-right"></i> </button>
                      </div>
                      <div className='text-center'>
                        OU
                      </div>
                      <div className='text-center'>
                      <button onClick={()=>checkValidNextStepLink()} className="btn btn-info "> Se connecter <i className="fa fa-chevron-right"></i> </button>
                      </div>
                    </div>
                    
                    <Link to="/" className="btn btn-light"> <i className="fa fa-chevron-left"></i> Continuer mes achats </Link>
                  </div>
                </div>
                
              </div> 
  
              <div className="alert alert-success mt-3">
                <p className="icontext"><i className="icon text-success fa fa-truck"></i> Livraison rapide</p>
              </div>
  
            </main> 
            <aside className="d-none d-sm-block col-md-3">
              
              <div className="card">
                <div className="card-body">
                    
                    <dl className="text-center">
                      <dt>Total Panier: {dotToComma(data?.infosPanier?.payInfos?.toPay)}€</dt>
                    </dl>
                    <hr/>
                    <dl className="text-center">
                    <button type="button" onClick={showDeliveryInfos} className="btn btn-secondary "> Commander en mode invité <i className="fa fa-chevron-right"></i> </button>
                    </dl>
                    <hr/>
                   
                    <dl className="text-center">
                      <Link to="/connexion" className="btn btn-info "> Se connecter <i className="fa fa-chevron-right"></i> </Link>
                    </dl>
                    
                </div> 
              </div>  
            </aside> 
          </div>
  
          </div> 
        </section>
  
        
        </Template>
    )
  }
  
 
}

export default DetailsBasket