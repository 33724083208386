import Template from "../Template"
import { Link } from 'react-router-dom'

function ErrorFiveHundred() {

  const styles = {
    main: {
      //width: "200px",
      height:"65vh",
      //border: "1px solid #2d2d2d",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    section: {
     
    },
   
  };
  return (
    <div>
      <Template>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          
          <div style={styles.main}>
            <div style={styles.section}>
                <p style={{display: "flex",justifyContent: "center",alignItems: "center"}} ><img style={{width:"120px"}} src="https://api.lnvx.fr/images/logo/logo.png" alt="Logo LNVX"/></p>
                <h5 >UNE ERREUR EST SURVENUE !</h5>
                <p style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                  <Link to={`/`} >
                      <button type="button" className="btn btn-light"> Continuer mes achats </button>
                  </Link>
                </p>
            </div>
            
          </div>

      
          </div>
        </div>
      </div> 
      </Template>
    </div>
  );
}

export default ErrorFiveHundred;