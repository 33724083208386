import React,{ useEffect,useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred'
import Loading from './components/loading/Loading'
import Template from './components/Template';
import {urlData,urlDataImage} from './services/url';
import { dotToComma } from "./services/helpers"


function Orders() {

  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [orders, setOrders] = useState([])
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const navigate= useNavigate()


  useEffect(() => {
    if(!connected){
      navigate('/connexion')
    }else{
    
      const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${urlData()}/orders/${idUser}`,{headers:{'Content-Type': 'application/json','Authorization': `Bearer ${token}`}});
          
            if(response.data.success) {
              setFetchError(null);
              setOrders(response.data.orders);
              
            }else{
            
              setFetchError(response.data.message);
            }
        } catch (err) {
        
            console.log(err)
            setFetchError(err.message);
        } finally {
        
            setIsLoading(false);

        }
      }
      fetchData()
  }
   
  
},[connected])

  const priceTotal=(totalBasket,deliveyPrice,promoPrice)=>{
    let total = totalBasket+deliveyPrice-promoPrice
    total=dotToComma(total)
    return total
  }
  const returnOrdersStatusCount=(orders,status)=>{
    try {
      if(!Array.isArray(orders)){
        console.log(`not array for ${status}`)
        return 0;
      }
      if(orders.length===0){
        console.log(`length for ${status}`)
        return 0;
      }
  
      let count= orders.reduce((accumulator, currentValue)=>{
        
          let find= status.includes(currentValue.idTypestatusorder) && currentValue.valid_order===1 && currentValue.cancel_order!==1?1:0;
          console.log(`find   ${find} for include=${status.includes(currentValue.idTypestatusorder)} valid_order=${currentValue.valid_order===1} cancel_order=${currentValue.cancel_order!==0} `)
          return accumulator +find
      },0)
  
      return count
    } catch (error) {
      console.log(error)
      return 'Err'
    }
    
  }

  return (
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError &&
        <Template>
        <section className="section-content padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-md-3">
                <nav className="list-group">
                  <Link className="list-group-item " to="/compte"> Mon compte  </Link>
                  <Link className="list-group-item active" to="/commandes"> Mes commandes </Link>
                </nav>
              </aside>
              <main className="col-md-9"  style={{height:"75vh",overflow: "hidden",overflowY: "scroll"}}>

                <article className="card mb-3">
                  <div className="card-body">
                    <article className="card-group card-stat">
                      <figure className="card bg">
                        <div className="p-3">
                          <h4 className="title">{`${orders.length}`}</h4>
                          <span>Commandes</span>
                        </div>
                      </figure>
                     
                      <figure className="card bg">
                        <div className="p-3">
                          <h4 className="title">{returnOrdersStatusCount(orders,[1,2,3,4,11])}</h4>
                          <span>En Attente</span>
                        </div>
                      </figure>
                      <figure className="card bg">
                        <div className="p-3">
                          <h4 className="title">{returnOrdersStatusCount(orders,[6])}</h4>
                          <span>Livrés</span>
                        </div>
                      </figure>
                      <figure className="card bg">
                        <div className="p-3">
                          <h4 className="title">{returnOrdersStatusCount(orders,[5,9,10])}</h4>
                          <span>Annulés</span>
                        </div>
                      </figure>
                    </article>
                    

                  </div>
                </article> 

                <article className="card  mb-3" style={{height:"75vh",overflow: "hidden",overflowY: "scroll"}}>
                  <div className="card-body">
                    <h5 className="card-title mb-4">Mes commandes </h5>	
                    <div className="row">
                        {orders?.map((e,index)=>{
                          return(
                          
                            <div key={index} className="col-md-12">
                              <article key={index} className="card mb-4">
                                  <header className="card-header">
                                    <Link to={`/commandes/${e.number_order}`} className="float-right"> <i className="fa fa-eye"></i> Voir</Link>
                                    <strong className="d-inline-block mr-3">Numéro: {e.number_order}</strong>
                                    <span>Date: {e.dateOrder}</span>
                                  </header>
                                  <div className="card-body">
                                    <div className="row" > 
                                      <div className="col-md-8">
                                        <h6 className="text-muted">Livraison</h6>
                                        <p><time className="text-muted"><i className="fa fa-truck"></i></time>:&nbsp;{e.dateDeliveryFrench}<br/>
                                        <time className="text-muted"><i className="fa fa-address-book"></i></time>:&nbsp;{e.nameDeliveryAddress} <br/>  
                                            {e.roadDeliveryAddress} {e.infosroadDeliveryAddress && `,${e.infosroadDeliveryAddress}`} <br/> 
                                          {e.postcodeDeliveryAddress} {e.townDeliveryAddress}<br/>
                                          <time className="text-muted"><i className="fa fa-phone-volume"></i></time>:&nbsp; {e.contactAdresse} <br/>
                                          
                                          Statut:&nbsp;{e.cancel_order===1 &&
                                                  <span className="text-danger">COMMANDE ANNULEE </span>
                                                }
                                                {e.cancel_order!==1 &&
                                                  <span className={e.typestatusorder==='COMMANDE VALIDEE' || e.typestatusorder==='LIVRAISON EFFECTUEE'?"text-success":"text-warning"}>{e.typestatusorder} </span>
                                                }
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h6 className="text-muted">Paiement</h6>
                                        {/*<span className="text-success">
                                          <i className="fab fa-lg fa-cc-visa"></i>
                                            Visa  **** 4216  
                                        </span>*/}
                                        <p>CB<br/>Sous total:  {dotToComma(e.total_order)}€ <br/>
                                        Livraison:  {dotToComma(e.pricedelivery_order)}€ <br/> 
                                        {e.amountpromo_order>0 && <span>Promo:  -{dotToComma(e.amountpromo_order)}€ <br/></span> }
                                        <span className="b">Total:  {priceTotal(e.total_order,e.pricedelivery_order,e.amountpromo_order)}€ </span>
                                        </p>
                                      </div>
                                    </div> 
                                  </div> 
                                
                                </article> 
                            </div>
                          )
                        })}
                    </div> 
                  </div>
                </article> 
              </main> 
            </div>
          </div> 
        </section>
      </Template>
      }
    </div>
  )
}

export default Orders