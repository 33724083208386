import React,{ useState, useRef } from 'react'
import {useStoreState,useStoreActions } from 'easy-peasy';
import axios from 'axios';
import {urlData} from '../../services/url';
import { useNavigate } from 'react-router-dom';

function UserDeleteAccount() {


  const idUser= useStoreState((state)=>state.idUser)
  const setFullDisconnect= useStoreActions((state)=>state.setFullDisconnect)
  const token= useStoreState((state)=>state.token)
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [errorFind, setFindError] = useState(false)
  const [errorData, setError] = useState("")
  const [successChangeMessage, setSuccessMessage] = useState('')
  const [successChange, setSuccessChange] = useState(false)
  const [hideBtnValid, setHideBtnValid] = useState(false)

  const navigate=useNavigate()

  const deleteAccountUser= async ()=>{
   setIsLoadingData(true)
   

    let body ={
      idUser
    }
    const responseDeleteAccount = await axios.put(`${urlData()}/user-account/delete-account`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
 
    if(!responseDeleteAccount.data.success){
      
      setFindError(true);
      setError(responseDeleteAccount.data.message)
    
      return
    }
    setFullDisconnect()
    setHideBtnValid(true)
    setSuccessMessage(responseDeleteAccount.data.message)
    setSuccessChange(true)
    
    setTimeout(()=>{
      handleCloseForm()
      navigate('/')
    },3000)

    return 
  }

  const handleCloseForm=()=>{
   
    window.$("#deleteAcountModal").modal("hide")
   
    setFindError(true);
    setHideBtnValid(false)
    setSuccessMessage("")
    setSuccessChange(false)
    setFindError(false);
    setError("")
  }




  return (
    <article className="card mb-4">
        <header className="card-header">
          <strong className="d-inline-block mr-3">SUPPRESSION COMPTE</strong>
        </header>
        <div className="card-body">
          <div className="row" > 
            <div className="col-md-8">
              <h6 className="text-muted">Supprimer votre compte</h6>
              <button type='button' onClick={()=>window.$("#deleteAcountModal").modal("show")} className='btn btn-danger'>Supprimer</button>
            </div>
          </div> 
        </div> 
        <div className="modal" id="deleteAcountModal"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">SUPPRESSION COMPTE</h5>
          
        </div>
        <div className="modal-body">
            <div className="alert alert-danger mt-3 text-center">
                <p className="icontext" style={{fontWeight:"bold"}}>
                  <i className="icon text-danger fa fa-exclamation-triangle"></i>
                ATTENTION  &nbsp;<i className="icon text-danger fa fa-exclamation-triangle"></i>
                </p>
              
              <p className="icontext" style={{fontWeight:"bold"}}>
               En cliquant sur valider, la suppression de votre compte sera irréversible.
                </p>
              </div>
           
            {errorFind && 
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-success fa fa-triangle-exclamation"></i> {errorData}</p>
              </div>
            }
            {successChange && !errorFind &&
              <div className="alert alert-success mt-3">
                <p className="icontext"><i className="icon text-success fa fa-check"></i>{successChangeMessage}</p>
              </div>
            }
            
        </div>
        <div className="modal-footer">
          
          

          {  !hideBtnValid && 
            <button type="button" disabled={isLoadingData?true:false} className="btn btn-success" onClick={deleteAccountUser}>Valider</button>
          }
          { !hideBtnValid && 
             <button type="button" disabled={isLoadingData?true:false} onClick={handleCloseForm} className="btn btn-danger " >
              Annuler
          </button>
          }
         
        </div>
      </div>
    </div>
  </div>
      </article> 
    
  )
}

export default UserDeleteAccount