import { Link } from 'react-router-dom'


export default  function CarrouselPart({categories,sliders,linkApiImages,popCat}) {

  let categoriesSlice= Array.isArray(categories) && categories.length>0?categories.slice(0,8):[]
  let popCategories=Array.isArray(popCat) && popCat.length>0?popCat.slice(0,3):[] 
  function randomSort(values){
    if(Array.isArray(values)){
        if(values.length>0){
            values=values
            .map((a) => ({sort: Math.random(), value: a}))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
        }
        values= values.slice(0,3)
        return values
    }else{
      return []
    }
  }

  //let categoriesPop= randomSort(categories)

  return (
    <div>
      <section className="section-main padding-y">
            <main className="card">
              <div className="card-body">

                <div className="row">
                  <aside className="d-none d-sm-block col-lg col-md-3  flex-lg-grow-0">
                    <h6>CATEGORIES</h6>
                    <nav className="nav-home-aside">
                      <ul className="menu-category">
                        {categoriesSlice?.map((i,index) => {
                            return(
                              
                              <Link key={index} to={`/categories/${i.idCatProd}`}>
                                <li >{i.nameCatProd}</li>
                              </Link>
                                
                                
                            )
                            
                        })}
                        <li><Link to="/categories">Toutes nos catégories</Link></li>
                        
                      </ul>
                    </nav>
                  </aside>
                  <div className="col-md-9 col-xl-7 col-lg-7">

              
                <div id="carousel1_indicator" className="slider-home-banner carousel slide" data-ride="carousel">
                  <ol className="carousel-indicators">
                    <li data-target="#carousel1_indicator" data-slide-to="0" className="active"></li>
                    <li data-target="#carousel1_indicator" data-slide-to="1"></li>
                    <li data-target="#carousel1_indicator" data-slide-to="2"></li>
                  </ol>
                  <div className="carousel-inner">
                    {sliders?.map((data,index) => {
                      return(
                        <div key={index} className={index===0?"carousel-item active":"carousel-item "}>
                          <img src={`${linkApiImages}${data.mainPicture}`} alt={data.nameProduct} style={{objectFit:"contain"}}/> 
                        </div>
                      )
                     
                    })}
                    </div>
          
                  <a className="carousel-control-prev" href="#carousel1_indicator" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carousel1_indicator" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div> 
              

                  </div> 
                  <div className="col-md d-none d-lg-block flex-grow-1">
                    <aside className="special-home-right">
                      <h6 className="bg-blue text-center text-white mb-0 p-2">Categorie populaire</h6>
                      {popCategories && popCategories?.map((i,index) => {
                            return(
                              
                              <div key={index} className="card-banner border-bottom">
                                <div className="py-3" style={{width:"80%"}}>
                                  <h6 className="card-title" style={{fontSize:"14px"}}>{i.categoryProduct}</h6>
                                  <Link to={`/categories/${i.idCategoryProduct}`} className="btn btn-secondary btn-sm">
                                    Voir 
                                  </Link>
                                  
                                </div> 
                                <img src={`${linkApiImages}${i.imageCategoryProduct}`} height="80" alt="Catégorie populaire" className="img-bg"/>
                              </div>
                                
                                
                            )
                            
                      })}
                      
                    </aside>
                  </div> 
                </div> 

              </div> 
            </main> 
          </section>
    </div>
  );
}

