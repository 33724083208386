import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import { Link, useNavigate   } from 'react-router-dom'
import { useStoreState } from 'easy-peasy';
import Template from './components/Template'
import FindPostCode from './components/postCode/FindPostCode';
import {urlData} from './services/url';
import ModalRegisterSuccess from './components/modal/ModalSuccessRegistration';
function Register() {

  const postCode= useStoreState((state)=>state.postCode)
  const connected= useStoreState((state)=>state.connected)
  const [ username,setUsername] = useState()
  const [ password,setPassword] = useState()
  const [ checkPass,setCheckPass] = useState()
  const [ surname,setSurname] = useState()
  const [ firstname,setFirstname] = useState()
  const [ token,setToken] = useState()
  const [ code,setCode] = useState()
  const [ showInfosForm,setShowInfosForm] = useState()
  const [loadingReceiveCode, setLoadingReceiveCode] = useState(false)
  const [loadingRegistration, setLoadingRegistration] = useState(false)
  const [successFindRegister, setSuccessFindRegister] = useState(false)
  const [errorFindRegister, setErrorFindRegister] = useState(false)
  const [errorRegister, setErrorRegister] = useState("")
  const [errorFindRegistration, setErrorFindRegistration] = useState(false)
  const [errorRegistration, setErrorRegistration] = useState("")
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  //register
  //register-validation

  useEffect(() => {
    
      const fetchData = async () => {
        if(connected){
          navigate('/')
          return
        }
      }
      fetchData()
  })

  const handleSendCode=async ()=>{
    try {
      setLoadingReceiveCode(true)
      let payload={username}
      if(!username){
        setErrorFindRegister(true)
        setErrorRegister("L'adresse email est manquante.")
        setLoadingReceiveCode(false)
        return
      }
      const response = await axios.post(`${urlData()}/register`,payload,{header:{"Content-Type":"application/json"}});
      
      if(!response.data.success) {
        setErrorFindRegister(true)
        setErrorRegister(response.data.message)
        setLoadingReceiveCode(false)
        return
      }
      setErrorFindRegister(false)
      setErrorRegister("")
      setSuccessFindRegister(true)
      setToken(response.data.token)
      setShowInfosForm(true)
        
      
    } catch (error) {
      setErrorFindRegister(true);
     
      setErrorRegister("Une erreur interne est survenue (Envoi code)")
      setLoadingReceiveCode(false)
    }
  } 

  const setErreurRegistration=(sentence,findError=true)=>{
    setErrorFindRegistration(findError)
    setErrorRegistration(sentence)
  }

  const checkFormValid=()=>{
    let payload={username}
      if(!code){
        setErreurRegistration("Le code est invalide.")
        return false;
      }
      if(!username){
        setErreurRegistration("L'adresse email est invalide.")
        return false;
      }

      if(!password){
        setErreurRegistration("Le mot de passe est invalide.")
        return false;
      }

      if(!checkPass){
        setErreurRegistration("Le mot de passe de vérification est invalide.")
        return false;
      }

      if(checkPass!==password){
        setErreurRegistration("Les mots de passe doivent être identiques.")
        return false;
      }
      setErreurRegistration("",false)
      return true
  }

  
  const handleSendRegistration=async ()=>{
    try {
      setLoadingRegistration(true)
      setErreurRegistration("",false)
      if(checkFormValid()){
       
        let payload={username,password,checkPass,surname,firstname,code,token,postCode}
        const response = await axios.post(`${urlData()}/register-validation`,payload,{header:{"Content-Type":"application/json"}});
       
        
        if(!response.data.success) {
          setLoadingRegistration(false)
          setErreurRegistration(response?.data?.message)
          return
        }
        setLoadingRegistration(false)
        setShowModal(true)
        setTimeout(()=>{
          setShowModal(false)
          navigate('/connexion')
        },3000)
       
        
      }
      
        
      
    } catch (error) {
      setErrorFindRegister(true);
      setErrorRegister("Une erreur interne est survenue (Envoi code)")
      setLoadingReceiveCode(false)
    }
  } 

  return (
    <div>
      <Template>
      <section className="section-content padding-y">
        <div className="card mx-auto" style={{maxWidth:"520px",marginTop:"40px"}}>
            <article className="card-body">
          <header className="mb-4"><h4 className="card-title text-center">Inscription</h4></header>
            {errorFindRegister  && !successFindRegister &&
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorRegister}</p>
              </div>
            }

            {errorFindRegistration  &&
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorRegistration}</p>
              </div>
            }
            {successFindRegister && !errorFindRegister &&
              <div className="alert alert-success mt-3">
                <p className="icontext"><i className="icon text-success fa fa-check"></i>Un code à été envoyé dans votre boîte email.</p>
              </div>
            }
           
          <form>
              <div className="form-group">
                <label>Email</label>
                <input type="email" onChange={(e)=>setUsername(e.target.value)} className="form-control" placeholder=""/>
              </div> 
              {!successFindRegister &&
                <div className="form-group">
                  <button type="button" onClick={handleSendCode} disabled={loadingReceiveCode?true:false} className="btn btn-secondary btn-block">{loadingReceiveCode?"Envoi.....":"Recevoir le code"}   </button>
                </div>
              }
             
              {showInfosForm &&
                <>
                  <div className="form-group">
                    <label>Code</label>
                    <input type="text" onChange={(e)=>setCode(e.target.value)} className="form-control" placeholder=""/>
                  </div> 
                  <div className="form-row">
                    <div className="col form-group">
                      <label>Prénom</label>
                        <input type="text" onChange={(e)=>setFirstname(e.target.value)} className="form-control" placeholder=""/>
                    </div> 
                    <div className="col form-group">
                      <label>Nom</label>
                        <input type="text" onChange={(e)=>setSurname(e.target.value)} className="form-control" placeholder=""/>
                    </div> 
                  </div> 
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Mot de passe</label>
                        <input className="form-control" onChange={(e)=>setPassword(e.target.value)} type="password"/>
                    </div> 
                    <div className="form-group col-md-6">
                      <label>Re-taper le mot de passe</label>
                        <input className="form-control" onChange={(e)=>setCheckPass(e.target.value)} type="password"/>
                    </div> 
                  </div>
                  <div className="form-group">
                      <button type="button" onClick={handleSendRegistration} disabled={loadingRegistration?true:false} className="btn btn-secondary btn-block">{loadingRegistration?"Envoi....":"S'inscrire"}   </button>
                  </div>
                </>
              }
              
              
            </form>
          </article>
          </div>
          <p className="text-center mt-4">Déja un compte? <Link to="/connexion">Se connecter</Link></p>
          <p className="text-center mt-4"> <Link to="/">Continuer sans compte</Link></p>
          <br/><br/>
      </section>
      <FindPostCode/>
      
      <ModalRegisterSuccess showModal={showModal} firstname={firstname} surname={surname}/>
      </Template>
    </div>
  )
}

export default Register