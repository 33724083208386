import { Link } from 'react-router-dom'
import { dotToComma, random_sort } from "../../services/helpers"
import AddToBasket from '../addToBasket/AddToBasket';
import { useStoreActions,useStoreState } from 'easy-peasy';

export default function SectionFive({nameSection,sectionData,linkApiImages,colorShopBtn,handleAddBasket,showPrices}) {
  
  
  sectionData=sectionData.length>0?sectionData.sort(random_sort):[]

  let sectionDataSlice= Array.isArray(sectionData) && sectionData.length>0? sectionData.slice(0,12):[]

  const addBasket= useStoreActions((state)=>state.addBasket)
  const connected= useStoreState((state)=>state.connected)
  const postCode= useStoreState((state)=>state.postCode)
  const token= useStoreState((state)=>state.token)
  const idUser= useStoreState((state)=>state.idUser)
  const addTotalBasket= useStoreActions((state)=>state.addTotalBasket)
  const setArticleSizes= useStoreActions((state)=>state.setArticleSizes)
  const setIsLoadingProduct= useStoreActions((state)=>state.setIsLoadingProduct)
  const setMessageError= useStoreActions((state)=>state.setMessageError)
  const setMoveToBasket= useStoreActions((state)=>state.setMoveToBasket)
  const setShowPopupError= useStoreActions((state)=>state.setShowPopupError)
  const setShowPopupSuccess= useStoreActions((state)=>state.setShowPopupSuccess)
  const setDeleteFromWishlist= useStoreActions((state)=>state.setDeleteFromWishlist)

  

  return (
    <div>
      <section  className="padding-bottom-sm">
        <header className="section-heading ">
          <h4 className="title-section text-uppercase">{nameSection}</h4>
        </header>
        <div className="row row-sm">
            {sectionDataSlice?.map((i,index) => {
              
                return(
                  <div  key={index} className="col-xl-2 col-lg-3 col-md-4 col-6">
                    <div  className="card card-sm card-product-grid">
                      <Link to={`/articles/${i.idBookProd}`} className="img-wrap">
                         {i.articlePromo>0 &&
                          <b className="badge badge-danger mr-1">{i.isFlash?'FLASH':'PROMOTION'}</b>
                         }
                        <img  className="mt-1 ml-1 mr-1 rounded img-fluid " src={`${linkApiImages}${i.uniqIdDoc}`} alt="produit"/>
                      </Link>
                      
                      <figcaption className="info-wrap">
                        <Link to={`/articles/${i.idBookProd}`} className="title">
                          {i.nameProduct}
                        </Link>
                        
                        {i.stock>0 &&
                          <div style={{display: "flex",justifyContent: "space-around"}}>
                            {showPrices && i.articlePromo>0 &&
                            <div className="price-wrap">
                              <span className="price">{dotToComma(i.newPricePromo)}€</span>
                              <del className="price-old">{dotToComma(i.oldPricePromo)}€</del>
                            </div> 
                          }
                            {showPrices && !i.articlePromo>0 &&
                            <div className="price mt-1">{dotToComma(i.price)}€</div>
                            }
                            <AddToBasket 
                              idBookProd={i.idBookProd}
                              idProduct={i.idProduct}
                              idBookSize={i.idBookSize}
                              quantity={1}
                              showPopupSuccess={1}
                              showPopupError={1}
                              multipleArticle={i.multipleArticle>1?true:false}
                              addBasket={addBasket}
                              connected={connected}
                              postCode={postCode}
                              token={token}
                              idUser={idUser}
                              addTotalBasket={addTotalBasket}
                              setArticleSizes={setArticleSizes}
                              setIsLoadingProduct={setIsLoadingProduct}
                              setMessageError={setMessageError}
                              setMoveToBasket={setMoveToBasket}
                              setShowPopupError={setShowPopupError}
                              setShowPopupSuccess={setShowPopupSuccess}
                              setDeleteFromWishlist={setDeleteFromWishlist}
                            >
                              <button className='btn btnLight' type='button'>
                                <span className="icon icon-xs rounded-circle " style={{backgroundColor:`#${colorShopBtn}`}}>
                                    <i className={colorShopBtn?`fa fa-cart-plus grey`:"fa fa-cart-plus red"}></i>
                                  </span>
                              </button>
                            </AddToBasket>
                          </div> }
                        {i.stock===0 &&<div className="price mt-1">Indisponible</div> }
                      </figcaption>
                    </div>
                  </div> 
                )
              
              
            })}
          
        </div> 
      </section>
      <style jsx="true">{`
        .title {
          height: 65px;
        }
        
        
      `}</style>
      
    </div>
  );
}

/*

<button className='btn btnLight' type='button' onClick={()=>handleAddBasket(i.idBookProd,i.idProduct,i.idBookSize)}>
                                <span className="icon icon-xs rounded-circle " style={{backgroundColor:`#${colorShopBtn}`}}>
                                    <i className={colorShopBtn?`fa fa-cart-plus grey`:"fa fa-cart-plus red"}></i>
                                  </span>
                              </button>


{posts?.map((i) => {
        <h1>{i.title}</h1>
      })}

*/