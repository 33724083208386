import React from 'react'
import { Link } from 'react-router-dom'
import Template from './components/Template'
import Policy from './components/cgv/policy'
import ArticleOne from './components/cgv/ArticleOne'
import ArticleTwo from './components/cgv/ArticleTwo'
import ArticleThree from './components/cgv/ArticleThree'
import ArticleFour from './components/cgv/ArticleFour'
import ArticleFive from './components/cgv/ArticleFive'
import ArticleSix from './components/cgv/ArticleSix'
import ArticleSeven from './components/cgv/ArticleSeven'
import ArticleEight from './components/cgv/ArticleEight'
import ArticleNine from './components/cgv/ArticleNine'
import Mentions from './components/cgv/mentions'

function InfosCustomer() {
  return (
    <Template>
        <section className="section-pagetop bg-secondary">
          <div className="container">
            <h2 className="title-page text-white">POLITIQUES DE CONFIDENTIALITE & CGV & MENTIONS LEGALES</h2>
          </div>
        </section>
        <section className="section-content bg-white padding-y">
          <div className="container">
            <Policy/>
            <h5 style={{textAlign:"center"}}>CGV</h5>
            <ArticleOne/>
            <ArticleTwo/>
            <ArticleThree/>
            <ArticleFour/>
            <ArticleFive/>
            <ArticleSix/>
            <ArticleSeven/>
            <ArticleEight/>
            <ArticleNine/>
            <Mentions/>

            <br/><br/>

            <Link to="/" className="btn btn-light">Retourner à la page d'accueil</Link>
          </div> 
        </section>
    </Template>
  )
}

export default InfosCustomer