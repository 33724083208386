import React from 'react'

function ArticleOne() {
  return (
    <>
        <h5 class="card-title">Article 1</h5>
        <p>
          Champ d’application Conformément à l’article article L. 441-6 du code de commerce, les conditions générales de vente présentée ci-dessous sont applicables à toutes les ventes conclues via le site marchand de la société Les nouveaux . Le consommateur devra prendre connaissance et accepté les conditions générales de vente émise par l’entreprise afin de passer commande.
        </p>
    </>
  )
}

export default ArticleOne