import React from 'react'
import { Link } from 'react-router-dom'
import { dotToComma } from "../../services/helpers"

function OrdersProfile({profil}) {



  const priceTotal=(totalBasket,deliveyPrice,promoPrice)=>{
      let total = totalBasket+deliveyPrice-promoPrice
      total=dotToComma(total)
      return total
  }

  if( profil.orders.length===0){

  }
  

  return (

   
    
    <main className="col-md-9" style={{height:"75vh",overflow: "hidden",overflowY: "scroll"}}>
      {
        profil.orders.length===0 &&
        <article  className="card mb-4">
              
              <div className="card-body">
                <div className="row" > 
                  <div className="col-md-12">
                    <h6 className="text-muted">Commandes</h6>
                    <p> 0 commande
                    </p>
                  </div>
                  
                </div> 
              </div> 
            
            </article>
      }

      {
        profil.orders.length>0 &&
        profil.orders.map((data,index)=>{
          return(
            <article key={index} className="card mb-4">
              <header className="card-header">
                <Link to={`/commandes/${data.number_order}`} className="float-right"> <i className="fa fa-eye"></i> Voir</Link>
                <strong className="d-inline-block mr-3">Numéro: {data.number_order}</strong>
                <span>Date: {data.dateOrder}</span>
              </header>
              <div className="card-body">
                <div className="row" > 
                  <div className="col-md-8">
                    <h6 className="text-muted">Livraison</h6>
                    <p><time className="text-muted"><i className="fa fa-truck"></i></time>:&nbsp;{data.dateDeliveryFrench}<br/>
                    <time className="text-muted"><i className="fa fa-address-book"></i></time>:&nbsp;{data.nameDeliveryAddress} <br/>  
                        {data.roadDeliveryAddress} {data.infosroadDeliveryAddress && `,${data.infosroadDeliveryAddress}`} <br/> 
                      {data.postcodeDeliveryAddress} {data.townDeliveryAddress}<br/>
                      <time className="text-muted"><i className="fa fa-phone-volume"></i></time>:&nbsp; {data.contactAdresse} <br/>
                      
                      Statut:&nbsp;{data.cancel_order===1 &&
                              <span className="text-danger">COMMANDE ANNULEE </span>
                            }
                            {data.cancel_order!==1 &&
                              <span className={data.typestatusorder==='COMMANDE VALIDEE' || data.typestatusorder==='LIVRAISON EFFECTUEE'?"text-success":"text-warning"}>{data.typestatusorder} </span>
                            }
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h6 className="text-muted">Paiement</h6>
                    {/*<span className="text-success">
                      <i className="fab fa-lg fa-cc-visa"></i>
                        Visa  **** 4216  
                    </span>*/}
                    <p>CB<br/>Sous total:  {dotToComma(data.total_order)}€ <br/>
                    Livraison:  {dotToComma(data.pricedelivery_order)}€ <br/> 
                    {data.amountpromo_order>0 && <span>Promo:  -{dotToComma(data.amountpromo_order)}€ <br/></span> }
                    <span className="b">Total:  {priceTotal(data.total_order,data.pricedelivery_order,data.amountpromo_order)}€ </span>
                    </p>
                  </div>
                </div> 
              </div> 
            
            </article> 
          )
        })
      }
   
	  </main>
  )
}

export default OrdersProfile