import React,{ useEffect,useState,useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred'
import Loading from './components/loading/Loading'
import Template from './components/Template';
import {urlData} from './services/url';
import { dotToComma } from './services/helpers';
import ReactToPrint from 'react-to-print'
//import { useReactToPrint  } from 'react-to-print';
import OrderInfo from './components/orderPage';


function OrderPage() {


  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [order, setOrder] = useState({})
  const [ordersItem, setOrdersItem] = useState([])
  const [findOrder, setFindOrder] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const params = useParams();
  const componentRef = useRef();


  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/order/${params.idOrder}/${idUser}`,{headers:{'Content-Type': 'application/json','Authorization': `Bearer ${token}`}});
         
          if(response.data.success) {
            if(response.data.find){
             
              setOrder(response.data.order);
              setOrdersItem(response.data.orderItems)
              setFindOrder(true)
            }
            setFetchError(null);
          }else{
          
            setFetchError(response.data.message);
          }
      } catch (err) {
       
          console.log(err)
          setFetchError(err.message);
      } finally {
      
          setIsLoading(false);

      }
  }
   
  fetchData()
},[connected,params,idUser])

const priceTotal=(totalBasket,deliveyPrice,promoPrice)=>{
  let total = totalBasket+deliveyPrice-promoPrice
  total=dotToComma(total)
  return total
}

  /*const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
 */

  return (
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError && findOrder &&
        <Template>
        <section className="section-content padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-md-3">
                <nav className="list-group">
                  <Link className="list-group-item " to="/compte"> Mon compte  </Link>
                  <Link className="list-group-item " to="/commandes"> Mes commandes </Link>
                  <span className="list-group-item active" > Commande: {order?.number_order} </span>
                </nav>
              </aside>
              <main className="col-md-9"  >
              <article className="card  mb-3" >
                  <div className="card-body">
                  <h5 className="card-title mb-4">Ma commande </h5>	
                  <div className="row">
                      <div  className="col-md-12">
                      <article className="card mb-4">
                          <header className="card-header">
                          <ReactToPrint
                            trigger={() => <button className="btn btn-light float-right" ><i className="fa fa-print"></i> Imprimer</button>}
                            content={() => componentRef.current}
                          />
                          
                          
                          <strong className="d-inline-block mr-3">Numéro: {order.number_order}</strong>
                          <span>Date: {order.dateOrder}</span>
                          </header>
                      </article> 
                      </div>
                  </div> 
                  </div>
              </article>

              <article  className="card  mb-3" >
                  <div className="card-body">
                    <div className="row">
                        
                        <div  className="col-md-12">
                            <article className="card mb-4">
                                
                                <div className="card-body">
                                <div className="row" > 
                                    <div className="col-md-8">
                                    <h6 className="text-muted">Livraison</h6>
                                    <p><time className="text-muted"><i className="fa fa-truck"></i></time>:&nbsp;{order.dateDeliveryFrench}<br/>
                                    <time className="text-muted"><i className="fa fa-address-book"></i></time>:&nbsp;{order.nameDeliveryAddress} <br/>  
                                        {order.roadDeliveryAddress} {order.infosroadDeliveryAddress && `,${order.infosroadDeliveryAddress}`} <br/> 
                                        {order.postcodeDeliveryAddress} {order.townDeliveryAddress}<br/>
                                        <time className="text-muted"><i className="fa fa-phone-volume"></i></time>:&nbsp; {order.contactAdresse} <br/>
                                        
                                        Statut:&nbsp;{order.cancel_order===1 &&
                                                <span className="text-danger">COMMANDE ANNULEE </span>
                                            }
                                            {order.cancel_order!==1 &&
                                                <span className={order.typestatusorder==='COMMANDE VALIDEE' || order.typestatusorder==='LIVRAISON EFFECTUEE'?"text-success":"text-warning"}>{order.typestatusorder} </span>
                                            }
                                    </p>
                                    </div>
                                    <div className="col-md-4">
                                    <h6 className="text-muted">Paiement</h6>
                                    {/*<span className="text-success">
                                        <i className="fab fa-lg fa-cc-visa"></i>
                                        Visa  **** 4216  
                                    </span>*/}
                                    <p>CB<br/>Sous total:  {dotToComma(order.total_order)}€ <br/>
                                    Livraison:  {dotToComma(order.pricedelivery_order)}€ <br/> 
                                    {order.amountpromo_order>0 && <span>Promo:  -{dotToComma(order.amountpromo_order)}€ <br/></span> }
                                    <span className="b">Total:  {priceTotal(order.total_order,order.pricedelivery_order,order.amountpromo_order)}€ </span>
                                    </p>
                                    </div>
                                </div> 
                                </div> 
                            
                            </article> 
                        </div>
                        <div  className="col-md-12">
                        <article className="card mb-4">
                            
                            <div className="card-body">
                            <table className='table'>
                                <thead>
                                    <tr>
                                    <th>Désignation</th>
                                    <th className='text-center'>Quantité</th>
                                    <th className='text-center'>Prix</th>
                                    <th className='text-center'>Total</th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    ordersItem?.map((e,index)=>{
                                        return(
                                        <tr key={index}>
                                            <td>{e.nameProduct}</td>
                                            <td className='text-center'>{e.quantity_orderitem}</td>
                                            <td className='text-center'>{dotToComma(e.price_orderitem)}€</td>
                                            <td className='text-center'>{dotToComma(e.total_orderitem)}€</td>
                                            
                                        </tr>
                                        )
                                    })

                                    }
                                </tbody>
                            </table>
                            </div> 
                        
                        </article> 
                        </div>
                    </div> 
                  </div>
              </article> 
              <div
                
                style={{ visibility: "hidden" }}
              >
               <OrderInfo ref={componentRef} order={order} ordersItem={ordersItem} />
              </div>
             
              
                
              </main> 
            </div>
          </div> 
        </section>
      </Template>
      }
      {
        !isLoading && !fetchError && !findOrder &&
        <Template>
        <section className="section-content padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-md-3">
                <nav className="list-group">
                  <Link className="list-group-item " to="/compte"> Mon compte  </Link>
                  <Link className="list-group-item " to="/commandes"> Mes commandes </Link>
                </nav>
              </aside>
              <main className="col-md-9" style={{height:"75vh",overflow: "hidden"}} >

                

                <article className="card  mb-3" >
                  <div className="card-body">
                    <h5 className="card-title mb-4">Ma commande </h5>	
                    <div className="row">
                      <div  className="col-md-12">
                        <article className="card mb-4">
                          <div className="card-body">
                            <div className="row" > 
                              <div className="col-md-12 text-center">
                                <h3>LA COMMANDE EST INCONNU</h3>
                                <Link to="/commandes"><button className='btn btn-secondary'>Voir toutes mes commandes </button></Link>
                              </div>
                              
                            </div> 
                          </div> 
                        
                        </article> 
                      </div>
                      
                    </div> 
                  </div>
                </article> 
              </main> 
            </div>
          </div> 
        </section>
      </Template>
      }
    </div>
  )
}

export default OrderPage