import React from 'react'

function ArticleThree() {
  return (
    <>
        <h5 class="card-title">Article 3</h5>
        <p>
        Commandes Toutes les commandes s’effectuent sur le site : www.lnvx.fr . Pour passer commande le client doit être majeur et fournir un certain nombre d’informations. Il se devra donc de remplir, au minimum, toutes les cases indiquées par un astérisque. Si une erreur est réalisée, dans les informations, lors du passage de la commande l’entreprise ne pourra en être tenue pour responsable. Une fois la commande passer le consommateur recevra dans les heures qui suivent un mail de confirmation. Une fois la commande expédiée il recevra un mail de validation.
        </p>
    </>
  )
}

export default ArticleThree