import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import { Link, useNavigate   } from 'react-router-dom'
import { useStoreActions,useStoreState } from 'easy-peasy';
import {urlData} from './services/url';
import Template from './components/Template';
import '../src/components/modal/Modal.css'

function Login() {
  const [ email, setEmail]= useState("")
  const [ password, setPassword]= useState("")
  const [ errorFindLogin, setFindErrorLogin] = useState(false)
  const [ errorLogin, setErrorLogin] = useState("")
  const [ showModal, setShowModal]= useState(false)
  const [ loading, setLoading]= useState(false)
  const [ successSentCode, setSuccessSentCode]= useState(false)
  const [ errorSentCode, setErrorSentCode]= useState(false)
  const [ messerrorSentCode,setMesserrorSentCode] = useState("")
  const [ successSentRegistration, setSuccessSentRegistration]= useState(false)
  const [ errorSentRegistration, setErrorSentRegistration]= useState(false)
  const [ messerrorSentRegistration,setMesserrorSentRegistration] = useState("")
  const [ username,setUsername] = useState("")
  const [ passwordRetrieve,setPasswordRetrieve] = useState("")
  const [ checkPass,setCheckPass] = useState("")
  const [ code,setCode] = useState("")
  
  const setConnection= useStoreActions((state)=>state.setConnection)
  const connected= useStoreState((state)=>state.connected)
  const clearBasketData= useStoreActions((state)=>state.clearBasketData)
  const setTotalBasket= useStoreActions((state)=>state.setTotalBasket)
  const baskets= useStoreState((state)=>state.baskets)
  const navigate = useNavigate()
  
  useEffect(() => {
      const fetchData = async () => {
        if(!connected)return
        navigate('/')
      }
    
    fetchData()
  })

  const handleLogin=async (e)=>{
    e.preventDefault();
    try {
      let username=email
    const response = await axios.post(`${urlData()}/`,{username,password,basketData:baskets},{header:{"Content-Type":"application/json"}});
    console.log(response.data)
    if(!response.data.success) {
      setFindErrorLogin(true);
      setErrorLogin(response.data.message);
      window.$("#messErrLogin").modal("show")
      return 
    }
    window.$("#messErrLogin").modal("hide")
    setFindErrorLogin(false);
    setErrorLogin("");
    let data= response.data
    let token=data.token
    let idUser=data.idUser
    let connected=true
    let infosUser={surname:data?.surname,firstname:data?.firstname}
    let timeLogIn=new Date().toLocaleString('fr-FR');
   
    let payload={timeLogIn,token,idUser,connected,infosUser}
    let basketQuantityInfos=data.basketQuantityInfos
    clearBasketData()
    setTotalBasket(basketQuantityInfos)
    setConnection(payload)
    navigate('/')
  } catch (err) {
  
    setFindErrorLogin(true);
    setErrorLogin(err);
    window.$("#messErrLogin").modal("show")
  }
    
  }

  const handleSendEmail=async (e)=>{
   
    try {
      e.preventDefault();
      setLoading(true)
      setErrorSentCode(false)
      if(!username){
        setLoading(false)
        setMesserrorSentCode("Vous devez renseigner l'adresse email")
        setErrorSentCode(true)
        return
      }

      const response = await axios.post(`${urlData()}/retrieve-pass`,{username},{header:{"Content-Type":"application/json"}});
     
      if(!response.data.success) {
        setLoading(false)
        setMesserrorSentCode(response.data.message)
        setErrorSentCode(true)
        return 
      }
      setLoading(false)
      setSuccessSentCode(true)
    } catch (err) {
      setLoading(false)
      setMesserrorSentCode("Une erreur est survenue")
      setErrorSentCode(true)
    }
    
  }

  const handleRetrievePass=async (e)=>{
    e.preventDefault();
    try {
      setLoading(true)
      setErrorSentRegistration(false)
      setMesserrorSentRegistration("")
      if(!username){
        setLoading(false)
        setMesserrorSentRegistration("Vous devez renseigner l'adresse email.")
        setErrorSentRegistration(true)
        return
      }
      if(!passwordRetrieve){
        setLoading(false)
        setMesserrorSentRegistration("Le mots de passe est invalide.")
        setErrorSentRegistration(true)
        return
      }

      if(!checkPass){
        setLoading(false)
        setMesserrorSentRegistration("Vous devez confirmer le mot de passe.")
        setErrorSentRegistration(true)
        return
      }

      if(passwordRetrieve!==checkPass){
        setLoading(false)
        setMesserrorSentRegistration("Les mots de passe doivent être identiques.")
        setErrorSentRegistration(true)
        return
      }

      
      let password= passwordRetrieve
      
      const response = await axios.post(`${urlData()}/retrieve-pass/user`,{username,password,checkPass,code},{header:{"Content-Type":"application/json"}});
      if(!response.data.success) {
        setLoading(false)
        setMesserrorSentRegistration(response.data.message)
        setErrorSentRegistration(true)
        return 
      }
      setLoading(false)
      setSuccessSentRegistration(true)
      
      setTimeout(()=>{
        clearAllData()
      },1500)
    } catch (err) {
      setLoading(false)
      setMesserrorSentRegistration("Une erreur est survenue")
      setErrorSentRegistration(true)
    }
    
  }

  function clearAllData(){
    setShowModal(false)
    setUsername("")
    setPasswordRetrieve("")
    setCheckPass("")
    setSuccessSentCode(false)
    setErrorSentCode(false)
    setMesserrorSentCode("")
    setSuccessSentRegistration(false)
    setErrorSentRegistration(false)
    setMesserrorSentRegistration("")

  }

  const handleCloseModal=()=>{
    setShowModal(false)
    clearAllData()
  }


  return (
    <div>
      <Template>
        <section className="section-content padding-y">
          <div className="card mx-auto" style={{"maxWidth":"520px","marginTop":"40px"}}>
            <article className="card-body">
              <header className="mb-4"><h4 className="card-title text-center">Connexion</h4></header>
              {errorFindLogin && 
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorLogin}</p>
              </div>
            }
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" onChange={e=>setEmail(e.target.value)} value={email}  className="form-control" />
                  <small id="errorEmail" className="form-text text-muted text-danger"></small>
                </div>
                <div className="form-group">
                  <label>Mot de passe</label>
                  <input type="password" onChange={e=>setPassword(e.target.value)} value={password} className="form-control"/>
                  <small id="errorPassword" className="form-text text-muted text-danger"></small>
                
                </div>
                <div className="form-group">
                  <button type='button' onClick={()=>{setShowModal(true)}} className="btn float-right">Mot de passe oublié?</button> 
                </div> 
                <div className="form-group">
                    <button type="submit" className="btn btn-secondary btn-block"> Se connecter</button>
                </div>   
                <small id="errorConnexion" className="form-text text-muted text-danger"></small>  
              </form>
            </article>
          </div> 
          <p className="text-center mt-4"> <Link to="/inscription">Créer un compte</Link></p>
          <p className="text-center mt-4"> <Link to="/">Mot de passe oublié?</Link></p>
          <br/><br/>
        </section>
        <div className={showModal ? "modal-container" : "hidden"}>
          <form >
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">RE-INITIALISATION MOT DE PASSE</h5>
                <h6>Vous souhaitez ré-initialiser votre mot de passe</h6>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" onChange={(e)=>setUsername(e.target.value)} className="form-control" value={username} disabled={successSentCode} placeholder="Entrer votre adresse email"/>
                </div> 
                {!successSentCode && 
                  <div className="form-group">
                    <button type="button" onClick={(e)=>{handleSendEmail(e)}} title='Recevoir le code par email' className="btn btn-success float-right">Envoyer</button>
                  </div> 
                }
                
                { loading && 
                  <div className="alert alert-success mt-3">
                    <p className="icontext"><i className="icon text-success fa fa-spinner"></i>Chargement......</p>
                  </div>
                }

                { successSentCode && !errorSentCode && 
                  <div className="alert alert-success mt-3">
                    <p className="icontext"><i className="icon text-success fa fa-check"></i>Un code à été envoyé dans votre boîte email.</p>
                  </div>
                }
                { successSentCode && !errorSentCode && 
                  <div className="form-group">
                   <button type="button" onClick={(e)=>{handleSendEmail(e)}} title='Recevoir un nouveau code' className="btn btn-success float-right">Recevoir un nouveau code</button>
                  </div> 
                  
                }

                { !successSentCode && errorSentCode && 
                  <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {messerrorSentCode}</p>
                  </div>
                }
                { successSentCode && !errorSentCode &&
                  <>
                    <div className="form-group">
                      <label>Code</label>
                      <input type="text" onChange={(e)=>setCode(e.target.value)} className="form-control" placeholder=""/>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Mot de passe</label>
                          <input className="form-control" onChange={(e)=>setPasswordRetrieve(e.target.value)} type="password"/>
                      </div> 
                      <div className="form-group col-md-6">
                        <label>Re-taper le mot de passe</label>
                          <input className="form-control" onChange={(e)=>setCheckPass(e.target.value)} type="password"/>
                      </div> 
                    </div>
                  </>
                }
                { successSentRegistration && !errorSentRegistration && 
                  <div className="alert alert-success mt-3">
                    <p className="icontext"><i className="icon text-success fa fa-check"></i>Le mot de passe a été modifié.</p>
                  </div>
                }

                { !successSentRegistration && errorSentRegistration && 
                  <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {messerrorSentRegistration}</p>
                  </div>
                }
              </div>
              <div className="card-footer text-right">
                <div className="btn-group">
                  { code && passwordRetrieve && checkPass && successSentCode &&
                    <button type="button" title='Vous devez sélectionner une ville' onClick={(e)=>{handleRetrievePass(e)}} className="btn btn-success">Valider</button>
                  }
                  
                  <button type="button"onClick={handleCloseModal} title="Annuler la ré-initialisation" className="btn btn-danger">Annuler</button>
                </div>
                  
                
              </div>
            </div>
          </form>
            
        </div>
      </Template>
    </div>
  )
}

export default Login

/*


{errorFindRegister  && !successFindRegister &&
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorRegister}</p>
              </div>
            }

            {errorFindRegistration  &&
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorRegistration}</p>
              </div>
            }
            {successFindRegister && !errorFindRegister &&
              <div className="alert alert-success mt-3">
                <p className="icontext"><i className="icon text-success fa fa-check"></i>Un code à été envoyé dans votre boîte email.</p>
              </div>
            }

*/