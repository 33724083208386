import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom'
import { useStoreActions,useStoreState } from 'easy-peasy';
import Template from '../../../Template';
import {urlData,urlDataImage} from '../../../../services/url';

function PaymentBasketUser({dataPayment}) {
  

  console.log("dataPayment")
  console.log(dataPayment)
  console.log("dataPayment")
  const navigate=useNavigate()
  const {urlViva,timeOver,idUser,orderCode,token}=dataPayment

  const [expiredTime, setExpiredtime] = useState(false)
  const [canceledPayment, setCanceledPayment] = useState(false)
  
  const cancelOrderBuying= async()=>{
    let payloadCancel={idUser,token}
    const responseCancel = await axios.post(`${urlData}/cancel-basket-buying-card`,payloadCancel,{header:{"Content-Type":"application/json"}});
   /*if(!responseCancel.data.success){
        setMessageError(`${response.data.message}`)
        return window.$("#errorMess").modal("show")
      }*/
  }

  useEffect(() => {
    window.onbeforeunload = async function(e) {
      
      window.$("#refreshInfos").modal("show")
      cancelOrderBuying()
      return;
    };

    return () => {
        window.onbeforeunload = null;
    };
  }, []);

  

  const cancelExpired= async()=>{
    let timeExpired=timeOver? timeOver*60000:timeOver
    console.log(`timeExpired:${timeExpired}`)
    setTimeout(async()=>{
      setExpiredtime(true)
      cancelOrderBuying()
    },timeExpired)
  }
  cancelExpired()
  const cancelPayment= async()=>{
      setCanceledPayment(true)
      cancelOrderBuying()
     
     
  }
  const styles = {
    main: {
      width: "100%",
      height:"65vh",
      //border: "1px solid #2d2d2d",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    section: {
      //width: "200px",
      
      
    },
   
  };
  return(
    <div>
        {!expiredTime && !canceledPayment &&
          <section className="section-content padding-y">
            <div className="container">
              
              <div className="row">
                <div className="col-md-12">
                  <iframe src={urlViva} width="100%" height="700px" allow='payment'></iframe>
                </div>
              </div>
              {/*--<div className="row">
                <div className="col-md-12 text-center">
                  <div className="card-body border-top mb-4">
                      <button type="button" onClick={()=>cancelPayment()}  className="btn btn-danger"> Annuler paiement </button>
                  </div>
                </div>
              </div>--*/}
            </div>
          </section>
        }
        

        {
          expiredTime && !canceledPayment &&
          <Template>
          <section className="section-content padding-y">
            <div className="container">
              
            
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="card">
                      <div className="card-body">
                        <div style={styles.main}>
                          <div style={styles.section}>
                              <p style={{display: "flex",justifyContent: "center",alignItems: "center"}} >
                              <img style={{width:"100px"}} src={`${urlDataImage()}logo/logo.png`} alt="Logo LNVX"/></p>
                              <h2>SESSION EXPIREE</h2>
                              
                              <div className="alert alert-danger mt-3">
                                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>Votre session de paiement a expirée.</p>
                              </div>
                              <button type="button" onClick={()=>navigate('/')}  className="btn btn-secondary text-center"> Retourner à la page d'accueil </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>
             
            </div>
          </section>
          </Template>
        }
        {
          canceledPayment && !expiredTime &&
          <Template>
          <section className="section-content padding-y">
            <div className="container">
              
            
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="card">
                      <div className="card-body">
                        <div style={styles.main}>
                          <div style={styles.section}>
                              <p style={{display: "flex",justifyContent: "center",alignItems: "center"}} >
                              <img style={{width:"100px"}} src={`${urlDataImage()}logo/logo.png`} alt="Logo LNVX"/></p>
                              <h2>PAIEMENT ANNULEE</h2>
                              
                              <div className="alert alert-danger mt-3">
                                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>Le paiement a été annulée.</p>
                              </div>
                              <button type="button" onClick={()=>navigate('/')}  className="btn btn-secondary text-center"> Retourner à la page d'accueil </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>
             
            </div>
          </section>
          </Template>
        }
        <div className="modal" id="refreshInfos"  tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">PAIEMENT</h5>
              </div>
              <div className="modal-body">
                <h6>Infos paiement</h6>
                
                <p >Ne pas actualiser la page.</p>
                <p >Vous souhaitez annuler le paiement?<br/> Cliquer sur le bouton Annuler .</p>
                <div className="alert alert-danger mt-3">
                  <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>Attention l'annulation s'effectuera uniquement si le processus de paiement n'est pas en cours.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>  
  );
}

export default PaymentBasketUser