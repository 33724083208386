import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import  { useParams} from 'react-router-dom';
import {useStoreState } from 'easy-peasy';
import SectionTemplateCat from './components/sectionTemplate/SectionTemplateCat';
import {urlData} from './services/url';

function Brand() {
  
  const postCode= useStoreState((state)=>state.postCode)
  const [categoryPage, setCategoryPage] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)
  const params= useParams()
  


  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/brand/${params.idBrand}?postCode=${postCode}`);
         
          if(response.data.success) {
            setFetchError(null);
            setCategoryPage(response.data);
            
          }else{
            
            setFetchError(response.data.message);
          }
      } catch (err) {
       
        
          setFetchError(err.message);
      } finally {
        !postCode?setShowPrices(false):setShowPrices(true)
         
        setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode])
//SectionTemplate({isLoading,fetchError,titleSection,infos})

  return (
    <SectionTemplateCat isLoading={isLoading} fetchError={fetchError} titleSection={`Marque: ${categoryPage.brandName}`} infos={categoryPage} showPrices={showPrices}/>
    
  )
}

export default Brand
