import React from 'react'

function Mentions() {
  return (
    <>
        <h5 class="card-title">MENTIONS LÉGALES</h5>
        <p>
        Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site : www.lnvx.fr les informations suivantes : ÉDITEUR Le site www.lnvx.fr est la propriété exclusive de SASU Les nouveaux , qui l’édite. Les nouveaux SASU a u capital de 5 000€ Tél : 0 754 033 315 SIRET : 894 326 701 RCS : Compiègne B 894 326 701 Numéro TVA intracommunautaire: FR79894326701 Adresse de courrier électronique : info@lnvx.fr HÉBERGEMENT Le site est hébergé par 7 PL DE LA GARE, 57200 SARREGUEMINES .
        </p>
    </>
  )
}

export default Mentions