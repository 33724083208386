import React,{ useState } from 'react'
import {useStoreState } from 'easy-peasy';
import axios from 'axios';
import {urlData} from '../../services/url';
import {valueSupMyNumber} from '../../services/helpers';

function IdentityChange({profil,refreshData}) {


  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const [errorFindIdentity, setErrorFindIdentity] = useState(false);
  const [errorMessageFindIdentity, setErrorMessageFindIdentity] = useState(false);
  const [successFindIdentity, setSuccessFindIdentity] = useState(false);
  const [successMessageFindIdentity, setSuccessMessageFindIdentity] = useState(false);
  const [newSurname, setNewSurname] = useState("");
  const [firstName, setFirstName] = useState("");
  const [identityPass, setIdentityPass] = useState("");
  
  const [closeFormIdentity, setCloseFormIdentity] = useState(false);


  const hideFormError=()=>{
    window.$(".formChangeIdentity").hide()
  }
  hideFormError()


  const showInvalideValue=(value,message="Invalide")=>{
    console.log(`value clall: #${value}`)
    window.$(`#${value}`).html(message).show()
  }
  


 
  const validChangeIdentity= async()=>{

    try {
      hideFormError()
      setErrorFindIdentity(false)
      setErrorMessageFindIdentity("")
      setSuccessFindIdentity(false)
      setSuccessMessageFindIdentity("")
      if(!newSurname){
        showInvalideValue('checkNewIdentity','Invalide')
        return 
      }
    
      
      console.log(`pass call`)
      if(!firstName){
        showInvalideValue('formChangeFirstname','Invalide')
        return 
      }
      /*if(!valueSupMyNumber(2,newSurname)){
        
        showInvalideValue('checkNewIdentity','Invalide, 2 caractères minimum')
        return 
      }
      if(!valueSupMyNumber(2,firstName)){
        showInvalideValue('formChangeFirstname','Invalide, 2 caractères minimum')
        return 
      }*/

      if(!identityPass){
        showInvalideValue('checkPassNewIdentity','Mot de passe invalide')
        return 
      }

      
      let body ={
        idUser,firstname:firstName,surname:newSurname,password:identityPass
      }
      const responseIdentity = await axios.put(`${urlData()}/user-account/identity`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      console.log("responseIdentity")
      console.log(responseIdentity)
      console.log("responseIdentity")
      if(!responseIdentity.data.success){
        setErrorFindIdentity(true)
        setErrorMessageFindIdentity(responseIdentity.data.message)
        setSuccessFindIdentity(false)
        setIsLoadingData(false)
      
        return
      }
      setIsLoadingData(false)
      setSuccessMessageFindIdentity(responseIdentity.data.message)
      setCloseFormIdentity(true)
      setSuccessFindIdentity(true)
      setTimeout(()=>{
        handleCloseChangeIdentity()
      },3000)
      refreshData()
      return
    } catch (error) {
      setErrorFindIdentity(true)
      setErrorMessageFindIdentity("Une erreur interne est survenue.")
    }
    
  }


  const handleCloseChangeIdentity=()=>{
  
    setFirstName("")
    setIdentityPass("")
    setNewSurname('')
    setErrorFindIdentity(false)
    setErrorMessageFindIdentity("")
    setSuccessMessageFindIdentity("")
    setSuccessFindIdentity(false)
    setCloseFormIdentity(false)
    
    window.$("#identityForm").modal("hide")
    hideFormError()
  }





  return (
    <article className="card mb-4">
        <header className="card-header">
          <strong className="d-inline-block mr-3">IDENTITE</strong>
        </header>
        <div className="card-body">
          <div className="row" > 
            <div className="col-md-8">
              <h6 className="text-muted">Modifier votre identité</h6>
              <p>Identité: {profil.userInfos.firstname_customer} {profil.userInfos.surname_customer}</p>
              <button type='button' onClick={()=>window.$("#identityForm").modal("show")} className='btn btn-warning'>Modifier</button>
            </div>
          </div> 
        </div> 
        <div className="modal" id="identityForm"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">MODIFICATION IDENTITE</h5>
              
            </div>
            <div className="modal-body">
                <h6>Modifier votre identité</h6>
                {errorFindIdentity && 
                  <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorMessageFindIdentity}</p>
                  </div>
                }
                {successFindIdentity && !errorFindIdentity &&
                  <div className="alert alert-success mt-3">
                    <p className="icontext"><i className="icon text-success fa fa-check"></i>{successMessageFindIdentity}</p>
                  </div>
                }
                
                {
                  !closeFormIdentity && 
                  <form>
                    <div className="form-group">
                      <label>Nom</label>
                      <input type="text" onChange={(e)=>setNewSurname(e.target.value)} className="form-control" value={newSurname}/>
                      <span className='text-danger formChangeIdentity'  id="checkNewIdentity"></span>
                    </div>
                    <div className="form-group">
                      <label>Prénom</label>
                      <input type="text" onChange={(e)=>setFirstName(e.target.value)} className="form-control" value={firstName}/>
                      <span className='text-danger formChangeIdentity' id="formChangeFirstname"></span>
                    </div>
                    <div className="form-group">
                      <label>Mot de passe</label>
                      <input type="password" onChange={(e)=>setIdentityPass(e.target.value)} className="form-control" value={identityPass} />
                      <span className='text-danger formChangeIdentity'id="checkPassNewIdentity"></span>
                    </div>
                    
                    
                  </form>
                }
                
            </div>
            {
                  !closeFormIdentity &&
                  <div className="modal-footer">
                    { !newSurname ||  !firstName || !identityPass &&
                      <button type="button" title='Vous devez entrer les information' disabled className="btn btn-success">Valider</button>
                    }
      
                    { newSurname &&  firstName && identityPass &&
                      <button type="button" className="btn btn-success" onClick={validChangeIdentity}>Valider</button>
                    }
                    <button type="button" onClick={handleCloseChangeIdentity} className="btn btn-danger " >
                        Annuler
                    </button>
                  
                </div>
            }
           
          </div>
        </div>
      </div>
      </article> 
    
  )
}

export default IdentityChange