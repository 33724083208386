import React, { Component, useState} from 'react'
import * as bootstrap from 'bootstrap'
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom'
import {urlDataImage,urlData} from '../../services/url';
import { dotToComma } from '../../services/helpers';

  class AddToBasket extends Component {

    constructor(props){
      super(props);
     
      
      this.state={
        isLoadingProduct:this.props.isLoadingProduct || true,
        articleSizes:this.props.articleSizes,
        setArticleSizes:this.props.setArticleSizes,
        setIsLoadingProduct:this.props.setIsLoadingProduct,
        setMessageError:this.props.setMessageError,

        setMoveToBasket:this.props.setMoveToBasket,
        setShowPopupError:this.props.setShowPopupError,
        setShowPopupSuccess:this.props.setShowPopupSuccess,
        setDeleteFromWishlist:this.props.setDeleteFromWishlist,

        addBasket:this.props.addBasket,
        connected:this.props.connected,
        postCode:this.props.postCode,
        token:this.props.token,
        idUser:this.props.idUser,
        addTotalBasket:this.props.addTotalBasket,
  
        messageError:"",
        articlePage:{},
       
        showModal:false,
        messageError:"",
  
        fetchError:"",
  
        quantityPrice:this.props.quantityPrice || 1,
        pickPrice:0,
        messageError:"",
      }
      
      
    }
    

   navigate= (e)=>useNavigate(e)
   showErrorBasket=(message)=>{
    this.state.setMessageError(message)
    window.$("#multipleArticle").modal("hide")
    window.$("#errorBasket").modal("show")
    setTimeout(()=>{
      window.$("#errorBasket").modal("hide")
    },3000)
  }

   showSuccessBasket=()=>{
    
    window.$("#multipleArticle").modal("hide")
    window.$("#successBasket").modal("show")
    setTimeout(()=>{
      window.$("#successBasket").modal("hide")
      console.log(`this.props.moveToBasket: ${this.props.moveToBasket}`)
      if(this.props.moveToBasket){
        this.state.navigate('/panier')
      }
    },1500)
    
  }

   checkMultipleSize= async(idBookProd,idProduct,idBookSize)=>{
    return new Promise(async(resolve,reject)=>{
      try {
        let body={idBookProd,idProduct,idBookSize}
        const response = await axios.post(`${urlData()}/check-multiple-size`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${this.state.token}`}});
        //console.log(response.data)
        if(!response.data.success) {
          resolve({success:false,find:false,datas:[]})
        }
        if(!response.data.success) {
          resolve({success:true,find:false,datas:[]})
        }
        resolve({success:true,find:true,datas:response.data.datas})
      } catch (error) {
        resolve({success:false,find:false,datas:[]})
      }
    })
    
    
    

  }
 

  hideModal(){
    var myModal = new bootstrap.Modal(document.getElementByClass(`modal`))
    myModal.hide()
  }

   addToServerBasket= async(idBookProd,idProduct,idBookSize,quantity)=>{
   
    let body={idBookProd,idProduct,idBookSize,quantity,idUser:this.state.idUser,postCode:this.state.postCode}
    const response = await axios.post(`${urlData()}/addProductBasket`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${this.state.token}`}});
    console.log("response.data.success",response.data)
    if(!response.data.success) {
      if(this.props.showPopupError) this.state.showErrorBasket(response.data.message)
      return
    }
    if(!response.data.addProduct) {
      if(this.props.showPopupError) this.showErrorBasket(response.data.message)
      return
    }
    
    this.state.addTotalBasket(quantity)
    if(this.props.deleteFromWishlist){
      const responseDeleteWishlist = await axios.delete(`${urlData()}/wishlist/${this.state.idUser}/${idBookProd}`,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${this.state.token}`}});
    }
    
    if(this.props.showPopupSuccess){this.showSuccessBasket()}
    if(!this.props.showPopupSuccess && this.props.moveToBasket){this.navigate('/panier')}

  }

   finallyAddToBasket=async (idBookProd,idProduct,idBookSize,quantity,showPopupSuccess,showPopupError)=>{
    if(!this.state.connected){
      let dataRecord=await this.state.addBasket({idBookProd,idProduct,idBookSize,quantity})
      
      if(!dataRecord){
        if(showPopupError)this.showErrorBasket(dataRecord.message)
      }
      
      if(showPopupSuccess){this.showSuccessBasket()}
      if(!showPopupSuccess && this.props.moveToBasket){this.navigate('/panier')}
      return
    }

    this.addToServerBasket(idBookProd,idProduct,idBookSize,quantity)
    return
  }

   handleAddBasket= async (e,idBookProd,idProduct,idBookSize,quantity,showPopupSuccess,showPopupError)=>{
    try {
      e.preventDefault()
     
      if(!this.props?.postCode){
        window.$(`#postCode`).modal("show")
        return
      }
      this.state.setIsLoadingProduct(true)
      if(!this.props?.multipleArticle){
        this.state.setIsLoadingProduct(false)
        
        await  this.finallyAddToBasket(idBookProd,idProduct,idBookSize,quantity,showPopupSuccess,showPopupError)
        return
      }
     
      
      let findMulitple= await this.checkMultipleSize(idBookProd,idProduct,idBookSize)
      
      if(findMulitple.success && findMulitple.find){
        const isStockZero = (currentValue) => currentValue.stock_bookprosize ===0;
        if(findMulitple.datas.every(isStockZero)){
          if(showPopupError)this.showErrorBasket('Stock épuisé.')
          return 
        }
        this.state.setArticleSizes(findMulitple.datas)
       
        window.$(`#multipleArticle`).modal("show")
  
        this.state.setIsLoadingProduct(false)
      
      }else{
        this.state.setIsLoadingProduct(true)
        await  this.finallyAddToBasket(idBookProd,idProduct,idBookSize,quantity,showPopupSuccess,showPopupError)
      }
    } catch (error) {
      console.log(error)
    }
    
  

  }

   handleCloseMultipleArticle=(e)=>{
    e.preventDefault()
    
    window.$(`#multipleArticle`).modal("hide")
    this.state.setIsLoadingProduct(true)
    this.state.setArticleSizes([])
    
  }

  render(){

    const  {children,idBookProd,idProduct,idBookSize,quantity,moveToBasket,deleteFromWishlist,showPopupSuccess,showPopupError,multipleArticle,postCode}= this.props
 
    return (
      <div onClick={(e)=>{
        this.state.setMoveToBasket(moveToBasket?moveToBasket:false)
        this.state.setDeleteFromWishlist(deleteFromWishlist?deleteFromWishlist:false)
        this.state.setShowPopupSuccess(showPopupSuccess==1?true:false)
        this.state.setShowPopupError(showPopupError==1?true:false)
        this.handleAddBasket(e,idBookProd,idProduct,idBookSize,quantity,showPopupSuccess,showPopupError)
      }}>
        {children}
        
       
        
      </div>
    )
  }
  
}

export default AddToBasket

/*


{v.nameColor}<br/>
                              {v.product.articlePromo?dotToComma(v.newprice_bookpromotionsize):dotToComma(v.price_bookprosize)}€
                              {v.product.articlePromo &&<br/>}
                              {v.product.articlePromo &&<del>{dotToComma(v.oldprice_bookpromotionsize)}€</del>
                              }
                              */