import { Link } from "react-router-dom";
import {random_sort} from '../../services/helpers';
export default function sectionThree({linkApiImages,datas}) {
  let infos= Array.isArray(datas) && datas.length>0?datas.sort(random_sort).slice(0,8):[]
  
  
  return (
    <div>
      <section className="padding-bottom">
        <header className="section-heading heading-line">
          <h4 className="title-section text-uppercase">LES + VENDUS</h4>
        </header>

        <div className="card card-home-category">
          <div className="row no-gutters">
	          <div className="col-md-3">
              <div className="home-category-banner bg-light-orange">
                <h5 className="title">Categories les plus vendus</h5>
                <p>Une séléction d'article des plus vendus. </p>
                
                <Link to="/nouveautes" className="btn btn-outline-secondary rounded-pill ">Voir les nouveautés</Link>
                {/*<img src="images/items/14.jpg" className="img-bg "/>*/}
              </div>

	          </div> 
	          <div className="col-md-9">
              <ul className="row no-gutters bordered-cols">
                {infos && infos.map((e,index)=>{
                  return(
                    <li key={index} className="col-6 col-lg-3 col-md-4">
                      <Link to={`/categories/${e.idCategoryProduct}`} className="item"> 
                        <div className="card-body">
                          <h6  style={{fontSize:"14px"}}>{e.categoryProduct}  </h6>
                          <img className="img-sm rounded float-right" src={`${linkApiImages}${e.imageProduct}`}/> 
                          <p className="text-muted"  style={{fontSize:"12px"}}><i className="fa fa-star"></i> {e.nameProduct}</p>
                        </div>
                      </Link>
                    </li>
                  )
                })

                }
                
                {/*
                
                <li className="col-6 col-lg-3 col-md-4">
                  <a href="#" className="item"> 
                    <div className="card-body">
                      <h6 className="title">Group of electronic  stuff collection  </h6>
                      <img className="img-sm float-right" src="images/items/10.jpg"/> 
                      <p className="text-muted"><i className="fa fa-map-marker-alt"></i> Guanjou, China</p>
                    </div>
                  </a>	
                </li>
                <li className="col-6 col-lg-3 col-md-4">
                  <a href="#" className="item"> 
                    <div className="card-body">
                      <h6 className="title">Home and kitchen electronic  stuff collection  </h6>
                      <img className="img-sm float-right" src="images/items/11.jpg"/> 
                      <p className="text-muted"><i className="fa fa-map-marker-alt"></i> Guanjou, China</p>
                    </div>
                  </a>
                </li>
                <li className="col-6 col-lg-3 col-md-4">
                  <a href="#" className="item"> 
                    <div className="card-body">
                      <h6 className="title">Home and kitchen electronic  stuff collection  </h6>
                      <img className="img-sm float-right" src="images/items/12.jpg"/> 
                      <p className="text-muted"><i className="fa fa-map-marker-alt"></i> Guanjou, China</p>
                    </div>
                  </a>
                </li>
                <li className="col-6 col-lg-3 col-md-4">
                  <a href="#" className="item"> 
                    <div className="card-body">
                      <h6 className="title">Home and kitchen electronic  stuff collection  </h6>
                      <img className="img-sm float-right" src="images/items/1.jpg"/> 
                      <p className="text-muted"><i className="fa fa-map-marker-alt"></i> Guanjou, China</p>

                    </div>
                  </a>
                </li>
                <li className="col-6 col-lg-3 col-md-4">
                  <a href="#" className="item"> 
                    <div className="card-body">
                      <h6 className="title">Home and kitchen electronic  stuff collection  </h6>
                      <img className="img-sm float-right" src="images/items/2.jpg"/> 
                      <p className="text-muted"><i className="fa fa-map-marker-alt"></i> Guanjou, China</p>
                    </div>
                  </a>
                </li>*/}
              </ul>
            </div>
          </div>
        </div> 
      </section>
    </div>
  );
}