import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import {urlData} from './services/url';
import SectionTemplate from './components/sectionTemplate/SectionTemplate';

function Flash() {
  const postCode= useStoreState((state)=>state.postCode)
  const [flashsPage, setFlashPage] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)

  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/promotionspage?postCode=${postCode}`);
          if(response.data.success) {
            setFetchError(null);
            setFlashPage(response.data);
            
          }else{
            
            setFetchError(response.data.message);
          }
      } catch (err) {
       
        
          setFetchError(err.message);
      } finally {
        !postCode?setShowPrices(false):setShowPrices(true)
         
        setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode])

  return (
    <SectionTemplate isLoading={isLoading} fetchError={fetchError} titleSection='Flash' infos={flashsPage} showPrices={showPrices}/>
  )
}

export default Flash
