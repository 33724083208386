import { forwardRef } from 'react';
import { dotToComma } from "../../services/helpers"


const  OrderInfo=forwardRef((props,ref)=>{
    const {order,ordersItem} = props
    const priceTotal=(totalBasket,deliveyPrice,promoPrice)=>{
        let total = totalBasket+deliveyPrice-promoPrice
        total=dotToComma(total)
        return total
    }

  return (
    <article ref={ref} className="card  mb-3" >
        <div className="card-body">
        <div className="row">
            <div  className="col-md-12 text-center">
                <h2>COMMANDE</h2>    
            </div>
            <div  className="col-md-12">
                <article className="card mb-4">
                    
                    <div className="card-body">
                    <div className="row" > 
                        <div className="col-md-8">
                            <h6>LNVX</h6>
                        </div>
                        <div className="col-md-4">
                        <h6 className="text-muted">Client</h6>
                       
                        <p>{order.firstnameCustomer} {order.surnameCustomer}</p>
                        </div>
                    </div> 
                    </div> 
                
                </article> 
            </div>
            <div  className="col-md-12 text-center">
                <h6>COMMANDE N°{order?.number_order} </h6>    
            </div>
            <div  className="col-md-12">
                <article className="card mb-4">
                    
                    <div className="card-body">
                    <div className="row" > 
                        <div className="col-md-8">
                        <h6 className="text-muted">Livraison</h6>
                        <p><time className="text-muted"><i className="fa fa-truck"></i></time>:&nbsp;{order.dateDeliveryFrench}<br/>
                        <time className="text-muted"><i className="fa fa-address-book"></i></time>:&nbsp;{order.nameDeliveryAddress} <br/>  
                            {order.roadDeliveryAddress} {order.infosroadDeliveryAddress && `,${order.infosroadDeliveryAddress}`} <br/> 
                            {order.postcodeDeliveryAddress} {order.townDeliveryAddress}<br/>
                            <time className="text-muted"><i className="fa fa-phone-volume"></i></time>:&nbsp; {order.contactAdresse} <br/>
                            
                            Statut:&nbsp;{order.cancel_order===1 &&
                                    <span className="text-danger">COMMANDE ANNULEE </span>
                                }
                                {order.cancel_order!==1 &&
                                    <span className={order.typestatusorder==='COMMANDE VALIDEE' || order.typestatusorder==='LIVRAISON EFFECTUEE'?"text-success":"text-warning"}>{order.typestatusorder} </span>
                                }
                        </p>
                        </div>
                        <div className="col-md-4">
                        <h6 className="text-muted">Paiement</h6>
                        {/*<span className="text-success">
                            <i className="fab fa-lg fa-cc-visa"></i>
                            Visa  **** 4216  
                        </span>*/}
                        <p>CB<br/>Sous total:  {dotToComma(order.total_order)}€ <br/>
                        Livraison:  {dotToComma(order.pricedelivery_order)}€ <br/> 
                        {order.amountpromo_order>0 && <span>Promo:  -{dotToComma(order.amountpromo_order)}€ <br/></span> }
                        <span className="b">Total:  {priceTotal(order.total_order,order.pricedelivery_order,order.amountpromo_order)}€ </span>
                        </p>
                        </div>
                    </div> 
                    </div> 
                
                </article> 
            </div>
            <div  className="col-md-12">
            <article className="card mb-4">
                
                <div className="card-body">
                <table className='table'>
                    <thead>
                        <tr>
                        <th>Désignation</th>
                        <th className='text-center'>Quantité</th>
                        <th className='text-center'>Prix</th>
                        <th className='text-center'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        ordersItem?.map((e,index)=>{
                            return(
                            <tr key={index}>
                                <td>{e.nameProduct}</td>
                                <td className='text-center'>{e.quantity_orderitem}</td>
                                <td className='text-center'>{dotToComma(e.price_orderitem)}€</td>
                                <td className='text-center'>{dotToComma(e.total_orderitem)}€</td>
                            </tr>
                            )
                        })

                        }
                    </tbody>
                </table>
                </div> 
            
            </article> 
            </div>
        </div> 
        </div>
    </article> 
  )
})

export default OrderInfo