import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import {urlData} from './services/url';
import SectionTemplate from './components/sectionTemplate/SectionTemplate';

function Tenders() {

  const postCode= useStoreState((state)=>state.postCode)
  const [tendersPage, setTendersPage] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)
  
  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/tenderspage?postCode=${postCode}`);
          if(response.data.success) {
            setFetchError(null);
            setTendersPage(response.data);
            
          }else{
           
            setFetchError(response.data.message);
          }
      } catch (err) {
          setFetchError(err.message);
      } finally {
        !postCode?setShowPrices(false):setShowPrices(true)
        setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode])

  return (
    <SectionTemplate isLoading={isLoading} fetchError={fetchError} titleSection='Tendance' infos={tendersPage} showPrices={showPrices}/>
  )
}

export default Tenders
