import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { dotToComma } from '../../../../services/helpers'
import Template from '../../../Template'
import { useStoreActions,useStoreState } from 'easy-peasy';
import BasketEmpty from '../../BasketEmpty';
import { urlData, urlDataImage} from '../../../../services/url';
import axios from 'axios';

function DetailsBasketUser({data,showDeliveryInfos,basketEmpty,refreshData}) {
  
  const totalBasket= useStoreState((state)=>state.totalBasket)
  const linkApiImages=urlDataImage()
  const addBasket= useStoreActions((state)=>state.addBasket)
  const removeBasket= useStoreActions((state)=>state.removeBasket)
  const deleteBasket= useStoreActions((state)=>state.deleteBasket)
  const [ loadingBtn, setLoadingBtn ] = useState(false)
  const token= useStoreState((state)=>state.token)
  const idUser= useStoreState((state)=>state.idUser)

  useEffect(() => {
    refreshData()
    
  },[totalBasket])


  const getTotalItem=(quantity,price)=>{
    try {
      let priceTotal= quantity*price
      priceTotal= dotToComma(priceTotal)
      return priceTotal
    } catch (error) {
      return 'Erreur'
      
    }
  }

  const findAvailable =(bookProd,size,stock)=>{
    return bookProd!==0 && size!==0 && stock!==0?true:false
   }
 
   const quantityOverStock =(stock,quantity)=>{
     return quantity>stock?true:false
    }


  const addOne=async (idBookProd,idProduct,idBookSize,stock,quantityBasket,availableBookProd,availableSize)=>{
    try {
      
      setLoadingBtn(true)
      if(!findAvailable(availableBookProd,availableSize,stock)){
        window.$('#basketInfoDispo').modal('show')
        setLoadingBtn(false)
        return
      }

      if(quantityOverStock(stock,quantityBasket)){
        window.$('#basketInfoStock').modal('show')
        setLoadingBtn(false)
        return
      }
      const quantity=1;
    
      const addToBasket = await axios.post(`${urlData()}/addProductBasket`,{idProduct,idBookSize,idBookProd,idUser,quantity:1},{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      setLoadingBtn(false)
      refreshData()
      
    } catch (error) {
      setLoadingBtn(false)
      refreshData()
    }
  }
  const removeOne=async (idBookProd,idProduct,idBookSize,currentQuantity)=>{
    try {
      console.log(`currentQuantity${currentQuantity}`)
      setLoadingBtn(true)
      if(currentQuantity===1){
        window.$('#basketDeleteItem').modal('show')
        setLoadingBtn(false)
        return
        
      }

      
      const removeOneFromBasket = await axios.post(`${urlData()}/removeProductBasket`,{idProduct,idBookSize,idBookProd,idUser,quantity:1},{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      setLoadingBtn(false)
      refreshData()
    } catch (error) {
      setLoadingBtn(false)
      refreshData()
    }
   
  }


  const deleteArticle= async (idBookProd,idProduct,idBookSize)=>{
    try {
      setLoadingBtn(true)
      let body={idProduct,idBookSize,idBookProd,idUser}
      const deleteToBasket = await axios.post(`${urlData()}/deleteProductBasket`,{idProduct,idBookSize,idBookProd,idUser},{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      setLoadingBtn(false)
      refreshData()
      
    } catch (error) {
      setLoadingBtn(false)
      refreshData()
    }
    
  }

  const productAvailable = (e) =>{
    if(e.stock<e.quantity_basket)return false
    if(e.availableBookProd===0 || e.availableSize===0)return false
    return true
  };

  const checkValidNextStep=()=>{
  
    if(!Array.isArray(data?.infosPanier?.products) || data?.infosPanier?.products?.length===0)return
    if(!data?.infosPanier?.products.every(productAvailable)){
      window.$('#basketUserInfoAvailableProduct').modal("show")
      return
    }
    showDeliveryInfos()
    return
  }

  

  if(basketEmpty){
    return(
      <BasketEmpty/>
    )
  }
  if(!basketEmpty){
    return (
      <Template>
              
        <section className="section-content padding-y" >
          <div className="container">
  
          <div className="row">
            <main className="col-md-9">
          <div className="card" style={{minHeight:"65vh"}}>
            <div className="d-sm-none">
              <table className="table table-borderless table-shopping-cart" >
                <thead className="text-muted">
                  <tr className="small text-uppercase">
                    <th scope="col"  className='text-center '>Articles</th>
                  </tr>
                </thead>
                <tbody>
                  {data.infosPanier.products.map((e,index)=>{
                  
                    return(
                      <tr key={index}>
                        <td>
                          <table className="  table table-borderless table-shopping-cart">
                            <tr>
                              <td colSpan={4}>
                                <figure className="itemside"  >
                                  <div className="aside"><img src={`${linkApiImages}${e.nameImage}`} className="img-xs"/></div>
                                  <figcaption className="info">
                                    <Link to={`/articles/${e.idBookProd}`}  className="title text-dark">{e.nameProduct}</Link>
                                    {!findAvailable(e.availableBookProd,e.availableSize,e.stock)  &&
                                      <p className="text-danger small">Le produit est indisponible</p>
                                    }
                                    {findAvailable(e.availableBookProd,e.availableSize,e.stock) && quantityOverStock(e.stock,e.quantity_basket)  &&
                                      <p className="text-danger small">Stock disponible {e.stock}</p>
                                    }
                                    {!e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.price)}€</p>}
                                    {e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.newPrice)}€ <span style={{textDecorationLine:"line-through"}}>{dotToComma(e.oldPrice)}€</span></p>}
                                    <p className="text-muted small">Taille: {e.nameSizing}, Couleur: {e.nameColor} </p>
                                    
                                  </figcaption>
                                </figure>
                              </td>
                            </tr>
                            <tr>
                              <td className='text-center ' style={{paddingTop:'22px',alignItems:"center"}} width="33%"> 
                                <div className='text-center price-wrap'>
                                  <var className="price">
                                      { e.idBookPromotionSize?
                                        getTotalItem(e.quantity_basket,e.newPrice)
                                        :
                                        getTotalItem(e.quantity_basket,e.price)
                                                                      
                                      }
                                      €
                                  </var> 
                                </div>
                                  
                                    
                              </td>
                              <td width="56%"> 
                                
                                  <div className="input-group">
                                    
                                    <button className="btn btn-xs"  style={{width:"10px"}}  disabled={loadingBtn?true:false} onClick={()=>removeOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity_basket,e.availableBookProd,e.availableSize)} type="button" id="button-minus"> &minus; </button>
                                    
                                    <div style={{paddingTop:'9px'}}>{e.quantity_basket}</div>
                                    
                                    <button className="btn btn-xs" style={{width:"10px"}}  disabled={loadingBtn?true:false} onClick={()=>{
                                          
                                          addOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity_basket,e.availableBookProd,e.availableSize)
                                          
                                        }} type="button" id="button-plus"> + </button>
                                    
                                  </div>
                              
                                
                              </td>
                              
                              <td  width="10%"> 
                                <button type="button" disabled={loadingBtn?true:false} onClick={()=>deleteArticle(e.idBookProd,e.idProduct,e.idBookSize)} className="btn btn-light"> <i className="fa fa-trash "></i></button>
                              </td>
                            </tr>
                          </table>
                          
                        </td>
                      
                      </tr>
                    )
                  })}
                  
                </tbody>
              </table>
            </div>
            <div  className="d-sm-none">
              <div className=" card-body border-top">
                  <div className='text-center'>
                    <dl className="text-center">
                      <dt>Total Panier: {dotToComma(data?.infosPanier?.payInfos?.toPay)}€</dt>
                    </dl>
                  </div>
                  <div className='text-center'>
                    <hr/>
                  </div>
                  <div className='text-center'>
                  <button type="button" onClick={()=>checkValidNextStep()} className="btn btn-secondary float-md-right"> Commander <i className="fa fa-chevron-right"></i> </button>
                  </div>
                  <div className='text-center'>
                    <hr/>
                  </div>
                  
                  <div className='text-center'>
                    <Link to="/" className="btn btn-light"> <i className="fa fa-chevron-left"></i> Continuer mes achats </Link>
                  </div>
                
              </div>	
            </div> 
            <div className=" d-none d-sm-block">
              <table className="table table-borderless table-shopping-cart"  >
                  <thead className="text-muted">
                    <tr className="small text-uppercase">
                      <th scope="col">Articles</th>
                      <th scope="col" width="120">Quantité</th>
                      <th scope="col" width="120">Prix</th>
                      <th scope="col" className="text-right" width="200"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.infosPanier?.products.map((e,index)=>{
                      return(
                        <tr key={index}>
                          <td>
                            <figure className="itemside">
                              <div className="aside"><img src={`${linkApiImages}${e.nameImage}`} className="img-sm"/></div>
                              <figcaption className="info">
                                <Link to={`/articles/${e.idBookProd}`}  className="title text-dark">{e.nameProduct}</Link>
                                {!findAvailable(e.availableBookProd,e.availableSize,e.stock)  &&
                                  <p className="text-danger small">Le produit est indisponible</p>
                                }
                                {findAvailable(e.availableBookProd,e.availableSize,e.stock) && quantityOverStock(e.stock,e.quantity_basket)  &&
                                  <p className="text-danger small">Stock disponible {e.stock}</p>
                                }
                                {!e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.price)}€</p>}
                                {e.idBookPromotionSize && <p className="text-muted small">PU:{dotToComma(e.newPrice)}€ <span style={{textDecorationLine:"line-through"}}>{dotToComma(e.oldPrice)}€</span></p>}
                                <p className="text-muted small">Taille: {e.nameSizing}, Couleur: {e.nameColor}</p>
                              </figcaption>
                            </figure>
                          </td>
                          <td> 
                          <div className="form-group col-md flex-grow-0">
                              <div className="input-group mb-3 input-spinner">
                                <div className="input-group-append">
                                  <button className="btn " disabled={loadingBtn?true:false} onClick={()=>removeOne(e.idBookProd,e.idProduct,e.idBookSize,e.quantity_basket)} type="button" id="button-minus"> &minus; </button>
                                </div>
                              
                                <div style={{paddingTop:'9px'}}>{e.quantity_basket}</div>
                                
                                <div className="input-group-prepend">
                                  <button className="btn " disabled={loadingBtn?true:false} onClick={()=>addOne(e.idBookProd,e.idProduct,e.idBookSize,e.stock,e.quantity_basket,e.availableBookProd,e.availableSize)} type="button" id="button-plus"> + </button>
                                </div>
                              </div>
                            </div>
                            
                          </td>
                          <td> 
                            {e.idBookPromotionSize?
                              <div className="price-wrap"> 
                                <var className="price">{getTotalItem(e.quantity_basket,e.newPrice)}€</var> 
                                
                              </div>
                              :
                              <div className="price-wrap"> 
                                
                                <var className="price">{getTotalItem(e.quantity_basket,e.price)}€</var> 
                                
                              </div>
                          
                          
                            }
                              
                          </td>
                          <td className="text-left"> 
                            <button type="button" disabled={loadingBtn?true:false} onClick={()=>{
                              
                              deleteArticle(e.idBookProd,e.idProduct,e.idBookSize)
                            }} className="btn btn-light"> <i className="fa fa-trash "></i></button>
                          </td>
                        </tr>
                      )
                    })}
                    
                  </tbody>
                </table>
    
                <div className="card-body border-top">
                  <button type="button" onClick={()=>checkValidNextStep()} className="btn btn-secondary float-md-right"> Commander <i className="fa fa-chevron-right"></i> </button>
                  <Link to="/" className="btn btn-light"> <i className="fa fa-chevron-left"></i> Continuer mes achats </Link>
                </div>	
            </div>
         
          </div> 
  
          <div className="alert alert-success mt-3">
            <p className="icontext"><i className="icon text-success fa fa-truck"></i> Livraison rapide</p>
          </div>
  
            </main> 
            <aside className="d-none d-sm-block col-md-3">
              
              <div className="card">
                <div className="card-body">
                    
                    <dl className="dlist-align">
                      <dt>Total Panier:{dotToComma(data?.infosPanier?.payInfos?.toPay)}€</dt>
                      
                    </dl>
                    <hr/>
                    <button type="button" onClick={()=>checkValidNextStep()} className="btn btn-secondary float-md-right"> Commander <i className="fa fa-chevron-right"></i> </button>
                    
                </div> 
              </div>  
            </aside> 
          </div>
  
          </div> 
        </section>
  
       
      
        </Template>
    )
  }
  
 
}

export default DetailsBasketUser