import React from 'react'

function ArticleFive() {
  return (
    <>
        <h5 class="card-title">Article 5</h5>
        <p>
        Livraison A réception de la livraison le client s’engage à vérifier l’état du produit en présence du transporteur. En cas de dommage le client devra contacter le service client dans les plus brefs délais. La société Les nouveaux se garde le droit de choisir le transporteur. Conformément à l’article L 216-1 du code de la consommation, la livraison se fera dans les délais indiqués par la compagnie X, et à défaut d’indication sous 30 jours.
        </p>
    </>
  )
}

export default ArticleFive