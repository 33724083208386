import React,{ useState } from 'react'
import {useStoreState } from 'easy-peasy';
import axios from 'axios';
import {urlData} from '../../services/url';

function PasswordChange({profil,refreshData}) {


  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const [errorFindPassword, setErrorFindPassword] = useState(false);
  const [errorMessageFindPassword, setErrorMessageFindPassword] = useState(false);
  const [successFindPassword, setSuccessFindPassword] = useState(false);
  const [successMessageFindPassword, setSuccessMessageFindPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confNewPassword, setConfNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [sentCodeUsername, setSentCodeUsername] = useState(false);
  const [codeUsername, setCodeUsername] = useState("");
  const [closeFormPassword, setCloseFormPassword] = useState(false);


  const hideFormError=()=>{
    window.$(".formChangePassword").hide()
  }
  hideFormError()


  const showInvalideValue=(value,message="Invalide")=>{
    window.$(`#${value}`).html(message).show()
  }
  const hideInvalideValue=(value,mess)=>{
    window.$(`#${value}`).hide()
  }


  const validChangePassword= async()=>{

    try {
      hideFormError()
      setErrorFindPassword(false)
      setErrorMessageFindPassword("")
      setSuccessFindPassword(false)
      setSuccessMessageFindPassword("")
      if(!newPassword){
        showInvalideValue('checkNewPassword','Mot de passe invalide')
        return 
      }

      if(!confNewPassword){
        showInvalideValue('checkConfNewPassword','Mot de passe invalide')
        return 
      }

      if(newPassword!==confNewPassword){
        showInvalideValue('checkNewPassword','Mot de passe invalide')
        showInvalideValue('checkConfNewPassword','Mot de passe invalide')
        return 
      }

      if(!oldPassword){
        showInvalideValue('checkOldpass','Mot de passe invalide')
        return 
      }

      
      let body ={
        idUser,newPass:newPassword,confPass:confNewPassword,oldPass:oldPassword
      }
      const responsePassword = await axios.put(`${urlData()}/user-account/pass`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      console.log("responsePassword")
      console.log(responsePassword)
      console.log("responsePassword")
      if(!responsePassword.data.success){
        setErrorFindPassword(true)
        setErrorMessageFindPassword(responsePassword.data.message)
        setSuccessFindPassword(false)
        setIsLoadingData(false)
      
        return
      }
      setIsLoadingData(false)
      setSuccessMessageFindPassword(responsePassword.data.message)
      setCloseFormPassword(true)
      setSuccessFindPassword(true)
      setTimeout(()=>{
        handleCloseChangePassword()
      },3000)
      refreshData()
      return
    } catch (error) {
      setErrorFindPassword(true)
      setErrorMessageFindPassword("Une erreur interne est survenue.")
    }
    
  }


  const handleCloseChangePassword=()=>{
   
    setConfNewPassword("")
    setOldPassword("")
    setNewPassword('')
    setSentCodeUsername(false)
    setErrorFindPassword(false)
    setErrorMessageFindPassword("")
    setSuccessMessageFindPassword("")
    setSuccessFindPassword(false)
    setCloseFormPassword(false)
    
    window.$("#passwordForm").modal("hide")
    hideFormError()
  }





  return (

    <article className="card mb-4">
        <header className="card-header">
          <strong className="d-inline-block mr-3">MOT DE PASSE</strong>
        </header>
        <div className="card-body">
          <div className="row" > 
            <div className="col-md-8">
              <h6 className="text-muted">Modifier votre mot de passe</h6>
             
              <button type='button' onClick={()=>window.$("#passwordForm").modal("show")} className='btn btn-warning'>Modifier</button>
            </div>
          </div> 
        </div> 
        <div className="modal" id="passwordForm"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">MODIFICATION MOT DE PASSE</h5>
              
            </div>
            <div className="modal-body">
                <h6>Modifier votre mot de passe</h6>
                {errorFindPassword && 
                  <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorMessageFindPassword}</p>
                  </div>
                }
                {successFindPassword && !errorFindPassword &&
                  <div className="alert alert-success mt-3">
                    <p className="icontext"><i className="icon text-success fa fa-check"></i>{successMessageFindPassword}</p>
                  </div>
                }
                
                {
                  !closeFormPassword && 
                  <form>
                    <div className="form-group">
                      <label>Ancien Mot de passe</label>
                      <input type="password" onChange={(e)=>setOldPassword(e.target.value)} className="form-control" value={oldPassword} />
                      <span className='text-danger formChangePassword'id="checkOldpass"></span>
                    </div>
                    <div className="form-group">
                      <label>Nouveau mot de passe</label>
                      <input type="email" onChange={(e)=>setNewPassword(e.target.value)} className="form-control" value={newPassword}/>
                      <span className='text-danger formChangePassword'  id="checkNewPassword"></span>
                    </div>
                    <div className="form-group">
                      <label>Confirmation mot de passe</label>
                      <input type="email" onChange={(e)=>setConfNewPassword(e.target.value)} className="form-control" value={confNewPassword}/>
                      <span className='text-danger formChangePassword' id="checkConfNewPassword"></span>
                    </div>
                    
                    
                   
                    
                   
                  </form>
                }
                
            </div>
            {
                  !closeFormPassword &&
                  <div className="modal-footer">
                    { !newPassword ||  !oldPassword || !confNewPassword && 
                      <button type="button" title='Vous devez entrer les informations' disabled className="btn btn-success">Valider</button>
                    }
      
                    { newPassword &&  oldPassword && confNewPassword && 
                      <button type="button" className="btn btn-success" onClick={validChangePassword}>Valider</button>
                    }
                    <button type="button" onClick={handleCloseChangePassword} className="btn btn-danger " >
                        Annuler
                    </button>
                  
                </div>
            }
           
          </div>
        </div>
      </div>
      </article> 

    
  )
}

export default PasswordChange