import React,{useState,useRef } from 'react'
import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import {urlData} from '../../services/url';
import './Modal.css'


function 
Modal() {

    const [showModal, setShowModal] = useState(true);
    const setPostCode= useStoreActions((state)=>state.setPostCode)
    const setTownName= useStoreActions((state)=>state.setTownName)
    const [loadingResultTowns, setLoadingResultTowns] = useState(false)
    const [resultTowns, setResultTowns] = useState([])
    const [pickTowns, setPickTowns] = useState()
    const [pickNameTowns, setPickNameTowns] = useState()
    const [errorFindResultTowns, setFindErrorResultTowns] = useState(false)
    const [errorResultTowns, setErrorResultTowns] = useState("")
    const getPostCode=useRef()
  
    const handlePostCodeFindTowns=()=>{
      
      if(getPostCode.current.value.length===5){
          getTownsFromPostCode()
      }else{
        setResultTowns([])
        setPickTowns(null)
        setPickNameTowns(null)
        setFindErrorResultTowns(false);
        setErrorResultTowns("")
      }
    }
  
    const getTownsFromPostCode=async ()=>{
      try {
        setLoadingResultTowns(true)
        const response = await axios.get(`${urlData()}/find-postcode/${getPostCode.current.value}`);
        console.log('response')
        console.log(response)
        if(response.data.success) {
           setLoadingResultTowns(false)
          setFindErrorResultTowns(false);
          setErrorResultTowns("")
          setPickTowns(response.data.allInfos[0].id_france)
          setPickNameTowns(response.data.allInfos[0].commune_france)
          setResultTowns(response.data.allInfos);
          
        }else{
           setLoadingResultTowns(false)
          console.log("findErrors in ")
          setFindErrorResultTowns(true);
          setErrorResultTowns(response.data.message)
        }
      } catch (error) {
        setLoadingResultTowns(false)
        setFindErrorResultTowns(true);
        setErrorResultTowns("Une erreur interne est survenue (Information code postal)")
      }
      
    }
  
    const validPostCode=()=>{
      if(!pickTowns) return;
      setPostCode(pickTowns)
      setTownName(pickNameTowns)
      window.$("#postCode").modal("hide")
    }
    
  return (
    <div className={showModal ? "modal-container" : "hidden"}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">INFORMATION CODE POSTAL</h5>
          <h6>Entrer le code postal</h6>
          <input type="number" max="5" onChange={handlePostCodeFindTowns} className="form-control cpInput" ref={getPostCode} />
              <hr/>
              { loadingResultTowns && <p>Recherche......</p>}
              { resultTowns.length>0 &&
                <div>
                  <h6>Selectionner une ville:</h6>
                  {resultTowns.map((e,index)=>{
                    return(
                        <button key={index} type="button" className={pickTowns===e.id_france?"btn btn-secondary":"btn btn-light"} style={{marginBottom:"5px",marginRight:"3px"}}
                         onClick={()=>setPickTowns(e.id_france)}>{e.commune_france}</button>
                    )
                    
                  })}
                  
                </div>
              }
              {errorFindResultTowns && 
                <div className="alert alert-danger mt-3">
                  <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorResultTowns}</p>
                </div>
              }
        </div>
        <div className="card-footer text-right">
          { resultTowns.length===0 &&
            <button type="button" title='Vous devez sélectionner une ville' disabled className="btn btn-success">Valider</button>
          }

          { resultTowns.length>0 && pickTowns &&
            <button type="button" className="btn btn-success" onClick={validPostCode}>Valider</button>
          }
        </div>
      </div>
    </div>
  )
}

export default Modal