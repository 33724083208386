import React from 'react'
import { Link } from 'react-router-dom';
import { commaToDot, random_sort } from "../services/helpers";
import ArticleBox from './articleBox/ArticleBox';
import FindPostCode from "./postCode/FindPostCode";
import Template from "./Template";


class FilterTemplate extends React.Component {
    constructor(props) {
      super(props);
        let allProducts=props.allProducts && props.allProducts.length>0?props.allProducts.sort(random_sort):[] 
        let otherProducts=props?.infos?.otherProduct && props.infos?.otherProduct.length>0?props.infos?.otherProduct.sort(random_sort):[] 
      this.state = {nameCategory:'all',priceMin:"",priceMax:"",filterdProducts:allProducts,products:props.allProducts,otherProducts};
    }

    findByPriceMin=(product)=>{
    
        if(product.idBookSizePromo){
          return product.newPricePromo>=commaToDot(this.state.priceMin)
        }else{
          return product.price>=commaToDot(this.state.priceMin)
        }
    }

    findByPriceMax=(product)=>{
        console.log('call max')
        if(product.idBookSizePromo){
            return product.newPricePromo<=commaToDot(this.state.priceMax)
        }else{
            return product.price<=commaToDot(this.state.priceMax)
        }
    
    }

    findByPriceMinMax=(product)=>{
        console.log('call min && max')
        if(product.idBookSizePromo){
          return product.newPricePromo>=commaToDot(this.state.priceMin) && product.newPricePromo<=commaToDot(this.state.priceMax)
        }else{
          return product.price>=commaToDot(this.state.priceMin) && product.price<=commaToDot(this.state.priceMax)
        }
    }

    seeAllArticles=()=>{
        this.setState({nameCategory:"all",filterdProducts:this.state.products,priceMax:"",priceMin:""}) 
    }

    filterSearch= ()=>{
       
        let dataProducts= this.state.products
        if(this.state.nameCategory!=='all'){
          dataProducts=this.state.products.filter(product=>product.nameCatPro === this.state.nameCategory)
        
        }
        if(this.state.priceMin && this.state.priceMax && dataProducts.length>0){
          let filtered= dataProducts.filter(this.findByPriceMinMax)
          filtered=filtered.sort(random_sort)
          this.setState({filterdProducts:filtered})
          return
        }
  
        if(this.state.priceMin && !this.state.priceMax && dataProducts.length>0){
          let filtered= dataProducts.filter(this.findByPriceMin)
          filtered=filtered.sort(random_sort)
          this.setState({filterdProducts:filtered})
          return
        }
  
        if(!this.state.priceMin && this.state.priceMax && dataProducts.length>0){
          let filtered= dataProducts.filter(this.findByPriceMax)
          filtered=filtered.sort(random_sort)
          this.setState({filterdProducts:filtered})
          return
        }
        
        let filtered=this.state.nameCategory!=='all'? this.state.products.filter(e=> e.nameCatPro ===this.state.nameCategory ):this.state.products
        filtered=filtered.sort(random_sort)
        this.setState({filterdProducts:filtered})
    }

   
    render() {
        const { nameCategory, priceMin, priceMax, filterdProducts, products,otherProducts } = this.state;
        const {titleSection,infos,showPrices } = this.props;
      return(
        <>
        <Template>
            <div className="container">
                <section className="section-content padding-y">
                    <div className="container">
                        <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-2"> Vous êtes ici: </div> 
                            <nav className="col-md-8"> 
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={`/`}>
                                    Accueil
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">{titleSection}</li>
                                
                            </ol>  
                            </nav>
                            </div>
                            <hr/>
                            <div className="row">
                            <div className="col-md-2">Filter par</div>
                            <div className="col-md-10"> 
                                <ul className="list-inline">
                                
                                <li className="list-inline-item mr-3 dropdown"><button type="button" className="btn dropdown-toggle" data-toggle="dropdown">   Catégorie </button>
                                        <div className="dropdown-menu p-3" style={{"maxWidth":"400px"}}>
                                        <label className="form-check">
                                            <input type="radio" defaultChecked name="myfilter" onChange={()=>this.setState({nameCategory:"all"})}  className="form-check-input"/> Tous	
                                        </label>
                                        {infos?.catPrimaries?.map((e,index)=>{
                                            return(
                                            <label key={index} className="form-check">
                                                <input key={e} type="radio"  name="myfilter" onChange={()=>{
                                                    this.setState({nameCategory:e.nameCategoryProduct})
                                                }}  className="form-check-input"/><span style={{fontSize:"10px"}}>{e.nameCategoryProduct}</span><br/>
                                                </label>
                                            
                                            )
                                        }
                                            
                                        )}
                                        

                                        </div>
                                    </li>
                                    {
                                    nameCategory!=='all' &&
                                    <li className="list-inline-item mr-3 dropdown">
                                    {nameCategory}
                                    </li>
                                    }
                                    
                                
                                <li className="list-inline-item mr-3">
                                    <div className="form-inline">
                                    <label className="mr-2">Prix</label>
                                    <input className="form-control form-control-sm" onChange={(e)=>this.setState({priceMin:e.target.value})} value={priceMin} placeholder="Min" type="number"/>
                                    <span className="px-2"> - </span>
                                    <input className="form-control form-control-sm" onChange={(e)=>this.setState({priceMax:e.target.value})} value={priceMax} placeholder="Max" type="number"/>
                                    <button type="button"  onClick={()=>this.filterSearch()} className="btn btn-sm btn-light ml-2">Ok</button>
                                </div>
                                </li>
                                
                                </ul>
                            </div>
                            </div> 
                            
                        </div> 
                        </div> 
                    
                
                        <header className="mb-3">
                            <div className="form-inline">
                            <strong className="mr-md-auto">{filterdProducts.length} articles trouvés </strong>
                            </div>
                        </header>
                
                        <div className="row">
                        {filterdProducts?.map((e,index)=>{
                            return(
                                <ArticleBox key={index}  idBookProd={e.idBookProd} image={e.uniqIdDoc} 
                            nameProduct={e.nameProduct} 
                            price={e.price} 
                            showPrices={showPrices} 
                            idBookSizePromo={e.idBookSizePromo}
                            newPricePromo={e.newPricePromo}
                            oldPricePromo={e.oldPricePromo}
                            isFlash={e.isFlash} 
                            articlePromo={e.articlePromo}
                            sellBy={"LNVX"} stock={e.stock}
                            idProduct={e.idProduct}
                            idBookSize={e.idBookSize}
                            article={e}
                            />
                            )
                            }
                        
                        )}
                        </div> 
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <h6>Retrouver tous les produits</h6>
                            </div>
                        {    
                           filterdProducts.length==0 && products && products.length>0 && 
                           products?.map((e,index)=>{
                            return(
                                <ArticleBox key={index}  idBookProd={e.idBookProd} image={e.uniqIdDoc} 
                                nameProduct={e.nameProduct} 
                                price={e.price} 
                                showPrices={showPrices} 
                                idBookSizePromo={e.idBookSizePromo}
                                newPricePromo={e.newPricePromo}
                                oldPricePromo={e.oldPricePromo}
                                isFlash={e.isFlash} 
                                articlePromo={e.articlePromo}
                                sellBy={"LNVX"} stock={e.stock}
                                idProduct={e.idProduct}
                                idBookSize={e.idBookSize}
                                article={e}
                                />
                            )
                            })

                            
                          
                        }
                        {    
                           filterdProducts.length==0 && products && products.length==0 && 
                           otherProducts?.map((e,index)=>{
                            return(
                                <ArticleBox key={index}  idBookProd={e.idBookProd} image={e.uniqIdDoc} 
                                nameProduct={e.nameProduct} 
                                price={e.price} 
                                showPrices={showPrices} 
                                idBookSizePromo={e.idBookSizePromo}
                                newPricePromo={e.newPricePromo}
                                oldPricePromo={e.oldPricePromo}
                                isFlash={e.isFlash} 
                                articlePromo={e.articlePromo}
                                sellBy={"LNVX"} stock={e.stock}
                                idProduct={e.idProduct}
                                idBookSize={e.idBookSize}
                                article={e}
                                />
                            )
                            })

                            
                          
                        }
                        </div>
                
                       
                    </div> 
                </section>
            </div>
            <FindPostCode/>
        </Template>
        </>
        
      )
    }
  }

  export default FilterTemplate