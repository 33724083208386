import React from 'react'

function ArticleSix() {
  return (
    <>
        <h5 class="card-title">Article 6</h5>
        <p>
        Prix Le prix des l’articles présenté sur le site est indiqué en Euro (€) et toutes taxes comprises, hors frais de livraison et de douane. Les prix figurant sur la commande, après validation de celle-ci, sont fermes et définitif. Les prix peuvent être modifiés à tout moment, sans préavis et notamment en cas de changement de données fiscales ou économiques. Les articles seront facturés sur la base des tarifs en vigueur au moment de l’enregistrement de la commande.
        </p>
    </>
  )
}

export default ArticleSix