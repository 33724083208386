import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import {useStoreState } from 'easy-peasy';
import FindPostCode from './components/postCode/FindPostCode';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred';
import Loading from './components/loading/Loading';
import Template from './components/Template';
import { Link } from 'react-router-dom';
import {urlData,urlDataImage} from './services/url';

function Categories() {
  
  const postCode= useStoreState((state)=>state.postCode)
  const [categoriesPage, setCategories] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  
  const linkApiImages=urlDataImage()
  
  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/categories?postCode=${postCode}`);
          
          if(response.data.success) {
            setFetchError(null);
            setCategories(response.data);
            
          }else{
           
            setFetchError(response.data.message);
          }
      } catch (err) {
       
         
          setFetchError(err.message);
      } finally {
        
          setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode])
//SectionTemplate({isLoading,fetchError,titleSection,infos})

  return (
    <div>
      
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError && 
        <Template>
        <section className="section-content padding-y">
          <div className="container">

          <nav className="row">
              {categoriesPage?.catPrimaries.map((data,index)=>{
                return(
                  
                    <div key={index} className="col-md-3">
                      <Link to={`/categories/${data.idCategoryProduct}`} >
                        <div className="card card-category" style={{background:`#${data.colorisCategoryProduct}`}}>
                          <div className="img-wrap" style={{background:`#ffffff`,display:"flex",justifyContent:"center"}} >
                            <img src={`${linkApiImages}${data.uniqIdDoc}`} alt={`Categorie ${data.nameCategoryProduct}`}/>
                          </div>
                          <div className="card-body">
                            <h4 className="card-title text-center">{data.nameCategoryProduct}</h4>
                            <ul className="list-menu text-center">
                              {data.secondaries.map((info,indexSec)=>{
                                  return(
                                    <li key={indexSec}>{info.nameSecondCategoryProduct}</li>
                                  )
                                })

                              }
                            </ul>
                          </div>
                        </div>
                        </Link>
                    </div>
                  
                )
              })

              }
               
             
            </nav> 


          </div> 
          </section>
        <FindPostCode/>
      </Template>
      }
      
    </div>
    
  )
}

export default Categories
