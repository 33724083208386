import React from 'react'

function ArticleNine() {
  return (
    <>
        <h5 class="card-title">Article 9</h5>
        <p>
        Cas de force majeure En cas de survenance d’un cas de force majeure, l’entreprise ne pourra être tenue pour responsable de son incapacité à remplir une partie ou la totalité de ses obligations.
        </p>
    </>
  )
}

export default ArticleNine