import React,{ useEffect,useState,useRef } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { dotToComma, commaToDot } from '../../../../services/helpers'
import Template from '../../../Template'
import { useStoreActions,useStoreState } from 'easy-peasy';
import {urlDataImage,urlData} from '../../../../services/url';

function DeliveryBasket({data,showBasketInfos,showPaymentInfos}) {
  const linkApiImages=urlDataImage()
  const baskets= useStoreState((state)=>state.baskets)
  const postCode= useStoreState((state)=>state.postCode)
  
  const returnTotal=(totalBasket,priceDeliveries,pricePromo)=>{
    let total="Erreur"
    try {
      total= parseFloat(totalBasket)+parseFloat(priceDeliveries)-parseFloat(pricePromo)
      total= total>=0?total:0
      let totalComma= dotToComma(total)
      return {total,totalComma}
    } catch (error) {
      return {total,totalComma:'Erreur'}
    }
  }

  const [ loading, setLoading]= useState(true)
  const [ timePaymentAllowance, setTimePaymentAllowance]= useState(0)
  const [ urlDs, setUrlDs]= useState("")
  
  const [ showCardPayment, setShowCardPayment]= useState(false)
  const [ showRecordPayment, setShowRecordPayment]= useState(false)
  const [ showCancelPayment, setShowCancelPayment]= useState(false)
  
  const [ paymentDatas, setPaymentDatas]= useState({})
  const [ pickDelivery, setPickDelivery]= useState(0)
  const [ pickMondialDelivery, setPickMondialDelivery]= useState(0)
  const [ pickRelayDelivery, setPickRelayDelivery]= useState(0)
  const [ pickDriveDelivery, setPickDriveDelivery]= useState(0)
  const adressesRelayDelivery= data.infosPanier.addressesPartner || []
  const adressesDriveDelivery= data.infosPanier.addressesDrive || []
  const adressesMondialDelivery=data.infosPanier.addressesRelay || []
  const [ showAdressesRelayDelivery, setShowAdressesRelayDelivery]= useState(false)
  const [ showAdressesDriveDelivery, setShowAdressesDriveDelivery]= useState(false)
  const [ showAdressesMondialDelivery, setShowAdressesMondialDelivery]= useState(false)
  const [ nameVoucher, setNameVoucher]= useState("")
  const [ timer, setTimerInfos]= useState(0)

  
  const  [ nameAddressLnvx, setNameAddressLnvx]= useState("")
  const  [ roadAddressLnvx, setRoadAddressLnvx]= useState("")
  const  [ postCodeAddressLnvx, setPostCodeAddressLnvx]= useState(data?.infosPanier?.userLocation?.postCode)
  const  [ townAddressLnvx, setTownAddressLnvx]= useState(data?.infosPanier?.userLocation?.town)
  


  const [ nameAddressDrive, setNameAddressDrive]= useState("")
  const [ roadAddressDrive, setRoadAddressDrive]= useState("")
  const [ postCodeAddressDrive, setPostCodeAddressDrive]= useState("")
  const [ townAddressDrive, setTownAddressDrive]= useState("")


  const [ nameAddressPartner, setNameAddressPartner]= useState(adressesRelayDelivery[0]?.namePartner || "")
  const [ roadAddressPartner, setRoadAddressPartner]= useState(adressesRelayDelivery[0]?.roadPartnerAddress || "")
  const [ postCodeAddressPartner, setPostCodeAddressPartner]= useState(adressesRelayDelivery[0]?.postcodePartnerAddress ||"")
  const [ townAddressPartner, setTownAddressPartner]= useState(adressesRelayDelivery[0]?.townPartnerAddress ||"")

  const [ nameAddressExternal, setNameAddressExternal] = useState("")
  const [ roadAddressExternal, setRoadAddressExternal] = useState("")
  const [ postCodeAddressExternal, setPostCodeAddressExternal] = useState("")
  const [ townAddressExternal, setTownAddressExternal] = useState("")

  const  telephone= useRef("")
  const  emailCustomer= useRef("")
  const  surnameCustomer= useRef("")
  const  firstnameCustomer= useRef("")

  const [ nameAddress, setNameAddress]= useState("")
  const [ roadAddress, setRoadAddress]= useState("")
  const [ postCodeAddress, setPostCodeAddress]= useState("")
  const [ townAddress, setTownAddress]= useState("")

  //payment data
  const [ typePayment, setTypePayment]= useState(1)
  const [ idDrive, setIdDrive]= useState(0)
  const [ idRelay, setIdRelay]= useState(0)
  const [ idCarrier, setIdCarrier]= useState(0)
  const [ typeDelivery, setTypeDelivery]= useState(data?.deliveries[0].typeDelivery)
  const [ priceDelivery, setPriceDelivery]= useState(data?.deliveries[0].price)
  const [ totalPay, setTotalPay]= useState(returnTotal(data?.infosPanier?.payInfos?.toPay,data?.deliveries[pickDelivery].price,0).totalComma)
  const totalPayInfos= useRef(returnTotal(data?.infosPanier?.payInfos?.toPay,data?.deliveries[pickDelivery].price,0).total)
  const [ totalBasket, setTotalBasket]= useState(data?.infosPanier?.payInfos?.toPay)
  const [ idPromo, setIdPromo]= useState(0)
  const [ pricePromo, setPricePromo]= useState(0)
  const [ messageError, setMessageError]= useState("")
  


  useEffect(()=>{
    if(typeDelivery===4) getAddressesExternal();
  },[])




  const setAddressCustomer=(nameAdress,road,postcode,town)=>{
    setNameAddress(nameAdress)
    setRoadAddress(road)
    setPostCodeAddress(postcode)
    setTownAddress(town)
  }

 

  const chekInfosCustomer=()=>{
    if(!emailCustomer.current.value ){
      return {success:false,message:"L'email est invalide"}
    }
    if(!surnameCustomer.current.value){
      return {success:false,message:"Le nom de client est invalide"}
    }
    if(!firstnameCustomer.current.value){
      return {success:false,message:"Le prénom de client est invalide"}
    }
    if(!telephone.current.value){
      return {success:false,message:"Le numéro de téléphone est invalide"}
    }

    return {success:true}
  }

  const getAddressData=()=>{
    
    switch (typeDelivery) {
      case 1:
        return checkAddressLnvx()
      case 2:
        return checkAddressDrive()
      case 3:
        return checkAddressPartner()
      case 4:
        return checkAddressExternal()
      default:
        return {success:false,message:'Erreur, adresse invalide'};
    }
  }
  
  const checkAddressLnvx=()=>{
    if(!nameAddressLnvx){
      return {success:false,message:"le nom de l'adresse est invalide"}
    }
    if( !roadAddressLnvx ){
      return {success:false,message:"la rue de l'adresse est invalide"}
    }
    if(!postCodeAddressLnvx){
      return {success:false,message:"le code postal de l'adresse est invalide"}
    }
    if( !townAddressLnvx){
      return {success:false,message:"la ville de l'adresse est invalide"}
    }
    
    setAddressCustomer(nameAddressLnvx,roadAddressLnvx,postCodeAddressLnvx,townAddressLnvx)
    return {success:true,
      nameAddress:nameAddressLnvx,
      roadAddress:roadAddressLnvx,
      postCodeAddress:postCodeAddressLnvx,
      townAddress:townAddressLnvx
    }
  }

  const checkAddressDrive=()=>{
    if(!nameAddressDrive){
      return {success:false,message:"le nom de l'adresse est invalide"}
    }
    if( !roadAddressDrive ){
      return {success:false,message:"la rue de l'adresse est invalide"}
    }
    if(!postCodeAddressDrive ){
      return {success:false,message:"le code postal de l'adresse est invalide"}
    }
    if( !townAddressDrive){
      return {success:false,message:"la ville de l'adresse est invalide"}
    }
    setAddressCustomer(nameAddressDrive,roadAddressDrive,postCodeAddressDrive,townAddressDrive)
    return {success:true,
      nameAddress:nameAddressDrive,
      roadAddress:roadAddressDrive,
      postCodeAddress:postCodeAddressDrive,
      townAddress:townAddressDrive
    }
  }


  const checkAddressPartner=()=>{
    if(!nameAddressPartner){
      return {success:false,message:"le nom de l'adresse est invalide"}
    }
    if( !roadAddressPartner ){
      return {success:false,message:"la rue de l'adresse est invalide"}
    }
    if(!postCodeAddressPartner ){
      return {success:false,message:"le code postal de l'adresse est invalide"}
    }
    if( !townAddressPartner){
      return {success:false,message:"la ville de l'adresse est invalide"}
    }
    
    setAddressCustomer(adressesRelayDelivery[pickRelayDelivery].namePartner,adressesRelayDelivery[pickRelayDelivery].roadPartnerAddress,
      adressesRelayDelivery[pickRelayDelivery].postcodePartnerAddress,adressesRelayDelivery[pickRelayDelivery].townPartnerAddress)
    return {success:true,
      nameAddress:adressesRelayDelivery[pickRelayDelivery].namePartner,
      roadAddress:adressesRelayDelivery[pickRelayDelivery].roadPartnerAddress,
      postCodeAddress:adressesRelayDelivery[pickRelayDelivery].postcodePartnerAddress,
      townAddress:adressesRelayDelivery[pickRelayDelivery].townPartnerAddress}
  }

  const checkAddressExternal=()=>{
    if(!nameAddressExternal){
      return {success:false,message:"le nom de l'adresse est invalide"}
    }
    if(!roadAddressExternal ){
      return {success:false,message:"la rue de l'adresse est invalide"}
    }
    if(!postCodeAddressExternal ){
      return {success:false,message:"le code postal de l'adresse est invalide"}
    }
    if(!townAddressExternal){
      return {success:false,message:"la ville de l'adresse est invalide"}
    }
    setAddressCustomer(nameAddressExternal,roadAddressExternal,postCodeAddressExternal,townAddressExternal)
    return {success:true,
      nameAddress:adressesMondialDelivery[pickMondialDelivery].nomMag,
      roadAddress:adressesMondialDelivery[pickMondialDelivery].rue,
      postCodeAddress:adressesMondialDelivery[pickMondialDelivery].codePostal,
      townAddress:adressesMondialDelivery[pickMondialDelivery].ville
    }
  }



  const findExternalAddress=(pickMondialDelivery)=>{
    let newPick=pickMondialDelivery+1
    if(newPick<adressesMondialDelivery.length){
      setPickMondialDelivery(pickMondialDelivery+1)
      setAddressesExternal()
    }
    
  }

  const setInfosTypeDelivery=(typeDelivery,index)=>{
    setTypeDelivery(typeDelivery)
    setPriceDelivery(data?.deliveries[index].price)
    switch (typeDelivery) {
      case 1:
        setTotalPay(returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).totalComma)
        totalPayInfos.current= returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).total
        break;
      case 2:
       
        setTotalPay(returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).totalComma)
        totalPayInfos.current= returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).total
       
        break;
      case 3:
        handleChangePartnerAddress(pickRelayDelivery)
        setTotalPay(returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).totalComma)
        totalPayInfos.current= returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).total
        
        break;
      case 4:
        getAddressesExternal()
        setTotalPay(returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).totalComma)
        totalPayInfos.current= returnTotal(totalBasket,data?.deliveries[index].price,pricePromo).total
        
        break;
      default:
        break;
    }
  }

  const setAddressesExternal=()=>{

    if(adressesMondialDelivery.length>0){
      let postCode=adressesMondialDelivery[pickMondialDelivery].codePostal
      let cpVille=adressesMondialDelivery[pickMondialDelivery].cpVille
      if(cpVille!=="Indisponible" || postCode!=="Indisponible"){
        getAddressesExternal()
      }else{
        findExternalAddress(pickMondialDelivery)
      }
    }
  
  }

  const getAddressesExternal=(choosePick)=>{
      let getPick= choosePick?choosePick:pickMondialDelivery
      let name=adressesMondialDelivery[getPick].nomMag
      let road=adressesMondialDelivery[getPick].rue
      let postCode=adressesMondialDelivery[getPick].codePostal
      let town=adressesMondialDelivery[getPick].ville
      let cpVille=adressesMondialDelivery[getPick].cpVille
      
      console.log(`name:${name},road:${road},postCode:${postCode},town:${town}`)
      setNameAddressExternal(name)    
      setRoadAddressExternal(road)
      setPostCodeAddressExternal(postCode)
      setTownAddressExternal(town)
      
      return {name,road,postCode,town}
      
       
  
  }

  

//voucher-visitor



  const promoFetch= async (e)=>{
    e.preventDefault()
    try {
      if(!nameVoucher ){
        //call modal error
        return;
      }
      
      let payload={baskets,nameVoucher}
      const source = axios.CancelToken.source();
      payload.cancelToken=source.token
      const response = await axios.post(`${urlData()}/voucher-visitor`,payload,{header:{"Content-Type":"application/json"}});
      if(!response.data.success){
        setMessageError(`${response.data.message}`)
        return window.$("#errorMess").modal("show")
      }
      
      setIdPromo(response.data.idPromo)
      setPricePromo(response.data.amountPromo);
      setTotalPay(returnTotal(totalBasket,priceDelivery,response.data.amountPromo).totalComma)
      totalPayInfos.current= returnTotal(totalBasket,priceDelivery,response.data.amountPromo).total
            
      
    } catch (error) {
      //call modal error
    }
   
    
  }

  const handleChangeMondial=(pickAddressMondial)=>{
    setPickMondialDelivery(pickAddressMondial)
    setNameAddressExternal(adressesMondialDelivery[pickAddressMondial].nomMag)
    setRoadAddressExternal(adressesMondialDelivery[pickAddressMondial].rue)
    setPostCodeAddressExternal(adressesMondialDelivery[pickAddressMondial].codePostal)
    setTownAddressExternal( adressesMondialDelivery[pickAddressMondial].ville)
    return window.$("#showAddressExternal").modal("hide") 
  }

   function handleChangePartnerAddress(pickRelayDelivery){
    setPickRelayDelivery(pickRelayDelivery)
 
    setNameAddressPartner(adressesRelayDelivery[pickRelayDelivery].namePartner)
    setRoadAddressPartner(adressesRelayDelivery[pickRelayDelivery].roadPartnerAddress)
    setPostCodeAddressPartner(adressesRelayDelivery[pickRelayDelivery].postcodePartnerAddress)
    setTownAddressPartner( adressesRelayDelivery[pickRelayDelivery].townPartnerAddress)
    return window.$("#showAddressPartner").modal("hide") 

  }

  const deletePromo=()=>{
    setIdPromo(0)
    setPricePromo(0);
    setNameVoucher("");
    setTotalPay(returnTotal(totalBasket,priceDelivery,0).totalComma)
    totalPayInfos.current= returnTotal(totalBasket,priceDelivery,0).total
  }

  const goToPayment=async ()=>{
    
    if(!getAddressData().success){
      setMessageError(`${getAddressData().message}`)
        return window.$("#errorMess").modal("show")
     }
    if(!chekInfosCustomer().success){
      setMessageError(`${chekInfosCustomer().message}`)
      return window.$("#errorMess").modal("show")
      
    }
    if(totalPayInfos===null || totalPayInfos===''){
      setMessageError(`Erreur prix de vente`)
      return window.$("#errorMess").modal("show")
    }

    let payload={
      basketData:baskets,
      codePostal:getAddressData().postCodeAddress,
      contact:telephone.current.value,
      email:emailCustomer.current.value,
      firstname:firstnameCustomer.current.value,
      idPromoData:idPromo,
      nameAddress:getAddressData().nameAddress,
      postCode,
      priceDelivery,
      rue:getAddressData().roadAddress,
      surname:surnameCustomer.current.value,
      totalPromo:pricePromo,
      typeDelivery,
      totalBasket:totalBasket,
      totalPay:commaToDot(totalPay),
      ville:getAddressData().townAddress,
    
      }
      
      
      const response = await axios.post(`${urlData()}/basket-buying-card-visitor`,payload,{header:{"Content-Type":"application/json"}});
      if(!response.data.success){
        setMessageError(`${response.data.message}`)
        return window.$("#errorMess").modal("show")
      }
      let urlViva=response.data.urlDs
      let timeOver=response.data.timePaymentAllowance
      let orderCode=response.data.orderCode
      let payloadDataPayment={username:emailCustomer.current.value,orderCode,timeOver,urlViva}
      showPaymentInfos(payloadDataPayment)
      
  }

  

  return (
    <Template>
        <section className="section-content padding-y">
          <div className="container">
            <div className="row">
              <main className="col-md-9">
                <div className="card-body border-top mb-4">
                    <button type="button" onClick={showBasketInfos}  className="btn btn-light float-md-left"><i className="fa fa-chevron-left"></i>  Retourner au panier </button>
                </div>	
                <div className="card mb-4">
                  <div className="card-body">
                    <h4 className="card-title mb-3">Mode de livraison</h4>
                    <div className="form-row">
                      {data.deliveries?.map((e,index)=>{
                        if(index==0){
                          return (
                            <div key={index}  className="form-group col-sm-6 col-md-2"  style={{"minWidth":"240px"}}>
                            <label className="js-check box active">
                              <input type="radio" name="typeDelivery" onClick={()=>setInfosTypeDelivery(e.typeDelivery,index)} defaultValue={e.typeDelivery} defaultChecked/>
                              <h6 className="title">{e.nameCarrier}</h6>
                              <p className="text-muted">{dotToComma(e.price)}€<br/>Livraison le: {e.dateDelivery}</p>
                              
                            </label> 
                          </div>
                          )
                        }else{
                          return (
                            <div key={index}  className="form-group col-sm-6 col-md-2" style={{"minWidth":"240px"}}>
                            <label className="js-check box active">
                              <input type="radio" name="typeDelivery" onClick={()=>setInfosTypeDelivery(e.typeDelivery,index)} defaultValue={e.typeDelivery} />
                              <h6 className="title">{e.nameCarrier}</h6>
                              <p className="text-muted">{dotToComma(e.price)}€<br/>Livraison le: {e.dateDelivery}</p>
                              
                            </label> 
                          </div>
                          )
                        }
                        
                      })}
                      
                    </div> 
                  </div>
                  
                    
                </div> 
                {typeDelivery===1 &&
                  <div className="card mb-4">
                    <div className="card-body">
                    <h4 className="card-title mb-3">Adresse de livraison</h4>
                    <div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Nom</label>
                          <input type="text"  value={nameAddressLnvx} onChange={(e)=>setNameAddressLnvx(e.target.value)} className="form-control"/>
                        </div> 
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Adresse</label>
                            <input type="text" value={roadAddressLnvx} onChange={(e)=>setRoadAddressLnvx(e.target.value)}  className="form-control" />
                        </div> 
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Code postal</label>
                            <input type="text" disabled value={postCodeAddressLnvx}  className="form-control" />
                        </div> 
                        <div className="col form-group">
                          <label>Ville</label>
                            <input type="text" disabled value={townAddressLnvx}  className="form-control"/>
                        </div> 
                      </div>
                      
                    </div>
                    
                    
                    </div>
                  </div>
                }

                {typeDelivery===2 &&
                  <div className="card mb-4">
                    <div className="card-body">
                    <h4 className="card-title mb-3">Adresse de livraison Drive</h4>
                    <div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Nom</label>
                          <input type="text" readOnly onChange={e=>setNameAddressDrive(e.target.value)} value={nameAddressDrive} className="form-control"/>
                        </div> 
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Adresse</label>
                            <input type="text" readOnly  onChange={e=>setRoadAddressDrive(e.target.value)} value={roadAddressDrive} className="form-control" />
                        </div> 
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Code postal</label>
                            <input type="text" readOnly  onChange={e=>setPostCodeAddressDrive(e.target.value)} value={postCodeAddressDrive} className="form-control" />
                        </div> 
                        <div className="col form-group">
                          <label>Ville</label>
                            <input type="text" readOnly  onChange={e=>setTownAddressDrive(e.target.value)} value={townAddressDrive} className="form-control"/>
                        </div> 
                      </div>
                      <div className="form-row text-center">
                        <div className="col-md-3 form-group">
                          
                          </div> 
                        <div className="col-offset-md-3 col-md-6 text-center form-group">
                          <div className="col-md-3 form-group"></div> 
                          <div className="col-offset-md-3 col-md-6 text-center form-group">
                            {!showAdressesDriveDelivery?
                              <button type="button" onClick={()=>setShowAdressesDriveDelivery(!showAdressesDriveDelivery)}  className="btn btn-block btn-success text-center"> CHANGEMENT ADRESSE  </button>
                            :
                              <button type="button" onClick={()=>setShowAdressesDriveDelivery(!showAdressesDriveDelivery)}  className="btn btn-block btn-warning text-center"> Fermer Liste  </button>
                            }
                          </div> 
                        </div> 
                      </div>
                      
                    </div>
                    
                    
                    </div>
                  </div>
                }

                {typeDelivery===2 && showAdressesDriveDelivery &&
                  <div className="card mb-4">
                    <div className="card-body">
                      <h4 className="card-title mb-3">Adresses Drive</h4>
                      <div className="table-wrapper">
                          <div className="table-scroll">
                            <table className="table text-center" >
                                <thead>
                                  <tr>
                                    <th>Adresse</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                    {data.adressesDrive?.map((e,index)=>{
                                      return (
                                        <tr key={index}>
                                          <td>
                                            {e.nameDrive} {e.numberDriveAddress} {e.roadDriveAddress} {e.postcodeDriveAddress} {e.townDriveAddress}
                                          </td>
                                          <td>{pickDriveDelivery!==index?
                                                <button type="button" onClick={()=>setPickDriveDelivery(index)} className="btn btn-success">Choisir</button>
                                                :
                                                <button type="button" disabled className="btn btn-warning">Sélectionné</button>
                                              }
                                          </td>

                                          
                                          
                                            
                                        </tr>
                                      )
                                    })}
                                    
                                  
                                </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                  </div>
                }

                {typeDelivery===3 &&
                  <div className="card mb-4">
                    <div className="card-body">
                      <h4 className="card-title mb-3">Adresse de livraison Relais</h4>
                      
                        <div className="form-row">
                          <div className="col form-group">
                            <label>Nom</label>
                            <input type="text" readOnly  onChange={e=>setNameAddressPartner(prevState => ({...prevState, [e.target.name]: e.target.value}))} value={nameAddressPartner}  className="form-control"/>
                          </div> 
                        </div>
                        <div className="form-row">
                          <div className="col form-group">
                            <label>Adresse</label>
                              <input type="text"   readOnly onChange={e=>setRoadAddressPartner(e.target.value)} value={roadAddressPartner} className="form-control" />
                          </div> 
                        </div>
                        <div className="form-row">
                          <div className="col form-group">
                            <label>Code postal</label>
                              <input type="text"   readOnly  onChange={e=>setPostCodeAddressPartner(e.target.value)} value={postCodeAddressPartner} className="form-control" />
                          </div> 
                          <div className="col form-group">
                            <label>Ville</label>
                              <input type="text"   readOnly onChange={e=>setTownAddressPartner(e.target.value)} value={townAddressPartner} className="form-control"/>
                          </div> 
                        </div>
                        <div className="form-row text-center">
                          <div className="col-md-3 form-group"></div> 
                          <div className="col-offset-md-3 col-md-6 text-center form-group">
                            {adressesRelayDelivery.length>0 &&
                             
                              <button type="button" onClick={()=>{ 
                                window.$("#showAddressPartner").modal("show")}}  className="btn btn-block btn-success text-center"> CHANGEMENT ADRESSE  </button>
                            
                            }
                          </div> 
                        </div>
                        
                    </div>
                  </div>
                }  

                {typeDelivery===3 && showAdressesRelayDelivery &&
                  <div className="card mb-4">
                    <div className="card-body">
                      <h4 className="card-title mb-3">Adresses Relais</h4>
                      <div className="table-wrapper">
                          <div className="table-scroll">
                            <table className="table text-center" >
                                <thead>
                                  <tr>
                                    <th>Adresse</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                    {adressesRelayDelivery?.map((e,index)=>{
                                      return (
                                        <tr key={index}>
                                          <td>
                                            {e.namePartner} {e.numberPartnerAddress} {e.roadPartnerAddress} {e.postcodePartnerAddress} {e.townPartnerAddress}
                                          </td>
                                          <td>{pickRelayDelivery!==index?
                                                <button type="button" onClick={()=>setPickRelayDelivery(index)} className="btn btn-success">Choisir</button>
                                                :
                                                <button type="button" disabled className="btn btn-warning">Sélectionné</button>
                                              }
                                          </td>

                                          
                                          
                                            
                                        </tr>
                                      )
                                    })}
                                    
                                  
                                </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                  </div>
                }

                {typeDelivery===4 &&
                  <div className="card mb-4">
                    <div className="card-body">
                    <h4 className="card-title mb-3">Adresse de livraison Mondial Relay</h4>
                    <div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Nom</label>
        
                          <input type="text" readOnly onChange={e=>setNameAddressExternal(e.target.value)} value={nameAddressExternal}  className="form-control"/>
                        </div> 
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Adresse</label>
                            <input type="text" readOnly onChange={e=>setRoadAddressExternal(e.target.value)} value={roadAddressExternal} className="form-control" />
                        </div> 
                      </div>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Code postal</label>
                            <input type="text" readOnly onChange={e=>setPostCodeAddressExternal(e.target.value)} value={postCodeAddressExternal} className="form-control" />
                        </div> 
                        <div className="col form-group">
                          <label>Ville</label>
                            <input type="text"  readOnly onChange={e=>setTownAddressExternal(e.target.value)} value={townAddressExternal}  className="form-control"/>
                        </div> 
                      </div>
                      
                      <div className="form-row text-center">
                          <div className="col-md-3 form-group"></div> 
                          <div className="col-offset-md-3 col-md-6 text-center form-group">
                            {adressesMondialDelivery.length>0 &&
                              
                              <button type="button" onClick={()=>{ 
                                window.$("#showAddressExternal").modal("show")}}  className="btn btn-block btn-success text-center"> CHANGEMENT ADRESSE  </button>
                            
                            }
                           
                          </div> 
                        </div>
                      
                    </div>
                    
                    
                    </div>
                  </div>
                } 

                {typeDelivery===4 && showAdressesMondialDelivery &&
                  <div className="card mb-4">
                    <div className="card-body">
                      <h4 className="card-title mb-3">Adresses Mondial Relay</h4>
                        <div className="table-wrapper">
                          <div className="table-scroll">
                            <table className="table text-center" >
                                <thead>
                                  <tr>
                                    <th>Adresse</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                    {adressesMondialDelivery?.map((e,index)=>{
                                      if(e.disponibilite==true && e.codePostal!="Indisponible" || e.cpVille!="Indisponible" ){
                                        if(index==pickMondialDelivery){
                                          return (
                                            <tr key={index}>
                                              <td>
                                                  {e.nomMag}<br/>
                                                  {e.rue}<br/>
                                                  {e.codePostal} {e.ville}
                                              </td>
                                              <td><button type="button" disabled className="btn btn-warning">Sélectionné</button></td>
                                            </tr>
                                          )
                                        }else{
                                          return (
                                            <tr key={index}>
                                              <td>
                                                  {e.nomMag}<br/>
                                                  {e.rue}<br/>
                                                  {e.codePostal} {e.ville}
                                              </td>
                                              <td><button type="button" onClick={()=>handleChangeMondial(index)}  className="btn btn-success">Choisir</button></td>
                                            </tr>
                                          )
                                        }
                                      }
                                    })}
                                    
                                  
                                </tbody>
                            </table>
                          </div>  
                        </div>
                        
                    </div>
                  </div>
                }


                <div className="card">
                  <div className="card-body">
                  <h4 className="card-title mb-3">Client</h4>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Email</label>
                          <input type="email" ref={emailCustomer} required className="form-control" />
                      </div>
                      <div className="col form-group">
                        <label>Téléphone</label>
                          <input type="text" ref={telephone} required className="form-control" />
                      </div> 
                    </div>
                     <div className="form-row">
                      <div className="col form-group">
                        <label>Nom</label>
                          <input type="text" ref={surnameCustomer} required className="form-control" />
                      </div>
                      <div className="col form-group">
                        <label>Prénom</label>
                          <input type="text" ref={firstnameCustomer} required className="form-control" />
                      </div>
                     </div>
                     
                     
                  </div>
                  
                    
                </div> 
                
  
                <div className="alert alert-success mt-3">
                  <p className="icontext"><i className="icon text-success fa fa-truck"></i> Livraison rapide</p>
                </div>
  
              </main> 
              <aside className="col-md-3">
                <div className="card mb-3">
                  <div className="card-body">
                  {pricePromo===0 &&
                    <form>
                      <div className="form-group">
                        <label>Code Promo?</label>
                        <div className="input-group">
                          <input type="text" className="form-control" onChange={e=>setNameVoucher(e.target.value)} name="voucher" placeholder="Coupon code"/>
                          <span className="input-group-append"> 
                            <button onClick={promoFetch}  className="btn btn-secondary">Appliquer</button>
                          </span>
                        </div>
                      </div>
                    </form>
                  }
                   {pricePromo>0 &&
                    <form>
                      <div className="form-group">
                        <label>Code Promo</label>
                        <div className="input-group">
                          <p>{nameVoucher.toUpperCase()} (-{dotToComma(pricePromo)}€)</p>
                          <span className="input-group-append"> 
                            <button onClick={deletePromo}  className="btn btn-danger">Supprimer le code</button>
                          </span>
                        </div>
                      </div>
                    </form>
                  }
                  </div> 
                </div> 
                <div className="card">
                  <div className="card-body">
                      <dl className="dlist-align">
                        <dt>Total Panier:</dt>
                        <dd className="text-right  h5"><strong>{dotToComma(data?.infosPanier?.payInfos?.toPay)}€</strong></dd>
                      </dl>
                      <dl className="dlist-align">
                        <dt>Livraison:</dt>
                        <dd className="text-right  h5"><strong>{dotToComma(priceDelivery)}€</strong></dd>
                      </dl>
                      <dl className="dlist-align">
                        <dt>Promo:</dt>
                        <dd className="text-right  h5"><strong>{pricePromo>0?`-${dotToComma(pricePromo)}`:pricePromo}€</strong></dd>
                      </dl>
                      <dl className="dlist-align">
                        <dt>Total:</dt>
                        <dd className="text-right  h5"><strong>{totalPay}€</strong></dd>
                      </dl>
                      <hr/>
                      <p className="text-center mb-3">
                        <img src="images/misc/payments.png" height="26"/>
                      </p>
                      <button type="button" onClick={goToPayment} className="btn btn-block btn-secondary text-center"> Valider  </button>
                      
                  </div> 
                </div>  
              </aside> 
            </div>
          </div> 
        </section>

        
        <div className="modal" id="mondialModal"  tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Adresses Mondial relay</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table table-bordered text-center">
                  <tbody>
                  <tr>
                    <th>Adresse</th>
                    <th></th>
                  </tr>
                  {data.infosPanier.addressesRelay?.map((e,index)=>{
                    if(e.disponibilite==true && e.codePostal!="Indisponible" || e.cpVille!="Indisponible" ){
                      if(index==pickMondialDelivery){
                        return (
                          <tr key={index}>
                            <td>
                                {e.nomMag}<br/>
                                {e.rue}<br/>
                                {e.codePostal} {e.ville}
                            </td>
                            <td><button type="button" disabled className="btn btn-secondary">Sélectionné</button></td>
                          </tr>
                        )
                      }else{
                        return (
                          <tr key={index}>
                            <td>
                                {e.nomMag}<br/>
                                {e.rue}<br/>
                                {e.codePostal} {e.ville}
                            </td>
                            <td><button type="button" className="btn btn-success">Choisir</button></td>
                          </tr>
                        )
                      }
                    }
                    
                    
                  })}
                  </tbody>
                 
                  
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="errorMess"  tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Erreur Message</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <h4>Erreur Validation</h4>
                  <p style={{color:'red'}}>{messageError}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="timer"  data-keyboard="false" data-backdrop="static" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Erreur Message</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <h1>{timer}</h1>
                 
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="showAddressPartner"  tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">RELAIS</h5>
              </div>
              <div className="modal-body">
                <h6>CHANGEMENT ADRESSE</h6>
                <table className="table text-center" >
                  <thead>
                    <tr>
                      <th>Adresse</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                      {adressesRelayDelivery?.map((e,index)=>{
                        return (
                          <tr key={index}>
                            <td>
                              <span className="text-dark">{e.namePartner}</span><br/> {e.numberPartnerAddress} {e.roadPartnerAddress}<br/> {e.postcodePartnerAddress} {e.townPartnerAddress}
                            </td>
                            <td>{pickRelayDelivery!==index?
                                  <button type="button" onClick={()=>handleChangePartnerAddress(index)} className="btn btn-success">Choisir</button>
                                  :
                                  <button type="button" disabled className="btn btn-warning">Sélectionné</button>
                                }
                            </td>

                            
                            
                              
                          </tr>
                        )
                      })}
                      
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="showAddressDrive"  tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">DRIVE</h5>
              </div>
              <div className="modal-body">
                <h6>CHANGEMENT ADRESSE</h6>
                <table className="table text-center" >
                  <thead>
                    <tr>
                      <th>Adresse</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                      {adressesDriveDelivery?.map((e,index)=>{
                        return (
                          <tr key={index}>
                            <td>
                              {e.nameDrive}<br/> {e.numberDriveAddress} {e.roadDriveAddress} <br/> {e.postcodeDriveAddress} {e.townDriveAddress}
                            </td>
                            <td>{pickDriveDelivery!==index?
                                  <button type="button" onClick={()=>setPickDriveDelivery(index)} className="btn btn-success">Choisir</button>
                                  :
                                  <button type="button" disabled className="btn btn-warning">Sélectionné</button>
                                }
                            </td>

                            
                            
                              
                          </tr>
                        )
                      })}
                      
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="showAddressExternal"  tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">MONDIAL RELAY</h5>
              </div>
              <div className="modal-body">
                <h6>CHANGEMENT ADRESSE </h6> <button className='btn btn-warning' onClick={()=>window.$("#showAddressExternal").modal("hide")}>Fermer la liste</button>
                <table className="table text-center" >
                  <thead>
                    <tr>
                      <th>Adresse</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                      {adressesMondialDelivery?.map((e,index)=>{
                        return (
                          <tr key={index}>
                            <td>
                                {e.nomMag}<br/>
                                {e.rue}<br/>
                                {e.codePostal} {e.ville}
                              
                            </td>
                            <td>{pickMondialDelivery!==index?
                                  <button type="button" onClick={()=>handleChangeMondial(index)} className="btn btn-success">Choisir</button>
                                  :
                                  <button type="button" disabled className="btn btn-warning">Sélectionné</button>
                                }
                            </td>
                          </tr>
                        )
                      })}
                      
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Template>
  )
}

export default DeliveryBasket 
