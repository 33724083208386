import { createStore, action, thunk, computed } from 'easy-peasy';
import api from './api/server'
//25597
export default createStore({
  postCode:getInfosStorage('postCode','','string'),
  setPostCode:action((state,payload) =>{
    localStorage.setItem('postCode', payload);
    state.postCode= payload
  }),
  timeLogIn:getInfosStorage('timeLogIn','','string'),
  setTimeLogin:action((state,payload) =>{
    localStorage.setItem('timeLogIn', payload);
    state.timeLogIn= payload
  }),
  townName:getInfosStorage('townName','','string'),
  setTownName:action((state,payload) =>{
    localStorage.setItem('townName', payload);
    state.townName= payload
  }),
  token:getInfosStorage('token','','string'),
  setToken:action((state,payload) =>{
    localStorage.setItem('token', payload);
    state.token= payload
  }),

  deleteFromWishlist:getInfosStorage('deleteFromWishlist',false,'bool'),
  setDeleteFromWishlist:action((state,payload) =>{
    localStorage.setItem('deleteFromWishlist', payload);
    state.deleteFromWishlist= payload
  }),

  showPopupSuccess:getInfosStorage('showPopupSuccess',false,'bool'),
  setShowPopupSuccess:action((state,payload) =>{
    localStorage.setItem('showPopupSuccess', payload);
    state.showPopupSuccess= payload
  }),

  showPopupError:getInfosStorage('showPopupError',false,'bool'),
  setShowPopupError:action((state,payload) =>{
    localStorage.setItem('showPopupError', payload);
    state.showPopupError= payload
  }),

  moveToBasket:getInfosStorage('moveToBasket',false,'bool'),
  setMoveToBasket:action((state,payload) =>{
    localStorage.setItem('moveToBasket', payload);
    state.moveToBasket= payload
  }),

  isLoadingProduct:getInfosStorage('isLoadingProduct',false,'bool'),
  setIsLoadingProduct:action((state,payload) =>{
    localStorage.setItem('isLoadingProduct', payload);
    state.isLoadingProduct= payload
  }),




  messageError:getInfosStorage('messageError',false,'bool'),
  setMessageError:action((state,payload) =>{
    localStorage.setItem('messageError', payload);
    state.messageError= payload
  }),

  articleSizes:getInfosStorage('articleSizes',[],'array'),
  setArticleSizes:action((state,payload) =>{
    localStorage.setItem('articleSizes',JSON.stringify(payload));
    state.articleSizes= payload
  }),

  idUser:getInfosStorage('idUser',0,'number'),
  setIdUser:action((state,payload) =>{
    localStorage.setItem('idUser', payload);
    state.idUser= payload
  }),
  infosUser:getInfosStorage('infosUser',{surname:"",firstname:""},'object'),
  setinfosUser:action((state,payload) =>{
    localStorage.setItem('infosUser', payload);
    state.infosUser= payload
  }),
  connected:getInfosStorage('connected',false,'bool'),
  setConnected:action((state,payload) =>{
    localStorage.setItem('connected', payload);
    state.connected= payload
  }),
  totalBasket:getInfosStorage('totalBasket',0,'number'),
  setTotalBasket:action((state,payload) =>{
    
    state.totalBasket= payload
  }),
  addTotalBasket:action((state,payload) =>{
    
    state.totalBasket= parseInt(payload)+parseInt(state.totalBasket)
  }),
  baskets:getInfosStorage('baskets',[],'array'),
  setBaskets:action((state,payload) =>{
    
    state.baskets= payload
  }),
  lastActiveTime:getInfosStorage('lastActiveTime',"",'string'),
  setLastActiveTime:action((state,payload) =>{
    state.lastActiveTime= payload
  }),
  addBasket:thunk(async ( actions, data, helpers)=>{
    const state= helpers.getState()
    try {
      return addBasketHelp(actions,state,data)
    } catch (error) {
      return false
    }
  }),
  removeBasket:thunk(async ( actions, data, helpers)=>{
    const state= helpers.getState()
    try {
      return removeQuantityBasketHelp(actions,state,data)
    } catch (error) {
      return false
    }
  }),
  deleteBasket:thunk(async ( actions, data, helpers)=>{
    const state= helpers.getState()
    try {
      return removeItemBasketHelp (actions,state,data)
    } catch (error) {
      return false
    }
  }),

  setConnection:action((state,payload) =>{
    console.log("payload")
    console.log(payload)
    localStorage.setItem('timeLogIn', payload?.timeLogIn);
    localStorage.setItem('token', payload?.token);
    localStorage.setItem('idUser', payload?.idUser);
    localStorage.setItem('connected', payload?.connected);
    localStorage.setItem('infosUser', payload?.infosUser);
    
    state.timeLogIn= payload.timeLogIn
    state.token= payload.token
    state.idUser= payload.idUser
    state.connected= payload.connected
    state.infosUser= payload.infosUser
    
  }),
  setDisconnect:action((state,payload) =>{
    console.log("payload")
    console.log(payload)
    localStorage.removeItem('lastActiveTime');
    localStorage.removeItem('token');
    localStorage.removeItem('idUser');
    localStorage.removeItem('connected');
    localStorage.removeItem('infosUser');
    
    state.timeLogIn= ""
    state.token= ""
    state.idUser= 0
    state.connected= false
    state.infosUser= {surname:"",firstname:""}
    
  }),
  setFullDisconnect:action((state,payload) =>{
    
    localStorage.clear();
    
    state.postCode= ""
    state.townName= ""
    state.totalBasket= 0
    state.basket= []
    state.articleSizes= []
    state.timeLogIn= ""
    state.token= ""
    state.idUser= 0
    state.connected= false
    state.infosUser= {surname:"",firstname:""}
    
  }),
  clearBasketData:thunk(async ( actions, data, helpers)=>{
    
    try {
      localStorage.removeItem('baskets');
      localStorage.setItem('totalBasket', 0);
      actions.setBaskets([])
      actions.setTotalBasket(0)
    } catch (error) {
      return false
    }
  }),
  
  manageLogin:thunk(async ( actions, data, helpers)=>{
    try {
      const response= await api.post('/',data)
      const infos=response.data
      if(infos.success){
        actions.setConnected(true)
      }
    } catch (error) {
      
    }
  }),
  


});


function compareDate(date) {
  const currentTime = new Date();
  if (date > currentTime) {
    return true;
  } else {
    return false;
  }
}

function clearLocalStorage() {
  // Get the current time
  let currentTime = new Date().getTime();

  // Get the last time the user was active
  let lastActiveTime = localStorage.getItem('lastActiveTime');

  // If there is no last active time, set it to now
  if (!lastActiveTime) {
    localStorage.setItem('lastActiveTime', currentTime);
    return;
  }

  // Calculate the difference between now and the last active time
  let diff = currentTime - lastActiveTime;

  // If the difference is greater than 15 minutes, clear local storage and set the new active time to now
  if (diff > 900000) {
    localStorage.clear();
    localStorage.setItem('lastActiveTime', currentTime);
  } else {
    // Otherwise, just update the last active time to now
    localStorage.setItem('lastActiveTime', currentTime);  
  }  
}

function getInfosStorage(nameStorage,initValue,typeElement){
  try {
    if(!nameStorage){
      return returnTypeInfo(typeElement)
    }
    
    var data = localStorage.getItem(nameStorage);
    if(!data){
      if(!initValue){
        return returnTypeInfo(typeElement)
      }

      return initValue
      
      
    }

    if(nameStorage!=='articleSizes'){
      return data
    }

    return JSON.parse(data)
    

  } catch (error) {
    return returnTypeInfo(typeElement)
  }
  
}

function returnTypeInfo(typeElement){
  switch(typeElement){
    case 'string':
      return ''
    case 'array':
      return []
    case 'number':
      return 0  
    case 'bool':
      return false
    case 'object':
      return {}
    default:
      return ''
  } 
}

function addBasketHelp(actions,state,payload){
  try {
    const idBookProd =payload.idBookProd
    const idProduct =payload.idProduct
    const idBookSize =payload.idBookSize
    const quantity =payload.quantity
    
    let basketValues= state.baskets.length>0?JSON.parse(state.baskets):[]
    
    let findProduct=basketValues.findIndex(e=> 
      e.idBookProd=== idBookProd && 
      e.idProduct=== idProduct &&
      e.idBookSize=== idBookSize
    )
    if(findProduct>=0){
      let dataProduct= basketValues[findProduct].quantity
      
      dataProduct=parseInt(dataProduct)+parseInt(quantity)
      basketValues[findProduct].quantity=dataProduct
      

    }else{
      payload.quantity=payload.quantity>0?parseInt(payload.quantity):1
      basketValues.push(payload)
    }

    let quantityProduct=basketValues.reduce(function(accumulateur, valeurCourante, index, array){
      return accumulateur + valeurCourante.quantity;
    },0);
    localStorage.removeItem('baskets');
    localStorage.setItem('baskets', JSON.stringify(basketValues));
    localStorage.setItem('totalBasket', quantityProduct);
    actions.setBaskets(JSON.stringify(basketValues))
    actions.setTotalBasket(quantityProduct)
   
    return true;
  } catch (error) {
    
    return false;
   
  }
  
}

function removeItemBasketHelp (actions,state,payload){
  try {
    
    const idBookProd =payload.idBookProd
    const idProduct =payload.idProduct
    const idBookSize =payload.idBookSize

    const basketValues= state.baskets.length>0?JSON.parse(state.baskets):[]
    let findProduct=basketValues.findIndex(e=> 
      e.idBookProd=== idBookProd && 
      e.idProduct=== idProduct &&
      e.idBookSize===idBookSize
    )
   
    if(findProduct>=0){
      
      basketValues.splice(findProduct, 1);
      let quantityProduct=basketValues.reduce(function(accumulateur, valeurCourante, index, array){
        return accumulateur + valeurCourante.quantity;
      },0);
      localStorage.removeItem('baskets');
      localStorage.setItem('baskets',JSON.stringify(basketValues) );
      localStorage.setItem('totalBasket', quantityProduct);
      actions.setBaskets(JSON.stringify(basketValues))
      actions.setTotalBasket(quantityProduct)
      return true
    }
    return false

  } catch (error) {
   
    return false
  }
  
  
}

function removeQuantityBasketHelp (actions,state,payload){ 
  try {
    const idBookProd =payload.idBookProd
    const idProduct =payload.idProduct
    const idBookSize =payload.idBookSize
    const quantity =payload.quantity
    
    let basketValues= state.baskets.length>0?JSON.parse(state.baskets):[]
  
    let findProduct=basketValues.findIndex(e=> 
      e.idBookProd=== idBookProd && 
      e.idProduct=== idProduct &&
      e.idBookSize=== idBookSize
    )
    if(findProduct>=0){
      let dataProduct= basketValues[findProduct].quantity
      
      dataProduct=parseInt(dataProduct)-parseInt(quantity)
      basketValues[findProduct].quantity=dataProduct
    

    }

    let quantityProduct=basketValues.reduce(function(accumulateur, valeurCourante, index, array){
      return accumulateur + valeurCourante.quantity;
    },0);
    localStorage.removeItem('baskets');
    localStorage.setItem('baskets', JSON.stringify(basketValues));
    localStorage.setItem('totalBasket', quantityProduct);
    actions.setBaskets(JSON.stringify(basketValues))
    actions.setTotalBasket(quantityProduct)
    
    return true;
    } catch (error) {
      
    return false;
   
  }
  
}