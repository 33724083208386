import React from 'react'
import ErrorFiveHundred from '../errors/ErrorFiveHundred';
import Loading from '../loading/Loading';
import Template from '../Template';
import { Link } from 'react-router-dom'

import ArticleBox from '../articleBox/ArticleBox';
import FilterTemplate from '../FilterProduct';


function SectionTemplateCat({isLoading,fetchError,titleSection,infos,showPrices}) {

  return (
    <div>
      
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError &&
        <React.Fragment >
          <FilterTemplate allProducts={infos.allProducts} titleSection={titleSection} infos={infos} showPrices={showPrices}/>
        </React.Fragment>
      }
      
    </div>
  )
}

export default SectionTemplateCat

/*
<Template>
        <div className="container">
            <section className="section-content padding-y">
                <div className="container">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-2"> Vous êtes ici: </div> 
                        <nav className="col-md-8"> 
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to={`/`}>
                                Accueil
                              </Link>
                            </li>
                            <li className="breadcrumb-item">{titleSection}</li>
                            
                        </ol>  
                        </nav>
                      </div>
                      <hr/>
                      <div className="row">
                        <div className="col-md-2">Filter par</div>
                        <div className="col-md-10"> 
                          <ul className="list-inline">
                            
                            <li className="list-inline-item mr-3 dropdown"><button type="button" className="dropdown-toggle" data-toggle="dropdown">   Catégorie </button>
                                  <div className="dropdown-menu p-3" style={{"maxWidth":"400px"}}>
                                    <label className="form-check">
                                      <input type="radio" name="myfilter" value="all" className="form-check-input"/> Tous	
                                    </label>
                                    {infos.catPrimaries?.map((e,index)=>{
                                      return(
                                        <>
                                         <input type="radio" name="myfilter" defaultValue={e.idCategoryProduct} className="form-check-input"/>{e.nameCategoryProduct}
                                        </>
                                       
                                      )
                                    }
                                      
                                    )}
                                   
  
                                  </div>
                              </li>
                             
                            
                            <li className="list-inline-item mr-3">
                              <div className="form-inline">
                                <label className="mr-2">Prix</label>
                              <input className="form-control form-control-sm" placeholder="Min" type="number"/>
                                <span className="px-2"> - </span>
                              <input className="form-control form-control-sm" placeholder="Max" type="number"/>
                              <button type="submit" className="btn btn-sm btn-light ml-2">Ok</button>
                            </div>
                            </li>
                           
                          </ul>
                        </div>
                      </div> 
                    </div> 
                  </div> 
                
            
                  {/*<header className="mb-3">
                      <div className="form-inline">
                        <strong className="mr-md-auto">{infos.sizesProduct} articles trouvés </strong>
                      </div>
                  </header>
            
                  <div className="row">
                    {infos.allProducts?.map((e,index)=>
                      <ArticleBox key={index}  idBookProd={e.idBookProd} image={e.uniqIdDoc} 
                      nameProduct={e.nameProduct} 
                      price={e.price} 
                      showPrices={showPrices} 
                      idBookSizePromo={e.idBookSizePromo}
                      newPricePromo={e.newPricePromo}
                      oldPricePromo={e.oldPricePromo}
                      isFlash={e.isFlash} 
                      articlePromo={e.articlePromo}
                      sellBy={"LNVX"} stock={e.stock}
                      idProduct={e.idProduct}
                      idBookSize={e.idBookSize}
                      article={e}
                      />
                    )}
                  </div> 
            
            
                  <nav className="mb-4" aria-label="Page navigation sample">
                    <ul className="pagination">
                      <li className="page-item disabled"><Link className="page-link" to="/">Préc</Link></li>
                      {(() => {
                        let td = [];
                        for (let i = 1; i < infos.totalPage+1; i++) {
                          if(i==1){
                            td.push(<li className="page-item active" key={i}><Link className="page-link" to="/">{i}</Link></li>);
                          }else{
                            td.push(<li className="page-item" key={i}><Link className="page-link" to="/">{i}</Link></li>);
                          }
                          
                        }
                        return td;
                      })()}
                      <li className="page-item"><Link className="page-link" to="/">Suiv</Link></li>
                    </ul>
                  </nav>
                </div> 
              </section>
        </div>
       
      </Template>




*/