import React from 'react'
import { Link } from 'react-router-dom'
import Template from './components/Template'

function Contact() {
  return (
    <Template>
        <section className="section-pagetop bg-secondary">
          <div className="container">
            <h2 className="title-page text-white">CONTACT</h2>
          </div>
        </section>
        
        <section className="section-content bg-white padding-y">
          <div className="container">
           
            <h5 style={{textAlign:"center"}}>CONTACT</h5><br/><br/>
            <h6>Vous pouvez nous contacter par e-mail à l'adresse suivante:</h6><br/><br/><br/>
            <p style={{textAlign:"center"}}><button className='btn ' onClick={() => window.location = 'mailto:info@lnvx.fr'}>info@lnvx.fr</button></p>

            <br/><br/>

            <Link to="/" className="btn btn-light">Retourner à la page d'accueil</Link>
          </div> 
        </section>

           
    </Template>
  )
}

export default Contact