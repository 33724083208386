import React,{ useState, useRef } from 'react'
import {useStoreState,useStoreActions } from 'easy-peasy';
import axios from 'axios';
import {urlData} from '../../services/url';
import {valueSupMyNumber} from '../../services/helpers';

function LocalityChange({profil,refreshData}) {


  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const setPostCode= useStoreActions((state)=>state.setPostCode)
  const setTownName= useStoreActions((state)=>state.setTownName)
  const [loadingResultTowns, setLoadingResultTowns] = useState(false)
  const [resultTowns, setResultTowns] = useState([])
  const [pickTowns, setPickTowns] = useState()
  const [pickNameTowns, setPickNameTowns] = useState()
  const [errorFindResultTowns, setFindErrorResultTowns] = useState(false)
  const [errorResultTowns, setErrorResultTowns] = useState("")
  const [idMonde, setIdMonde] = useState(0)
  const [successChangeMessage, setSuccessChangeMessage] = useState('')
  const [successChange, setSuccessChange] = useState(false)
  const [hideBtnValid, setHideBtnValid] = useState(false)
  const getPostCode=useRef()



  const handlePostCodeFindTowns=(e)=>{
    e.preventDefault()
    if(getPostCode.current.value.length===5){
        getTownsFromPostCode()
    }else{
      setResultTowns([])
      setPickTowns(null)
      setPickNameTowns(null)
      setFindErrorResultTowns(false);
      setErrorResultTowns("")
    }
  }

  const getTownsFromPostCode=async ()=>{
   
    setLoadingResultTowns(true)
    try {
      
      const response = await axios.get(`${urlData()}/find-postcode/${getPostCode.current.value}`);
      console.log('response')
      console.log(response)
      if(response.data.success) {
        setLoadingResultTowns(false)
        setFindErrorResultTowns(false);
        setErrorResultTowns("")
        setPickTowns(response.data.allInfos[0].id_france)
        setIdMonde(response.data.allInfos[0].id_france)
        setPickNameTowns(response.data.allInfos[0].commune_france)
        setResultTowns(response.data.allInfos);
        
      }else{
        setLoadingResultTowns(false)
        console.log("findErrors in ")
        setFindErrorResultTowns(true);
        setErrorResultTowns(response.data.message)
      }
    } catch (error) {
      console.log()
      setLoadingResultTowns(false)
      setFindErrorResultTowns(true);
      setErrorResultTowns("Une erreur interne est survenu(Changement code postal)")
    }
   
  }

  const validPostCode= async (e)=>{
    e.preventDefault()
    if(!pickTowns) return;

    let body ={
      idUser,idMonde,codePostal:getPostCode.current.value
    }
    const responseLocation = await axios.put(`${urlData()}/user-account/record-location`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
    console.log("responseLocation")
    console.log(responseLocation)
    console.log("responseIdentity")
    if(!responseLocation.data.success){
      setLoadingResultTowns(false)
      setFindErrorResultTowns(true);
      setErrorResultTowns(responseLocation.data.message)
    
      return
    }
    setPostCode(pickTowns)
    setTownName(pickNameTowns)
    setHideBtnValid(true)
    setSuccessChangeMessage(responseLocation.data.message)
    setSuccessChange(true)
    
    setTimeout(()=>{
      handleClosechangePostCodeUser()
    },3000)
    refreshData()
    
    return 
  }

  const handleClosechangePostCodeUser=()=>{
   
    window.$("#changePostCodeUser").modal("hide")
    setFindErrorResultTowns(false);
    setErrorResultTowns("")
    setPickTowns("")
    setIdMonde(0)
    setResultTowns([]);
    setSuccessChangeMessage("")
    setSuccessChange(false)
    setHideBtnValid(false)
  }




  return (
    <article className="card mb-4">
        <header className="card-header">
          <strong className="d-inline-block mr-3">LOCALITE</strong>
        </header>
        <div className="card-body">
          <div className="row" > 
            <div className="col-md-8">
              <h6 className="text-muted">Modifier votre localité</h6>
              <p>Ville: {profil.userInfos.nameTown} ({profil.userInfos.postCode})</p>
              <button type='button' onClick={()=>window.$("#changePostCodeUser").modal("show")} className='btn btn-warning'>Modifier</button>
            </div>
          </div> 
        </div> 
        <div className="modal" id="changePostCodeUser"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">INFORMATION CODE POSTAL</h5>
          
        </div>
        <div className="modal-body">
            <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                  En modifiant le code postal,votre panier pourra être modifier ou supprimer.
                </p>
              </div>
            <h6>Entrer le code postal</h6>
            <input type="number" max="5" onChange={handlePostCodeFindTowns} className="form-control" ref={getPostCode} />
            <hr/>
            { loadingResultTowns && <p>Recherche......</p>}
            { resultTowns.length>0 &&
              <div>
                <h6>Selectionner une ville:</h6>
                {resultTowns.map((e,index)=>{
                  return(
                      <button key={index} type="button" className={pickTowns===e.id_france?"btn btn-secondary":"btn btn-light"} style={{marginBottom:"5px",marginRight:"3px"}} onClick={()=>{
                        setPickTowns(e.id_france)
                        setIdMonde(e.id_france)
                        setPickNameTowns(e.commune_france)
                  
                      }}>{e.commune_france}</button>
                  )
                  
                })}
                
              </div>
            }
            {errorFindResultTowns && 
              <div className="alert alert-danger mt-3">
                <p className="icontext"><i className="icon text-success fa fa-triangle-exclamation"></i> {errorResultTowns}</p>
              </div>
            }
            {successChange && !errorFindResultTowns &&
              <div className="alert alert-success mt-3">
                <p className="icontext"><i className="icon text-success fa fa-check"></i>{successChangeMessage}</p>
              </div>
            }
            
        </div>
        <div className="modal-footer">
          
          { resultTowns.length===0 &&
            <button type="button" title='Vous devez sélectionner une ville' disabled className="btn btn-success">Valider</button>
          }

          { resultTowns.length>0 && pickTowns && !hideBtnValid && 
            <button type="button" className="btn btn-success" onClick={validPostCode}>Valider</button>
          }
          { !hideBtnValid && 
             <button type="button" onClick={handleClosechangePostCodeUser} className="btn btn-danger " >
              Annuler
          </button>
          }
          { hideBtnValid && 
             <button type="button" onClick={handleClosechangePostCodeUser} className="btn btn-danger " >
              Fermer
          </button>
          }
        </div>
      </div>
    </div>
  </div>
      </article> 
    
  )
}

export default LocalityChange