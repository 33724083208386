export function dotToComma(value){
  try {
    if(value && value!=="Indisponible"){
      value= parseFloat(value).toFixed(2)
      value=value.toString()
      value=value.replace('.',',')
      value=value.replace(',00','')
    }
  
    return value
  } catch (error) {
    console.log("error dottocomma",error)
    return value
  }
  
}

export function commaToDot(value){
  try {
    if(value && value!=="Indisponible"){
      value=value.toString()
      value=value.replace(',','.')
      value= parseFloat(value)
    }
  
    return value
  } catch (error) {
    console.log("error commaToDot",error)
    return value
  }
  
}

export function valueSupZero(value){
  try {
    let getData=value && value>0?true:false;
    return getData
  } catch (error) {
    return false
  }
  
}


export function valueSupMyNumber(number,value){
  try {
    let getData=value && value>=number?true:false;
    return getData
  } catch (error) {
    return false
  }
  
}

export  function random_sort(a, b) {
  return Math.random() - 0.5;
 }

export function validateEmail(value){
  try {
    if(!value){
      return false
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)){
      return false
    }
    return true
  } catch (error) {
    return false
  }
  
}

