import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import { Link,useParams, useNavigate  } from 'react-router-dom'
import { useStoreState,useStoreActions } from 'easy-peasy';
import Template from './components/Template';
import Loading from './components/loading/Loading';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred';
import { dotToComma } from "./services/helpers"
import AddToBasket from './components/addToBasket/AddToBasket';
import {urlData,urlDataImage} from './services/url';

function Article() {

  const linkApiImages=urlDataImage()
  const postCode= useStoreState((state)=>state?.postCode)
  const connected= useStoreState((state)=>state?.connected)
  const token= useStoreState((state)=>state?.token)
  const idUser= useStoreState((state)=>state?.idUser)
  const [articlePage, setArticlePage]= useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)
  const [ quantityPrice, setQuantityPrice ]= useState(1)
  const [ pickPrice, SetPickPrice ]= useState(0)
  const [ pickPicture, SetPicture ]= useState(0)
  const [ isFavorite, setIsFavorite ]= useState(false)
  const params = useParams();

  const addBasket= useStoreActions((state)=>state.addBasket)
  const addTotalBasket= useStoreActions((state)=>state.addTotalBasket)
  const setArticleSizes= useStoreActions((state)=>state.setArticleSizes)
  const setIsLoadingProduct= useStoreActions((state)=>state.setIsLoadingProduct)
  const setMessageError= useStoreActions((state)=>state.setMessageError)
  const setMoveToBasket= useStoreActions((state)=>state.setMoveToBasket)
  const setShowPopupError= useStoreActions((state)=>state.setShowPopupError)
  const setShowPopupSuccess= useStoreActions((state)=>state.setShowPopupSuccess)
  const setDeleteFromWishlist= useStoreActions((state)=>state.setDeleteFromWishlist)

  useEffect(() => {
    
    const fetchData = async () => {
      setIsLoading(true)
      try {
          const response = await axios.get(`${urlData()}/product/${params.idArticle}?postCode=${postCode}`);
        
          if(response.data.success) {
            setFetchError(null);
            setArticlePage(response.data);
            setIsFavorite(response.data?.product?.isFavorite)
            
            
          }else{
           
            setFetchError(response.data?.message);
          }
      } catch (err) {
       
          setFetchError(err.message);
      } finally {
        !postCode?setShowPrices(false):setShowPrices(true)
          setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode,params])


  const removeOne=()=>{
    if(quantityPrice>1){
      setQuantityPrice(c=> c-1)
    }
    
  }
  const addOne=()=>{
    setQuantityPrice(c=> c+1)
  }


  const removeFavorite=async (idBookProduct)=>{
    if(idBookProduct){
      const responseWishlist = await axios.delete(`${urlData()}/wishlist/${idUser}/${idBookProduct}`,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      console.log(responseWishlist)
      if(responseWishlist.data.success){
        setIsFavorite(false)
        return
      }
    }
    
  }

  const addFavorite=async (idBookProduct)=>{
    if(idBookProduct){
      let body=JSON.stringify({idBookProduct,idUser})
      const responseWishlist = await axios.post(`${urlData()}/wishlist`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      console.log(responseWishlist)
      if(responseWishlist.data.success){
        setIsFavorite(true)
        return
      }
    }
    
  }

  return (
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {!isLoading && !fetchError && 
          <Template>
              <section className="py-3 ">
                <div className="container">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={`/`}>
                          Accueil
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={`/categories/${articlePage.product.idCatPro}`}>
                          {articlePage.product.nameCatPro}
                        </Link>
                      </li>
                      {articlePage.product.nameSecCatPro?<li className="breadcrumb-item">{articlePage.product.nameSecCatPro}</li>:``}
                      {articlePage.product.nameCatThiPro?<li className="breadcrumb-item">{articlePage.product.nameCatThiPro}</li>:``}
                      {articlePage.product.nameCatFouPro?<li className="breadcrumb-item">{articlePage.product.nameCatFouPro}</li>:``}
                      {articlePage.product.nameProduct?<li className="breadcrumb-item">{articlePage.product.nameProduct}</li>:``}
                      
                  </ol>
                </div>
              </section>

              <section className="section-content bg-white padding-y">
                <div className="container">
                  <div className="row">
                    <aside className="col-xs-12 col-md-6">
                      <div className="card">
                        <article className="gallery-wrap"> 
                          <div className="img-big-wrap">
                            
                            {articlePage.product.documents.length>0?
                              articlePage.product.documents?.map((i,index) => {
                                return (
                                <img key={index} className={pickPicture!==index?"d-none":""} src={`${linkApiImages}${i.uniqid_doc}`} style={{width:"100%",objectFit:"contain"}} alt="article"/>
                                )
                              })
                              :
                              <div><img src={`${linkApiImages}no`} alt="article"/> </div>
                            }
                            
                              
                      
                          
                          </div>
                          <div className="thumbs-wrap">
                            {articlePage.product.documents?.map((i,index) => {
                              return <button type="button"  onClick={()=>SetPicture(index)} key={i.uniqid_doc} className="item-thumb"> <img src={`${linkApiImages}${i.uniqid_doc}`} alt="thumbnail"/></button>
                            })}
                          
                            
                          </div> 
                        </article> 
                      </div> 
                    </aside>
                    <main className="col-md-6">
                      <article className="product-info-aside">
                        <h2 className="title mt-3">{articlePage.product.nameProduct} </h2>
                        

                        <div className="mb-3"> 
                          
                          {showPrices && articlePage.product.sizes.length>1 &&<span className="text-muted" style={{"marginRight":"10px"}}>A partir de</span>}
                          {showPrices &&<var className="price h4">{dotToComma(articlePage.product.price)}€</var> }
                        </div> 
                        <p>{articlePage.product.description_product} </p>


                        <dl className="row">
                          <dt className="col-sm-3">Vendu par </dt>
                          <dd className="col-sm-9">{articlePage.product.market_product===1?
                            <Link href={`/vendeur/${articlePage.product.idSupplier}`}>
                              <a href="#">{articlePage.product.nameSupplier}</a>
                            </Link>
                            :`LNVX`
                          }</dd>

                          <dt className="col-sm-3">Marque</dt>
                          <dd className="col-sm-9">{articlePage.nameBrand}</dd>

                          <dt className="col-sm-3">Catégorie</dt>
                          <dd className="col-sm-9">{articlePage.product.nameCatPro}</dd>

                          

                          <dt className="col-sm-3">Disponibilité</dt>
                          <dd className="col-sm-9">{articlePage.product.available_bookprod===1?"OUI":"NON"}</dd>
                          {connected && <dt className="col-sm-3">Wishlist</dt>}
                          {connected && <dd className="col-sm-9">
                            {isFavorite?
                            <div><i className='fa fa-heart'style={{color:"green"}} title='Cet article est enregistré dans la wishlist'></i> <button type='button' className="btn" onClick={()=>removeFavorite(articlePage?.product?.idBookProd)}><i className='fa fa-trash' style={{color:"red"}} title='Retirer de la wishlist'></i></button></div>:
                            <div> <i className='fa fa-heart'style={{color:"gray"}} title='Ajouter à la wishlist'></i> <button type='button' className="btn" onClick={()=>addFavorite(articlePage?.product?.idBookProd)}><i className='fa fa-plus'style={{color:"blue"}} title='Ajouter à la wishlist'></i></button></div> }
                            </dd>
                          }
                        </dl>
                        <div className="row mt-4">
                          <div  className="col-12">
                            <div className="input-group mb-3 input-spinner">
                              {showPrices && articlePage.product.sizes?.map((v,index) => {
                                
                                
                                  return (
                                    <div key={index} className="mr-1">
                                      <button   onClick={e =>SetPickPrice(index)} className={index===pickPrice?`btn btn-rounded btn-outline-secondary bg-secondary `:"btn btn-rounded btn-outline-secondary  "} style={{"minWidth":"100px"}} >
                                        <p className={index===pickPrice?"text-white":""}>{v.nameSizing}<br/>
                                            {v.nameColor}<br/>
                                            {articlePage.product.articlePromo?dotToComma(v.newprice_bookpromotionsize):dotToComma(v.price_bookprosize)}€
                                            {articlePage.product.articlePromo &&<br/>}
                                            {articlePage.product.articlePromo &&<del>{dotToComma(v.oldprice_bookpromotionsize)}€</del>
                                          }
                                        </p>
                                      </button>
                                    </div>
                                  )
                                
                                
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="form-row  mt-4">
                          <div className="form-group col-md flex-grow-0">
                            <div className="input-group mb-3 input-spinner">
                              <div className="input-group-append">
                                <button className="btn btn-light"  onClick={removeOne} type="button" id="button-minus"> &minus; </button>
                              </div>
                              <input type="text" className="form-control" onChange={e=>setQuantityPrice(e.target.value)} value={quantityPrice}/>
                              <div className="input-group-prepend">
                                <button className="btn btn-light"   onClick={addOne} type="button" id="button-plus"> + </button>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md btn-group">
                          <AddToBasket 
                              idBookProd={articlePage.product.sizes[pickPrice].idBookProd}
                              idProduct={articlePage.product.sizes[pickPrice].idproduct_bookprosize}
                              idBookSize={articlePage.product.sizes[pickPrice].idBookProSize}
                              quantity={quantityPrice}
                              showPopupSuccess={1}
                              showPopupError={1}
                              multipleArticle={articlePage.multipleArticle>1?true:false}
                              addBasket={addBasket}
                              connected={connected}
                              postCode={postCode}
                              token={token}
                              idUser={idUser}
                              addTotalBasket={addTotalBasket}
                              setArticleSizes={setArticleSizes}
                              setIsLoadingProduct={setIsLoadingProduct}
                              setMessageError={setMessageError}
                              setMoveToBasket={setMoveToBasket}
                              setShowPopupError={setShowPopupError}
                              setShowPopupSuccess={setShowPopupSuccess}
                              setDeleteFromWishlist={setDeleteFromWishlist}
                            >
                              <button  disabled={showPrices?false:true} className="btn  btn-secondary mr-2 mb-2"> 
                              <i className="fas fa-shopping-cart"></i> <span className="text">Ajouter au panier</span> 
                            </button>
                            </AddToBasket>
                            <AddToBasket 
                              idBookProd={articlePage.product.sizes[pickPrice].idBookProd}
                              idProduct={articlePage.product.sizes[pickPrice].idproduct_bookprosize}
                              idBookSize={articlePage.product.sizes[pickPrice].idBookProSize}
                              quantity={quantityPrice}
                              showPopupSuccess={0}
                              showPopupError={1}
                              moveToBasket={1}
                              multipleArticle={articlePage.multipleArticle>1?true:false}
                              addBasket={addBasket}
                              connected={connected}
                              postCode={postCode}
                              token={token}
                              idUser={idUser}
                              addTotalBasket={addTotalBasket}
                              setArticleSizes={setArticleSizes}
                              setIsLoadingProduct={setIsLoadingProduct}
                              setMessageError={setMessageError}
                              setMoveToBasket={setMoveToBasket}
                              setShowPopupError={setShowPopupError}
                              setShowPopupSuccess={setShowPopupSuccess}
                              setDeleteFromWishlist={setDeleteFromWishlist}
                            >
                              <button  disabled={showPrices?false:true} className="btn  btn-secondary"> 
                              <i className="fas fa-shopping-cart"></i> <span className="text">Acheter maintenant</span> 
                            </button>
                            </AddToBasket>
                           
                          </div>
                          
                        </div> 
                      </article>
                    </main> 
                  </div> 
                </div> 
              </section>
              
              <section className="section-name padding-y bg">
                <div className="container">

                  <div className="row">
                    <div className="col-md-8">
                      <h5 className="title-description">Description</h5>
                      <p>
                        {articlePage.product.descriptionProduct}
                      </p>
                      

                      <h5 className="title-description">Caractéristiques</h5>
                      <table className="table table-bordered">
                        <tbody>
                          {articlePage.product.features?.map((i,index) => {
                            return  <tr key={i.id_profeat}><td>{i.feature_profeat}</td><td>{i.description_profeat}</td></tr>
                          })}
                        </tbody>
                        
                        
                      </table>
                    </div> 
                  </div> 
                </div>
              </section>
             
           
          </Template>
      }
    </div>
  )
}

export default Article



