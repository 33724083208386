import React from 'react'
import {useStoreState } from 'easy-peasy';
import BasketVisitor from './components/basketScreen/visitor/BasketVisitor'
import BasketUser from './components/basketScreen/user/BasketUser';

function Basket() {
  const connected= useStoreState((state)=>state.connected)
 
 
  return (
    <div>
      {!connected && <BasketVisitor/>}
      {connected && <BasketUser/>}
    </div>
  )
}

export default Basket