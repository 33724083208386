import React from 'react'

function ArticleTwo() {
  return (
    <>
        <h5 class="card-title">Article 2</h5>
        <p>
        Présentation des produits L’entreprise est dans le droit d’apporter des modifications à ses produits, cela ne l’oblige en aucun cas à apporter des modifications aux produits déjà en cours de commande. De plus, les photos présentent sur le site sont fournies à titre non contractuel. Les disponibilités du produit sont indiquées sur le site. Dans le cas où les quantités ne correspondent pas au stock réel, un délai de livraison supplémentaire sera appliqué. Si le produit n’est plus disponible l’entreprise s’engage à rembourser le client.
        </p>
    </>
  )
}

export default ArticleTwo