import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import { useStoreState,useStoreActions } from 'easy-peasy';
import Loading from '../../loading/Loading';
import ErrorFiveHundred from '../../errors/ErrorFiveHundred';
import DetailsBasket from './steps/DetailsBasket';
import DeliveryBasket from './steps/DeliveryBasket';
import PaymentBasket from './steps/PaymentBasket';
import BasketEmpty from '../BasketEmpty';
import {urlData} from '../../../services/url';


function BasketVisitor() {

  const [data, setData] = useState({});
  const [allowedNextStep, setAllowedNextStep] = useState(false);
  const [basketEmpty, setBasketEmpty] = useState(false);
  const [fetchError, setFetchError] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false)
  const [showBasket, setShowBasket] = useState(true)
  const [showPayment, setShowPayment] = useState(false)
  const [dataPayment, setDataPayment] = useState({})

  const baskets= useStoreState((state)=>state.baskets)
  const postCode= useStoreState((state)=>state.postCode)
  const connected= useStoreState((state)=>state.connected)
  const setTotalBasket= useStoreActions((state)=>state.setTotalBasket)
  
  const isAvailable = (currentValue) => currentValue.stock >0 && currentValue.availableSize===1 && currentValue.availableBookProd===1;

  function checkAvailableProduct(dataBasket){
    if(!dataBasket || dataBasket.length===0){
      setAllowedNextStep(false)
      return
    }

    if(!dataBasket.every(isAvailable)){
      setAllowedNextStep(false)
      return
    }

    setAllowedNextStep(true)
    return



  }
  

  useEffect(() => {
    let payload={basketData:baskets,postCode}
    const fetchData = async () => {
      if(connected){
        setIsLoading(false);
        window.location.reload();
        return
      }else{
        
        try {
          if(!isLoading){
            setIsLoading(true);
            const source = axios.CancelToken.source();
            payload.cancelToken=source.token
            //payload=JSON.stringify(payload)
            const response = await axios.post(`${urlData()}/basket-visitor`,payload,{header:{"Content-Type":"application/json"}});
            console.log('response')
            console.log(response)
            if(response.data.success) {
              setFetchError(null);
              if(!response?.data?.find){
                setTotalBasket(0)
                setBasketEmpty(true)
              }else{
                setData(response?.data);
                checkAvailableProduct(response.data?.infosPanier?.products)
                //setFetchError(null);
              } 
            }else{
              setFetchError(response.message);
            }
          }
            
        } catch (err) {
          setFetchError(err.message);
        } finally {
         
    
          setIsLoading(false);
        }
      }
    }
    
    fetchData()
  },[postCode,connected])
 
  /*useEffect(() => {
    const refreshData= async ()=>{

      let payload={basketData:baskets,postCode}
      const source = axios.CancelToken.source();
      payload.cancelToken=source.token
      try {
        const response = await axios.post(`${urlData()}/basket-visitor`,payload,{header:{"Content-Type":"application/json"}});
      
        if(response.data.success) {
            if(response.data.find){
              setData(response.data);
              checkAvailableProduct(response.data?.infosPanier?.products)
              setFetchError(null);
            }else{
              setBasketEmpty(true)
            }
            
        }else{
        
          setFetchError(response.message);
        }
      } catch (err) {
      
      
          setFetchError(err.message);
      } 
    }
    refreshData()

  },[totalBasket])*/

  const refreshData= async ()=>{

    let payload={basketData:baskets,postCode}
    const source = axios.CancelToken.source();
    payload.cancelToken=source.token
    try {
      const response = await axios.post(`${urlData()}/basket-visitor`,payload,{header:{"Content-Type":"application/json"}});
    
      if(response.data.success) {
          if(response.data.find){
            setData(response.data);
            checkAvailableProduct(response.data?.infosPanier?.products)
            setFetchError(null);
          }else{
            setTotalBasket(0)
            setBasketEmpty(true)
          }
          
      }else{
      
        setFetchError(response.message);
      }
    } catch (err) {
    
    
        setFetchError(err.message);
    } 
  }
  
  const showBasketInfos=()=>{
    setShowDelivery(false)
    setShowBasket(true)
    setShowPayment(false)
  }

  const showDeliveryInfos=()=>{
    if(!allowedNextStep){
      return
    }
    setShowDelivery(true)
    setShowBasket(false)
    setShowPayment(false)
  }

  const showPaymentInfos=(payloadDataPayment)=>{
    if(!allowedNextStep){
      return
    }
    setShowDelivery(false)
    setShowBasket(false)
    setDataPayment(payloadDataPayment)
    setShowPayment(true)
  }

  const handleCloseModal=()=>{
    window.$('.modal').modal("hide")
  }


 
  return (
    
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {!isLoading && !fetchError && basketEmpty && <BasketEmpty/>}
      {!isLoading && !fetchError && baskets.length>0 && showBasket &&
        <DetailsBasket data={data}  showDeliveryInfos={showDeliveryInfos}  basketEmpty={basketEmpty} refreshData={refreshData}/>
      }
      {!isLoading && !fetchError &&  baskets.length>0 && showDelivery &&
        <DeliveryBasket data={data} showBasketInfos={showBasketInfos} showPaymentInfos={showPaymentInfos} />
      }
      {!isLoading && !fetchError &&  baskets.length>0 && showPayment &&
        <PaymentBasket dataPayment={dataPayment} />
      }
      <div className="modal" id="basketInfoDispo"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">INFORMATION</h5>
              
            </div>
            <div className="modal-body">
                <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                    Vous devez supprimer les produits indisponibles
                    </p>
                  </div>
                
            </div>
            <div className="modal-footer">
      
              <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="basketInfoQuantity"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">INFORMATION</h5>
              
            </div>
            <div className="modal-body">
                <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                    Vous devez réduire la quantité d'un ou des produits.
                    </p>
                  </div>
                
            </div>
            <div className="modal-footer">
      
              <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="basketInfoAvailableProduct"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">INFORMATION</h5>
              
            </div>
            <div className="modal-body">
                <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                    Vérifiez les produits. Un ou plusieurs produits ne sont pas disponibles ou en stock insuffisant.
                    </p>
                  </div>
                
            </div>
            <div className="modal-footer">
      
              <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasketVisitor