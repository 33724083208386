import {random_sort} from '../../services/helpers';
import {urlDataImage} from '../../services/url';
import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom';



export default function PromotionPart({categories}) {

  
  const [categoriesData,setCategoriesData] = useState([])
  const linkApiImages=urlDataImage()


  useEffect(()=>{
    function sortCategories(){
      if(categories.length>0){
        let categoriesInfo= categories.length>5?categories.sort(random_sort).slice(0,5):categories.sort(random_sort)
      
        setCategoriesData(categoriesInfo)
      }
    }

    sortCategories()
  },[categories])
  
  
  return (
    <div>
      {
        categoriesData.length>0 &&
        <section className="padding-bottom">
            <div className="card card-deal">
                <div className="col-heading content-body">
                  <header className="section-heading">
                    <h3 className="section-title">NOS CATEGORIES</h3>
                    
                  </header>
                  {/*<div className="timer">
                    <div> <span className="num">04</span> <small>Days</small></div>
                    <div> <span className="num">12</span> <small>Hours</small></div>
                    <div> <span className="num">58</span> <small>Min</small></div>
                    <div> <span className="num">02</span> <small>Sec</small></div>
                  </div>*/}
              </div>
              <div className="row no-gutters items-wrap">
                {
                  categoriesData.map((e,index)=>{
                    return (
                      <div key={index} className="col-md col-6  mb-5">
                        <figure className="card-product-grid card-sm">
                          <Link to={`categories/${e.idCatProd}`} className="title">
                            <img width={120} src={`${linkApiImages}${e.uniqIdDocCatProd}`} alt={`Categorie ${e.nameCatProd}`}/>
                          </Link>
                          <div className="text-wrap p-3">
                            <Link to={`categories/${e.idCatProd}`} className="badge badge-danger">{e.nameCatProd}</Link>
                            
                          </div>
                      </figure>
                    </div> 
                    )
                  })
                }
                
           
            </div>
            </div>

          </section>
      }
       

    </div>
  );
}