import React,{ useState } from 'react'
import {useStoreState } from 'easy-peasy';
import axios from 'axios';
import {urlData} from '../../services/url';
import {validateEmail} from '../../services/helpers';

function UsernameChange({profil,refreshData}) {


  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const [errorFindUsername, setErrorFindUsername] = useState(false);
  const [errorMessageFindUsername, setErrorMessageFindUsername] = useState(false);
  const [successFindUsername, setSuccessFindUsername] = useState(false);
  const [successMessageFindUsername, setSuccessMessageFindUsername] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [confNewUsername, setConfNewUsername] = useState("");
  const [passNewUsername, setPassNewUsername] = useState("");
  const [sentCodeUsername, setSentCodeUsername] = useState(false);
  const [codeUsername, setCodeUsername] = useState("");
  const [closeFormUsername, setCloseFormUsername] = useState(false);


  const hideFormError=()=>{
    window.$(".formChangeUsername").hide()
  }
  hideFormError()


  const showInvalideValue=(value,message="Invalide")=>{
    window.$(`#${value}`).html(message).show()
  }
  const hideInvalideValue=(value,mess)=>{
    window.$(`#${value}`).hide()
  }


  const sendConfEmail= async()=>{
    hideFormError()
    setErrorFindUsername(false)
    setErrorMessageFindUsername("")
    setSuccessFindUsername(false)
    setSuccessMessageFindUsername("")
    if(!validateEmail(newUsername)){
      showInvalideValue('checkNewUsername','Email invalide')
      return 
    }

    if(!validateEmail(confNewUsername)){
      showInvalideValue('checkConfNewUsername','Email invalide')
      return 
    }

    if(newUsername!==confNewUsername){
      showInvalideValue('checkNewUsername','Email invalide')
      showInvalideValue('checkConfNewUsername','Email invalide')
      return 
    }

    if(!passNewUsername){
      showInvalideValue('checkPassNewUsername','Mot de passe invalide')
      return 
    }

   
    let body ={
      idUser,username:newUsername,confUsername:confNewUsername,password:passNewUsername
    }
    
    
    const responseUsername = await axios.post(`${urlData()}/user-account/username`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
    
    if(!responseUsername.data.success){
      setSuccessFindUsername(false)
      setSuccessMessageFindUsername("")
      setErrorFindUsername(true)
      setErrorMessageFindUsername(responseUsername.data.message)
      setIsLoadingData(false)
      return
    }
    
    setSentCodeUsername(true)
    setSuccessFindUsername(true)
    setSuccessMessageFindUsername("Un code à été envoyé dans votre boîte email.")
    setIsLoadingData(false)
    
    return
  }

  const validChangeUsername= async()=>{

    try {
      hideFormError()
      setErrorFindUsername(false)
      setErrorMessageFindUsername("")
      setSuccessFindUsername(false)
      setSuccessMessageFindUsername("")
      if(!validateEmail(newUsername)){
        showInvalideValue('checkNewUsername','Email invalide')
        return 
      }

      if(!validateEmail(confNewUsername)){
        showInvalideValue('checkConfNewUsername','Email invalide')
        return 
      }

      if(newUsername!==confNewUsername){
        showInvalideValue('checkNewUsername','Email invalide')
        showInvalideValue('checkConfNewUsername','Email invalide')
        return 
      }

      if(!passNewUsername){
        showInvalideValue('checkPassNewUsername','Mot de passe invalide')
        return 
      }

      if(!codeUsername){
        showInvalideValue('checkCodeUsername','Code invalide')
        return 
      }
      let body ={
        idUser,username:newUsername,confUsername:confNewUsername,password:passNewUsername,code:codeUsername
      }
      const responseUsername = await axios.post(`${urlData()}/user-account-validate/username`,body,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
      console.log("responseUsername")
      console.log(responseUsername)
      console.log("responseUsername")
      if(!responseUsername.data.success){
        setErrorFindUsername(true)
        setErrorMessageFindUsername(responseUsername.data.message)
        setSuccessFindUsername(false)
        setIsLoadingData(false)
      
        return
      }
      setIsLoadingData(false)
      setSuccessMessageFindUsername(responseUsername.data.message)
      setCloseFormUsername(true)
      setSuccessFindUsername(true)
      setTimeout(()=>{
        handleCloseChangeUsername()
      },3000)
      refreshData()
      return
    } catch (error) {
      setErrorFindUsername(true)
      setErrorMessageFindUsername("Une erreur interne est survenue.")
    }
    
  }


  const handleCloseChangeUsername=()=>{
    setCodeUsername("")
    setConfNewUsername("")
    setPassNewUsername("")
    setNewUsername('')
    setSentCodeUsername(false)
    setErrorFindUsername(false)
    setErrorMessageFindUsername("")
    setSuccessMessageFindUsername("")
    setSuccessFindUsername(false)
    setCloseFormUsername(false)
    
    window.$("#usernameForm").modal("hide")
    hideFormError()
  }





  return (

    <article className="card mb-4">
        <header className="card-header">
          <strong className="d-inline-block mr-3">IDENTIFIANT</strong>
        </header>
        <div className="card-body">
          <div className="row" > 
            <div className="col-md-8">
              <h6 className="text-muted">Modifier votre identité</h6>
              <p>Identité: {profil.userInfos.username_customer}</p>
              <button type='button' onClick={()=>window.$("#usernameForm").modal("show")} className='btn btn-warning'>Modifier</button>
            </div>
          </div> 
        </div> 
        <div className="modal" id="usernameForm"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">MODIFICATION IDENTIFIANT</h5>
              
            </div>
            <div className="modal-body">
                <h6>Entrer le nouvel identifiant</h6>
                {errorFindUsername && 
                  <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {errorMessageFindUsername}</p>
                  </div>
                }
                {successFindUsername && !errorFindUsername &&
                  <div className="alert alert-success mt-3">
                    <p className="icontext"><i className="icon text-success fa fa-check"></i>{successMessageFindUsername}</p>
                  </div>
                }
                
                {
                  !closeFormUsername && 
                  <form>
                    <div className="form-group">
                      <label>Identifiant</label>
                      <input type="email" onChange={(e)=>setNewUsername(e.target.value)} className="form-control" value={newUsername}/>
                      <span className='text-danger formChangeUsername'  id="checkNewUsername"></span>
                    </div>
                    <div className="form-group">
                      <label>Confirmation identifiant</label>
                      <input type="email" onChange={(e)=>setConfNewUsername(e.target.value)} className="form-control" value={confNewUsername}/>
                      <span className='text-danger formChangeUsername' id="checkConfNewUsername"></span>
                    </div>
                    <div className="form-group">
                      <label>Mot de passe</label>
                      <input type="password" onChange={(e)=>setPassNewUsername(e.target.value)} className="form-control" value={passNewUsername} />
                      <span className='text-danger formChangeUsername'id="checkPassNewUsername"></span>
                    </div>
                    
                    {sentCodeUsername &&
                      <div className="form-group">
                        <label>Code</label>
                        <input type="text" onChange={(e)=>setCodeUsername(e.target.value)} className="form-control" value={codeUsername} />
                        <span className='text-danger formChangeUsername'id="checkCodeUsername"></span>
                      </div>
                    }
                    
                    {!sentCodeUsername &&
                    <div className="form-group">
                      <button type="button" onClick={sendConfEmail} disabled={isLoadingData?true:false} className="btn btn-secondary btn-block">{isLoadingData?"Envoi.....":"Vérifier le compte"}   </button>
                    </div>
                    }
                  </form>
                }
                
            </div>
            {
                  !closeFormUsername &&
                  <div className="modal-footer">
                    { sentCodeUsername &&  !codeUsername &&
                      <button type="button" title='Vous devez entrer le code de validation' disabled className="btn btn-success">Valider</button>
                    }
      
                    { sentCodeUsername && codeUsername &&
                      <button type="button" className="btn btn-success" onClick={validChangeUsername}>Valider</button>
                    }
                    <button type="button" onClick={handleCloseChangeUsername} className="btn btn-danger " >
                        Annuler
                    </button>
                  
                </div>
            }
           
          </div>
        </div>
      </div>
      </article> 

    
  )
}

export default UsernameChange