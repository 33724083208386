import Template from "../Template"

function Loading() {

  const styles = {
    main: {
      //width: "200px",
      height:"65vh",
      //border: "1px solid #2d2d2d",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    section: {
      //width: "200px",
      
      
    },
   
  };

  
  return (
    <div>
      <Template>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          
          <div style={styles.main}>
            <div style={styles.section}>
                <p style={{display: "flex",justifyContent: "center",alignItems: "center"}} ><img style={{width:"120px"}} src="https://api.lnvx.fr/images/logo/logo.png" alt="Logo LNVX"/></p>
                <h5 >LES NOUVEAUX VOTRE QUOTIDIEN AU MEILLEUR PRIX !</h5>
                <p style={{display: "flex",justifyContent: "center",alignItems: "center"}}>Chargement.....</p>
            </div>
            
          </div>

      
          </div>
        </div>
      </div> 
      </Template>
    </div>
  );
}

export default Loading;