import React,{ useEffect,useState } from 'react'
import axios from 'axios';
import  { useSearchParams} from 'react-router-dom';
import { useStoreActions,useStoreState } from 'easy-peasy';
import Template from './components/Template';
import Loading from './components/loading/Loading';
import SectionOne from './components/sections/sectionOne';
import SectionFive from './components/sections/sectionFive';
import SectionPub from './components/sections/sectionPub';
import SectionTwo from './components/sections/sectionTwo';
import SectionThree from './components/sections/sectionThree';
import SectionFour from './components/sections/sectionFour';
import SectionSix from './components/sections/sectionSix';
import SectionEight from './components/sections/sectionEight';
import SectionArticles from './components/sections/sectionArticles';
import ErrorFiveHundred from './components/errors/ErrorFiveHundred';
import {urlData,urlDataImage} from './services/url';
import BtnScrollUp from './components/btnScrollUp/btnScrollUp';

function Homepage() {
  const linkApiImages=urlDataImage()
  const postCode= useStoreState((state)=>state.postCode)
  const connected= useStoreState((state)=>state.connected)
  const addBasket= useStoreActions((state)=>state.addBasket)
  const clearBasketData= useStoreActions((state)=>state.clearBasketData)
  const [homePage, setHomePage] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)
  const [searchParams] = useSearchParams();
 
  

  useEffect(() => {

    
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const clearBasket = searchParams.get('clearBasket')
        const paid = searchParams.get('paid')

        if(clearBasket && paid){
          clearBasketData()
        }
          const response = await axios.get(`${urlData()}/homepage?postCode=${postCode}`,{headers:{'Content-Type': 'application/json'}});
       
          if(response.data.success) {
            setFetchError(null);
            setHomePage(response.data);
            
          }else{
          
            setFetchError(response.data.message);
          }
      } catch (err) {
       
          console.log(err)
          setFetchError(err.message);
      } finally {
        !postCode?setShowPrices(false):setShowPrices(true)
        
        setIsLoading(false);

      }
  }
   
  fetchData()
},[postCode])

  

  const handleAddBasket=(idBookProd,idProduct,idBookSize)=>{
    const quantity=1;
    if(!connected){
      addBasket({idBookProd,idProduct,idBookSize,quantity})
      return
    }
  }

  return (
    <div>
      
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {
        !isLoading && !fetchError && 
        <Template>
         <BtnScrollUp/>
        <div className="container">
          <SectionOne categories={homePage.allCategories} sliders={homePage.slider} linkApiImages={linkApiImages} popCat={homePage.allPopulairesCategorie} />
          <SectionFive showPrices={showPrices} linkApiImages={linkApiImages} nameSection="NOUVEAUTES" sectionData={homePage.allNouveautes} colorShopBtn={homePage?.infosTemplate[0].nouveaute} handleAddBasket={handleAddBasket}/>
          {/*<SectionPub sectionData={homePage.sliderTwo} linkApiImages={linkApiImages}/>*/}
          {homePage.allFlashs.length>0 && <SectionFive showPrices={showPrices}  linkApiImages={linkApiImages} nameSection="Flashs" sectionData={homePage.allFlashs}colorShopBtn={homePage?.infosTemplate[0].flash} handleAddBasket={handleAddBasket}/>}
          {homePage.allPromotions.length>0 && <SectionFive showPrices={showPrices}  linkApiImages={linkApiImages} nameSection="Promotions" sectionData={homePage.allPromotions}colorShopBtn={homePage?.infosTemplate[0].promotion} handleAddBasket={handleAddBasket}/>  }
          <SectionFive showPrices={showPrices} linkApiImages={linkApiImages} nameSection="Tendances" sectionData={homePage.allPopulaires} colorShopBtn={homePage?.infosTemplate[0].tendance} handleAddBasket={handleAddBasket}/>
          <SectionTwo linkApiImages={linkApiImages} categories={homePage.allCategories}/>
          <SectionThree linkApiImages={linkApiImages} datas={homePage.mostSellByCategory}/>
          
          {/*<SectionEight linkApiImages={linkApiImages}/>*/}
          <SectionArticles  linkApiImages={linkApiImages} nameSection="Bon plan" sectionData={homePage.allProducts} colorShopBtn={homePage?.infosTemplate[0].nouveaute} />
        </div>
        <div className="modal" id="errorMess"  tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">PANIER</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <h4 className="text-danger">Erreur Panier</h4>
                  <p>messageError</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
              </div>
            </div>
          </div>
        </div>
      
      </Template>
      }
      
    </div>
  )
}

export default Homepage