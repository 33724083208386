import React,{ useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import {useStoreState, useStoreActions } from 'easy-peasy';
import axios from 'axios';
import {urlDataImage,urlData} from '../../services/url';
import { dotToComma } from "../../services/helpers"
import AddToBasket from '../addToBasket/AddToBasket';




function WishListProfil({profil,refreshData}) {
  const [wishlistPage, setWishlistPage] = useState({})
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPrices, setShowPrices] = useState(true)
  const [ messageErrorWishList, setMessageErrorWishList ] = useState()
  const postCode= useStoreState((state)=>state.postCode)
  const idUser= useStoreState((state)=>state.idUser)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const addBasket= useStoreActions((state)=>state.addBasket)
  const linkApiImages=urlDataImage()

 
  useEffect(() => {
    

   
  },[profil])

  const deleteFromWishList=async (idBookProd)=>{
   
    if(connected){
      const responseDeleteWishlist = await axios.delete(`${urlData()}/wishlist/${idUser}/${idBookProd}`,{headers:{'Content-Type': 'application/json','authorization':  `Bearer ${token}`}});
     
      if(!responseDeleteWishlist.data.success){
        setMessageErrorWishList(responseDeleteWishlist.data.message)
        window.$("#errorWishList").modal("show")
        setTimeout(()=>{
          window.$("#errorWishList").modal("hide")
        },3000)
        return
      }

      setMessageErrorWishList("")
      window.$("#successWishList").modal("show")
      setTimeout(()=>{
        window.$("#successWishList").modal("hide")
      },3000)
      refreshData()
      return
    }
  }

  const styles = {
    main: {
      height:"65vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    section: {
      paddingTop:"50px"
    },
  };
  


  return (
   <>
    { profil.wishlist.length=== 0 &&
      <main className="col-md-9" >
      <article  className="card"  style={styles.main} >
          <div className="card-body">
              <div className="row">
                <div >
                  <div style={styles.section}>
                     
                      <h2 ><i className="fa fa-star"></i> 0 WISHLIST</h2>
                      <p style={{display: "flex",justifyContent: "center",alignItems: "center",marginTop:"20px"}}>
                      <Link to="/" className="btn btn-secondary"> Continuer mes achats </Link>
                      </p>
                  </div>
                </div>
              </div>
          </div> 
      </article>
      </main>
    }

    { profil.wishlist.length> 0 &&
    <main className="col-md-9" style={{height:"75vh",overflow: "hidden",overflowY: "scroll"}}>
      <article  className="card">
          <div className="card-body">
              <div className="row">
                  {profil?.wishlist.map((data,index)=>{
                    return(
                      
                      <div key={index} className="col-md-6">
                        <figure className="itemside mb-4">
                          <div className="aside">
                            <Link to={`articles/${data.idBookProd}`} >
                              <img  src={`${linkApiImages}/${data.uniqIdDoc}`} alt={`article ${data.nameProduct}`} className="border img-md"/>
                            </Link>
                          </div>
                          <figcaption className="info">
                            <Link to={`articles/${data.idBookProd}`} className="title">{data.nameProduct}</Link>
                            <p className="price mb-2">{!data.idBookSizePromo?`${dotToComma(data.price)}€`:`${dotToComma(data.newPricePromo)}€ <del className="price-old">${dotToComma(data.oldPricePromo)}€</del>`}</p>
                            <div className='btn-group'>
                              <AddToBasket 
                                idBookProd={data.idBookProd}
                                idProduct={data.idProduct}
                                idBookSize={data.idBookSize}
                                quantity={1}
                                showPopupSuccess={1}
                                showPopupError={1}
                                deleteFromWishlist={1}
                              >
                                <button type="button" className="btn btn-secondary btn-sm mr-1"> Ajouter au panier </button>
                              </AddToBasket>
                            
                              <button type="button" onClick={()=>deleteFromWishList(data.idBookProd)} className="btn btn-danger btn-sm" data-toggle="tooltip" title="" data-original-title="Supprimer de la wishlist"> <i className="fa fa-times"></i> </button>
                            </div>
                            
                          </figcaption>
                        </figure>
                      </div> 
                        
                    )
                    
                  })

                  }
              </div>
          </div> 
      </article>
    <div className="modal" id="errorWishList"  tabIndex="-1" role="dialog" >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">WISHLIST</h5>
          </div>
          <div className="modal-body">
          <div className="alert alert-danger mt-3">
              <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i> {messageErrorWishList?messageErrorWishList:"Erreur, l'article n'a pas pu être supprimé de la wishlist."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal" id="successWishList"  tabIndex="-1" role="dialog" >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">WISHLIST</h5>
          </div>
          <div className="modal-body">
              <p className="icontext"><i className="icon text-success fa fa-check"></i>L'article a été retiré de la wishlist.</p>
          </div>
        </div>
      </div>
    </div>
  
</main>
    }
       
       </>
  )
}

export default WishListProfil