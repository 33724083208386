import React,{ useEffect,useState, } from 'react'
import axios from 'axios';
import { useStoreActions,useStoreState } from 'easy-peasy';

import Loading from '../../loading/Loading';
import ErrorFiveHundred from '../../errors/ErrorFiveHundred';
import DetailsBasketUser from './steps/DetailsBasketUser';
import DeliveryBasketUser from './steps/DeliveryBasketUser';
import PaymentBasketUser from './steps/PaymentBasketUser';
import FindPostCode from '../../postCode/FindPostCode';
import BasketEmpty from '../BasketEmpty';
import {urlData,urlDataImage} from '../../../services/url';

function BasketUser() {

  const [data, setData] = useState({});
  const [allowedNextStep, setAllowedNextStep] = useState(false);
  const [basketEmpty, setBasketEmpty] = useState(false);
  const [fetchError, setFetchError] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  
  const [showDelivery, setShowDelivery] = useState(false)
  const [showBasket, setShowBasket] = useState(true)
  const [showPayment, setShowPayment] = useState(false)
  const [dataPayment, setDataPayment] = useState({})
  
  const postCode= useStoreState((state)=>state.postCode)
  const connected= useStoreState((state)=>state.connected)
  const token= useStoreState((state)=>state.token)
  const idUser= useStoreState((state)=>state.idUser)
  const clearBasketData= useStoreActions((state)=>state.clearBasketData)
  const setTotalBasket= useStoreActions((state)=>state.setTotalBasket)
 
 
  const isAvailable = (currentValue) => currentValue.stock >0 && currentValue.availableSize===1 && currentValue.availableBookProd===1;

  function checkAvailableProduct(dataBasket){
    if(!dataBasket || dataBasket.length===0){
      setAllowedNextStep(false)
      return
    }

    if(!dataBasket.every(isAvailable)){
      setAllowedNextStep(false)
      return
    }

    setAllowedNextStep(true)
    return



  }


  useEffect(() => {
    
    const fetchData = async () => {
      if(!connected){
        setIsLoading(false);
        window.location.reload();
        return
      }else{
        let payload={basketData:[],postCode,idUser}
        setIsLoading(true);
        const source = axios.CancelToken.source();
        payload.cancelToken=source.token
        //payload=JSON.stringify(payload)
        try {
          //basket
            const response = await axios.post(`${urlData()}/basket`,payload,{headers:{'content-type': 'application/json','Authorization': `Bearer ${token}`}});
           
            if(response.data.success) {
              setFetchError(null);
              if(!response.data.find){
                if(response.data?.message){
                  setFetchError(response.data?.message)
                }else{
                  console.log("2 useeffect total basket")
                  setTotalBasket(0)
                  setBasketEmpty(true)
                }
                
              }else{
                setData(response.data);
                checkAvailableProduct(response.data?.infosPanier?.products)
                setBasketEmpty(false)
                setFetchError(null);
                clearBasketData()
                let totalPanier= response.data?.infosPanier?.products.reduce((accumulator,currentValue)=>{
                  return currentValue.quantity_basket+accumulator
                },0)
               setTotalBasket(totalPanier)
              }
                
            }else{
              console.log("findErrors in ")
              if(!response.data.find){
                console.log("1 useeffect postcode")
                setBasketEmpty(true)
                setTotalBasket(0)
                clearBasketData()
              }else{
                setFetchError(response.data.message);
              }
              
            }
        } catch (err) {
         
            console.log(err)
            setFetchError(err.message);
        } finally {
          
          console.log('call finally')
          setIsLoading(false);
        }
      }
      
    }
   
  fetchData()
},[postCode])
 


const refreshData= async ()=>{

  let payload={basketData:[],postCode,idUser}
  const source = axios.CancelToken.source();
  payload.cancelToken=source.token
  try {
    
    if(!connected){
      setIsLoading(false);
      window.location.reload();
      return
    }else{
      
      
      const source = axios.CancelToken.source();
      payload.cancelToken=source.token
      //payload=JSON.stringify(payload)
      try {
        //basket
          const response = await axios.post(`${urlData()}/basket`,payload,{headers:{'content-type': 'application/json','Authorization': `Bearer ${token}`}});
          
          if(response.data.success) {
            setFetchError(null);
            if(!response.data.find){
              if(response.data?.message){
                setFetchError(response.data?.message)
              }else{
                setTotalBasket(0)
                setBasketEmpty(true)
              }
              
            }else{
              setData(response.data);
              checkAvailableProduct(response.data?.infosPanier?.products)
              setBasketEmpty(false)
              setFetchError(null);
              clearBasketData()
              let totalPanier= response.data?.infosPanier?.products.reduce((accumulator,currentValue)=>{
                return currentValue.quantity_basket+accumulator
              },0)
             setTotalBasket(totalPanier)
            }
              
          }else{
            console.log("findErrors in ")
            if(!response.data.find){
              console.log("1 useeffect postcode")
              setBasketEmpty(true)
              clearBasketData()
              setTotalBasket(0)
            }else{
              setFetchError(response.data.message);
            }
            
          }
      } catch (err) {
       
          console.log(err)
          setFetchError(err.message);
      } finally {
        
        console.log('call finally')
        setIsLoading(false);
      }
    }
  } catch (err) {
  
      console.log(err)
      setFetchError(err.message);
  } 
}


const showBasketInfos=()=>{
  setShowDelivery(false)
  setShowBasket(true)
  setShowPayment(false)
}

const showDeliveryInfos=()=>{
  if(!allowedNextStep){
    return
  }
  setShowDelivery(true)
  setShowBasket(false)
  setShowPayment(false)
}

const showPaymentInfos=(payloadDataPayment)=>{
  if(!allowedNextStep){
    return
  }
  setShowDelivery(false)
  setShowBasket(false)
  setDataPayment(payloadDataPayment)
  setShowPayment(true)
}

const handleCloseModal=()=>{
  window.$('.modal').modal("hide")
}


  return (
    
    <div>
      {isLoading && <Loading/>}
      {!isLoading && fetchError && <ErrorFiveHundred/>}
      {!isLoading && !fetchError && basketEmpty && <BasketEmpty/>}
      {!isLoading && !fetchError &&  showBasket &&
        <DetailsBasketUser data={data}  showDeliveryInfos={showDeliveryInfos}  basketEmpty={basketEmpty} refreshData={refreshData}/>
      }
      {!isLoading && !fetchError &&   showDelivery &&
        <DeliveryBasketUser data={data} showBasketInfos={showBasketInfos} showPaymentInfos={showPaymentInfos} />
      }
      {!isLoading && !fetchError && showPayment &&
        <PaymentBasketUser dataPayment={dataPayment} />
      }
      <div className="modal" id="basketInfoDispo"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">INFORMATION</h5>
              
            </div>
            <div className="modal-body">
                <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                    Vous devez supprimer les produits indisponibles
                    </p>
                  </div>
                
            </div>
            <div className="modal-footer">
      
              <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="basketUserInfoAvailableProduct"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">INFORMATION</h5>
              
            </div>
            <div className="modal-body">
                <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                    Vérifiez les produits. Un ou plusieurs produits ne sont pas disponibles ou en stock insuffisant.
                    </p>
                  </div>
                
            </div>
            <div className="modal-footer">
      
              <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="basketInfoQuantity"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">INFORMATION</h5>
              
            </div>
            <div className="modal-body">
                <div className="alert alert-danger mt-3">
                    <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                    Vous devez réduire la quantité d'un ou des produits.
                    </p>
                  </div>
                
            </div>
            <div className="modal-footer">
      
              <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                  Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="basketInfoStock"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">INFORMATION</h5>
                
              </div>
              <div className="modal-body">
                  <div className="alert alert-danger mt-3">
                      <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                        Quantité maximum atteint(stock insuffisant au-dela).
                      </p>
                    </div>
                  
              </div>
              <div className="modal-footer">
        
                <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                    Fermer
                </button>
              </div>
            </div>
          </div>
        </div>  
        <div className="modal" id="basketDeleteItem"  tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"  role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">INFORMATION</h5>
                
              </div>
              <div className="modal-body">
                  <div className="alert alert-danger mt-3">
                      <p className="icontext"><i className="icon text-danger fa fa-exclamation-triangle"></i>
                        1 article, pour retirer l'article vous devez le supprimer.
                      </p>
                    </div>
                  
              </div>
              <div className="modal-footer">
        
                <button type="button" onClick={handleCloseModal} className="btn btn-success " >
                    Fermer
                </button>
              </div>
            </div>
          </div>
        </div>  
    </div>
  )
}

export default BasketUser